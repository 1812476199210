import { Box, Button, Chip, Grid, Typography } from "@mui/material";
import { Coin, DeleteGif, GreenTick } from "@platformx/shared/static-assets";
import { PlatFormXDateTimeFormat } from "@platformx/utilities";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import graphQlList from "../../../../../graphql/fetch";
import Confirmation from "../../../../Confirmation/Confirmation";
import Loader from "../../../../Loader/Loader";
import { isExpired, showDetailMessage } from "../../../helperProfile";
import OfferCard from "../Common/OfferCard";
import { useCustomStyle } from "./AvailableRewards.style";

const AvailableRewards = ({ item, secondaryArgs, memberId }) => {
  const classes = useCustomStyle();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [redeem, setRedeem] = useState(false);
  const [message, setMessage] = useState({ message: "", status: "" });
  const [isDetailAvailable, setIsDetailAvailable] = useState(false);
  const toggleDetail = () => {
    setShowDetail((prevState) => !prevState);
  };
  const confirmationPopUpClose = () => {
    setRedeem(false);
  };
  const showRedeem = () => {
    setRedeem(true);
  };
  const reedemCouponNow = async () => {
    setRedeem(false);
    const { prelemBaseEndpoint: { language = "en", deliveryEndPointGq = "" } = {}, sitename } =
      secondaryArgs;
    try {
      setLoading(true);
      const { data, errors } = await graphQlList.redeemCoupon(
        {
          rewardId: item?.reward_id,
          memberId: memberId,
          isPoints: false,
          quantity: 1,
          dateValid: item?.ends_at,
          type: item?.reward_type,
        },
        language,
        sitename,
        deliveryEndPointGq,
      );
      if (data?.users_assignRewards) {
        setMessage({
          message: data?.users_assignRewards?.message,
          status: "success",
        });
      } else {
        const msg = errors[0]?.message;
        setMessage({
          message: msg,
          status: "fail",
        });
      }
    } catch (err: any) {
      setMessage({
        message:
          "Oops! Something went wrong with the redemption. Please try again or contact support if the issue persists.",
        status: "fail",
      });
    } finally {
      setLoading(false);
    }
  };
  const expiredItem = isExpired(item?.ends_at);
  useEffect(() => {
    showDetailMessage(item, setIsDetailAvailable);
  }, [item]);

  return (
    <Box
      className={`${expiredItem ? "expiredRewards" : ""} ${classes.AvailableCardWrapper} AvailableCardWrapperSection`}>
      <Box className='cardDetail'>
        <Box width='100%' display='flex'>
          <Grid item>
            <Box className='rewardProduct'>
              <OfferCard imageUrl={item?.images?.[0]?.image} />
            </Box>
          </Grid>
          <Grid item xs ml={2} className='coupanDetailSection'>
            <Box>
              <Typography variant='h4regular' className='noTopMargin lessGap one-line-ellipsis'>
                {item?.reward_name}
              </Typography>
              <Typography
                variant='h5regular'
                component='div'
                dangerouslySetInnerHTML={{ __html: item?.rewards_desc }}
                className='noMargin lessGap two-line-ellipsis'></Typography>
            </Box>
            <Box mt={2} display={"flex"}>
              <Box display='flex'>
                <Chip
                  label={
                    <Typography variant='p4regular'>
                      {item?.cost_in_points ? item?.cost_in_points : 0} points
                    </Typography>
                  }
                  className='customchip'
                  icon={<img src={Coin} className='coinImage icons' alt='coin' />}
                />

                {item?.ends_at && (
                  <Typography variant='h5regular' className='noTopMargin labelColor' ml={2}>
                    Expires: {PlatFormXDateTimeFormat(item?.ends_at, false)}
                  </Typography>
                )}
              </Box>
            </Box>
          </Grid>
        </Box>
        <Grid item className='buttonWrapper'>
          <Box className='orderActionColumn'>
            <Button
              variant='tertiaryButton1'
              onClick={showRedeem}
              className='sm'
              type='button'
              disabled={expiredItem}
              sx={{ width: "100%" }}>
              {t("redeem")}
            </Button>
            <Button
              variant='tertiaryButton2'
              className='sm'
              onClick={toggleDetail}
              disabled={expiredItem}
              type='button'
              sx={{ width: "100%", marginBottom: 0 }}>
              {showDetail ? "Hide Details" : t("view_details")}
            </Button>
          </Box>
        </Grid>
      </Box>
      {showDetail && (
        <Box className='viewDetails'>
          <Typography variant='h5regular' className='noTopMargin'>
            {isDetailAvailable ? "Details" : "No details available"}
          </Typography>
          <Box className='description'>
            {item?.brand_name && (
              <Typography
                variant='h5regular'
                className='noMargin'
                dangerouslySetInnerHTML={{ __html: item?.brand_name }}
              />
            )}
            {item?.brand_description && (
              <Typography
                variant='p4regular'
                dangerouslySetInnerHTML={{ __html: item?.brand_description }}
              />
            )}
            {item?.usage_instruction && (
              <Typography
                variant='p4regular'
                dangerouslySetInnerHTML={{ __html: item?.usage_instruction }}
              />
            )}
            {item?.conditions_description && (
              <Typography
                variant='p4regular'
                dangerouslySetInnerHTML={{ __html: item?.conditions_description }}
              />
            )}
          </Box>
        </Box>
      )}
      {redeem && (
        <Confirmation
          open={true}
          text={`Are you sure you want to redeem ${item?.reward_name}`}
          title='Confirm Redemption'
          handleClose={confirmationPopUpClose}
          buttonOne='Cancel'
          buttonTwo='Confirm'
          width='600px'
          buttonOneFunc={confirmationPopUpClose}
          buttonTwoFunc={reedemCouponNow}
        />
      )}
      {loading ? <Loader /> : null}
      {!loading && message?.message && (
        <Confirmation
          img={message?.status === "success" ? GreenTick : DeleteGif}
          open={true}
          text={message?.message}
          title={
            message?.status === "success" ? "Redemption Successful" : "Redemption Unsuccessful"
          }
          handleClose={() => setMessage({ message: "", status: "" })}
          width='600px'
        />
      )}
    </Box>
  );
};

export default AvailableRewards;
