import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent } from "@mui/material";
import React from "react";
import { useCustomStyle } from "./CustomDialog.style";

interface CustomDialogProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false | string;
  minHeight: string;
  margin: string;
}
const CustomDialog = ({
  open,
  onClose,
  children,
  maxWidth = "600px",
  minHeight = "402px",
  margin = "32px",
}: CustomDialogProps) => {
  const classes = useCustomStyle();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={`${classes.customDialogPopup} customDialog`}
      sx={{
        "& .Platform-x-Dialog-paper": {
          maxWidth: `${maxWidth}`,
          width: "100%",
          minHeight: minHeight,
          margin: margin,
        },
      }}>
      <DialogContent className='customDialogWrapper'>
        <CloseIcon
          onClick={onClose}
          data-testid='confirmation-close'
          className={`close-icon closeIcon`}
        />
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default CustomDialog;
