import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, DialogContent, Grid, Skeleton, Typography } from "@mui/material";
import { DeleteGif, GreenTick } from "@platformx/shared/static-assets";
import { t } from "i18next";
import { useEffect, useState } from "react";
import graphQlList from "../../../../graphql/fetch";
import Confirmation from "../../../Confirmation/Confirmation";
import Loader from "../../../Loader/Loader";
import ToastService from "../../../ToastContainer/ToastService";
import { isExpired } from "../../helperProfile";
import ConfettiCanvas from "./Confetti";
import { useCustomStyle } from "./Confetti-popup.style";
import "./Confettipopup.css";

type ConfettipopupProps = {
  open?: boolean;
  width?: string;
  handleClose?: (e?: any) => void;
  buttonTwoFunc?: (e?: any) => void;
  winPrize: any;
  member_id: string;
  secondaryArgs: any;
};

export default function Confettipopup(props: ConfettipopupProps) {
  const classes = useCustomStyle();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ message: "", status: "" });
  const [rewardDetail, setRewardDetail] = useState<any>(null);
  const {
    open = false,
    handleClose = () => {},
    width = "800px",
    winPrize,
    member_id = "",
    secondaryArgs,
  } = props;
  const expiredItem = isExpired(winPrize?.ends_at);
  const [confettiShow, setConfettiShow] = useState(false);

  const getRewardDetail = async () => {
    try {
      const { prelemBaseEndpoint: { deliveryEndPointGq = "", language = "" } = {}, sitename } =
        secondaryArgs;
      const { data, errors } = await graphQlList?.fetchRewardDetailByID(
        {
          rewardId: winPrize?.reward_id,
        },
        language,
        sitename,
        deliveryEndPointGq,
      );
      if (errors) {
        setRewardDetail(null);
        ToastService.failToast(errors?.[0]?.message || t("errorRequest"));
      } else if (data) {
        const updatedRewardDetail = {
          rewardType: data?.users_getRewardDetails?.coupon_value_type,
          rewardValue: data?.users_getRewardDetails?.coupon_value,
        };
        setRewardDetail(updatedRewardDetail);
      }
    } catch {
      ToastService.failToast(t("errorRequest"));
    }
  };

  const reedemCouponNow = async () => {
    const { prelemBaseEndpoint: { language = "en", deliveryEndPointGq = "" } = {}, sitename } =
      secondaryArgs;
    try {
      setLoading(true);
      const { data, errors } = await graphQlList.redeemCoupon(
        {
          rewardId: winPrize?.reward_id,
          memberId: member_id,
          isPoints: true,
          quantity: 1,
          dateValid: "",
          type: "static_coupon",
        },
        language,
        sitename,
        deliveryEndPointGq,
      );
      if (data?.users_assignRewards) {
        setMessage({
          message: data?.users_assignRewards?.message,
          status: "success",
        });
      } else {
        const msg = errors[0]?.message;
        setMessage({
          message: msg,
          status: "fail",
        });
      }
    } catch (err: any) {
      setMessage({
        message:
          "Oops! Something went wrong with the redemption. Please try again or contact support if the issue persists.",
        status: "fail",
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setConfettiShow(true);
      }, 1000);
    }
    getRewardDetail();
  }, [open]);

  const getWinText = () => {
    if (rewardDetail?.rewardType === "money")
      return `Get ${rewardDetail?.rewardValue}$ off on your next purchase`;
    else if (rewardDetail?.rewardType === "percentage")
      return `Get ${rewardDetail?.rewardValue}% off on your next purchase`;
    else return "";
  };

  return (
    <Dialog
      open={open}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      sx={{
        "& .Platform-x-Dialog-paper": {
          maxWidth: `${width} !important`,
          width: "100%",
          height: "520px",
          position: "relative",
          margin: "4px",
        },
      }}
      className={`${classes.ConfettiPopup} Confetti-Popup`}>
      {confettiShow && <ConfettiCanvas />}
      <Box className='modal-close'>
        <CloseIcon
          onClick={handleClose}
          data-testid='confirmation-close'
          className='close-icon closeIcon'
        />
      </Box>
      <Box className='dialogTitle' id='alert-dialog-title'>
        <Typography variant='h2medium'>Congratulations!</Typography>
      </Box>
      <DialogContent className='dialogContent'>
        <Box className='dialogContentText' id='alert-dialog-description'>
          <Typography variant='p3regular'>
            Your spin was lucky! You&#39;ve earned a coupon. Hurry and claim your reward now.
            Don&#39;t wait too long, it&#39;s only available for a limited time.
          </Typography>
        </Box>
        <Box className='boxWrapper'>
          <Box className='boxWrapperInner'>
            {rewardDetail ? (
              <Typography variant='p3regular' sx={{ color: "#fff" }}>
                {getWinText()}
              </Typography>
            ) : (
              <Skeleton variant='text' width='100%' height={80} />
            )}
            <Box className='coupanCode'>
              <Typography variant='p3regular' sx={{ color: "#fff", margin: "4px" }}>
                {winPrize?.reward_name}
              </Typography>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <Box style={{ width: "100%" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} mt={2}>
            <Button
              variant='tertiaryButton1'
              onClick={reedemCouponNow}
              className='sm'
              type='button'
              disabled={expiredItem}>
              Claim Now
            </Button>
          </Grid>
        </Grid>
      </Box>
      {loading ? <Loader /> : null}
      {!loading && message?.message && (
        <Confirmation
          img={message?.status === "success" ? GreenTick : DeleteGif}
          open={true}
          text={message?.message}
          title={
            message?.status === "success" ? "Redemption Successful" : "Redemption Unsuccessful"
          }
          handleClose={() => {
            message?.status === "success" && handleClose();
            setMessage({
              message: "",
              status: "",
            });
          }}
          width='600px'
        />
      )}
    </Dialog>
  );
}
