import { useEffect, useRef, useState } from "react";

interface IConfettiParticle {
  x: number;
  y: number;
  r: number;
  d: number;
  color: string;
  tilt: number;
  tiltAngleIncremental: number;
  tiltAngle: number;
  draw(ctx: CanvasRenderingContext2D): void;
}
const ConfettiCanvas = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [fadeOut, setFadeOut] = useState(false); // State to control fade-out
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const context = canvas.getContext("2d");
    if (!context) return;

    let W = window.innerWidth;
    let H = window.innerHeight;
    const maxConfettis = 150;
    const particles: any[] = [];

    const possibleColors = [
      "DodgerBlue",
      "OliveDrab",
      "Gold",
      "Pink",
      "SlateBlue",
      "LightBlue",
      "Gold",
      "Violet",
      "PaleGreen",
      "SteelBlue",
      "SandyBrown",
      "Chocolate",
      "Crimson",
    ];

    function randomFromTo(from: number, to: number) {
      return Math.floor(Math.random() * (to - from + 1) + from);
    }

    function ConfettiParticle(this: IConfettiParticle) {
      this.x = Math.random() * W;
      this.y = Math.random() * H - H;
      this.r = randomFromTo(11, 33);
      this.d = Math.random() * maxConfettis + 11;
      this.color = possibleColors[Math.floor(Math.random() * possibleColors.length)];
      this.tilt = Math.floor(Math.random() * 33) - 11;
      this.tiltAngleIncremental = Math.random() * 0.07 + 0.05;
      this.tiltAngle = 0;

      this.draw = function (ctx: CanvasRenderingContext2D) {
        ctx.beginPath();
        ctx.lineWidth = this.r / 2;
        ctx.strokeStyle = this.color;
        ctx.moveTo(this.x + this.tilt + this.r / 3, this.y);
        ctx.lineTo(this.x + this.tilt, this.y + this.tilt + this.r / 5);
        ctx.stroke();
      };
    }

    function Draw() {
      const results: void[] = [];

      // Magical recursive functional love
      requestAnimationFrame(Draw);

      if (context) {
        context.clearRect(0, 0, W, window.innerHeight);
      }

      for (let i = 0; i < maxConfettis; i++) {
        if (context) {
          particles[i].draw(context);
        }
        results.push();
      }

      for (let i = 0; i < maxConfettis; i++) {
        const particle = particles[i];

        particle.tiltAngle += particle.tiltAngleIncremental;
        particle.y += (Math.cos(particle.d) + 3 + particle.r / 2) / 2;
        particle.tilt = Math.sin(particle.tiltAngle - i / 3) * 15;

        if (particle.x > W + 30 || particle.x < -30 || particle.y > H) {
          particle.x = Math.random() * W;
          particle.y = -30;
          particle.tilt = Math.floor(Math.random() * 10) - 20;
        }
      }

      return results;
    }

    // Handle window resize
    const handleResize = () => {
      W = window.innerWidth;
      H = window.innerHeight;
      canvas.width = W;
      canvas.height = H;
    };

    window.addEventListener("resize", handleResize, false);

    // Push new confetti objects to `particles[]`
    for (let i = 0; i < maxConfettis; i++) {
      particles.push(new ConfettiParticle());
    }

    // Initialize canvas size and animation
    canvas.width = W;
    canvas.height = H;
    Draw();
    setTimeout(() => {
      setFadeOut(true);
    }, 4000);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      style={{
        position: "absolute",
        opacity: fadeOut ? 0 : 1,
        transition: "opacity 2s ease-out",
        width: "600px",
        height: "100%",
      }}
    />
  );
};

export default ConfettiCanvas;
