import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import LaunchIcon from "@mui/icons-material/Launch";
import SearchIcon from "@mui/icons-material/Search";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import { avtarImg } from "@platformx/shared/static-assets";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AdvanceSearchPopup } from "../../AdvanceSearch/AdvanceSearch";
import {
  formRelativeURL,
  getCurrentLang,
  getCurrentPathName,
  getFlag,
  getHrefforAnchors,
  parseStringDetails,
} from "../helperFunction";
import EcomHeader from "./EcomHeader";
import "./Header.css";
import { useCustomStyle } from "./Header.style";
import { HeaderProps, Menus } from "./Header.types";
import HeaderLogo from "./HeaderLogo";
import HeaderMobile from "./HeaderMobile";
import LanguageDesktopView from "./LanguageHeader/LanguageDesktopView";
import RpiTagPopupIconButton from "./RpiTagPopupIconButton";

const Header = ({
  data,
  logoutButtonHandle,
  langCode,
  isCartIconEnable = true,
  isProductUpdateCount = null,
  navigateToCartPage = () => {},
  gcpUrl,
  bucketName,
  onLogin,
  isLoginEnabled = true,
  handleChangePassword,
  handleMyProfile,
  userData,
  secondaryArgs,
  handleOpenModal,
}: HeaderProps) => {
  const theme = useTheme();
  const isLogin = userData || localStorage.getItem("userLoginDetails");
  const { data: userLoginData = {} } = parseStringDetails(isLogin);
  const { name = "" } = userLoginData;

  const [currentMenu, setCurrentMenu] = useState(null);
  const [currentAnchorEl, setCurrentAnchorEl] = useState(null);
  const [openSearch, setOpenSearch] = React.useState(false);
  const handleClickOpen = () => {
    setOpenSearch(true);
  };
  const handleClickClose = () => {
    setOpenSearch(false);
  };

  const classes = useCustomStyle();
  const { t, i18n } = useTranslation();

  const langCodes = data?.language ? data?.language?.split("|") : [];
  const language = langCodes?.map((code) => {
    if (code === "de") {
      return { text: "German", code: code };
    } else if (code === "fr") {
      return { text: "French", code: code };
    } else {
      return { text: "English (UK)", code: code };
    }
  });

  const [anchorE2, setAnchorE2] = React.useState<null | HTMLElement>(null);

  const handleSubMenuOpen = (e: any, id: any) => {
    e.preventDefault();
    setCurrentMenu(id);
    setCurrentAnchorEl(e.currentTarget);
  };

  const handleSubMenuClose = () => {
    setCurrentMenu(null);
    setCurrentAnchorEl(null);
  };

  const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorE2(event.currentTarget);
  };

  const openMenu = Boolean(anchorE2);
  const handleCloseMenu = () => {
    setAnchorE2(null);
  };

  const handleLanguageRedirect = (id: any) => {
    if (typeof window !== "undefined") {
      if (id !== getCurrentLang()) {
        window.location.replace(`${window.location.origin}/${id}${getCurrentPathName()}`);
      }
    }
  };

  const handleContentRedirect = (urldata: any, content_type: any) => {
    if (typeof window !== "undefined") {
      const type = content_type === "VOD" ? "video" : "article";
      window.open(`/${langCode}/${type}${urldata}`, "_self");
    }
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleIconRedirect = () => {
    window.open(`/${getCurrentLang()}`, "_self");
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      i18n.changeLanguage(langCode);
    }
  }, []);

  return (
    <Box className={`${classes.xheader} xHeaderSectionWrapper`}>
      {/* <Fragment> */}
      <Hidden lgUp>
        <HeaderMobile
          data={data}
          isLogin={isLogin}
          handleIconRedirect={handleIconRedirect}
          language={language}
          langCode={langCode}
          handleLanguageRedirect={handleLanguageRedirect}
          logoutButtonHandle={logoutButtonHandle}
          isLoginEnabled={isLoginEnabled}
          isCartIconEnable={isCartIconEnable}
          isProductUpdateCount={isProductUpdateCount}
          navigateToCartPage={navigateToCartPage}
          gcpUrl={gcpUrl}
          bucketName={bucketName}
          handleOpenModal={handleOpenModal}
          onLogin={onLogin}
        />
      </Hidden>
      <Hidden lgDown>
        <AppBar position='static' className='x-header-appbar headerBackground'>
          <Box component='nav'>
            <Box className='logo'>
              {/* logo */}
              <HeaderLogo
                data={data}
                width={"100%"}
                gcpUrl={gcpUrl}
                height={"100%"}
                bucketName={bucketName}
                handleIconRedirect={handleIconRedirect}
              />
            </Box>
            <Box className='rightMenuWarp'>
              <List className='menuList'>
                {data?.Menus?.map((menu: Menus, key: number) => (
                  <ListItem
                    key={`${key?.toLocaleString()}_${menu?.Menu_Id}_parent`}
                    className='menuItemList'>
                    <Button
                      href={
                        menu?.Submenu.length > 0
                          ? "#"
                          : getHrefforAnchors(menu?.URL, langCode, menu?.Internal)
                      }
                      target={menu?.Internal ? "_self" : "_blank"}
                      component='a'
                      key={`${key?.toLocaleString()}_${menu.Menu_Id}`}
                      id={`menu_${menu.Menu_Id}`}
                      className={`x-app-menu ${
                        (currentMenu ? currentMenu === menu?.Menu_Id : false) ? "active" : ""
                      }`}
                      aria-controls={
                        (currentMenu ? currentMenu === menu?.Menu_Id : false)
                          ? `submenu_${menu.Menu_Id}`
                          : undefined
                      }
                      aria-haspopup='true'
                      aria-expanded={
                        (currentMenu ? currentMenu === menu?.Menu_Id : false) ? "true" : undefined
                      }
                      endIcon={
                        menu?.Submenu.length > 0 ? (
                          (currentMenu ? currentMenu === menu?.Menu_Id : false) ? (
                            <KeyboardArrowUp />
                          ) : (
                            <KeyboardArrowDown />
                          )
                        ) : null
                      }
                      onClick={
                        menu?.Submenu.length > 0
                          ? (e) => handleSubMenuOpen(e, menu?.Menu_Id)
                          : () => {}
                      }
                      data-testid={`menu-button-${menu.Menu_Id}`}
                      //onMouseOver={menu?.Submenu.length > 0 ? (e) => handleSubMenuOpen(e, menu?.Menu_Id) : () => {}}
                    >
                      <Typography component='span' variant='h6regular' className='my0'>
                        {menu?.Label}
                      </Typography>
                    </Button>

                    {menu.Submenu.length > 0 && (
                      <Menu
                        id={`submenu_${menu.Menu_Id}`}
                        className={`menu_class ${classes.headerPopupMenu} xHeaderPopupMenuWrapper`}
                        anchorEl={currentAnchorEl}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                        open={Boolean(currentMenu && currentMenu === menu?.Menu_Id)}
                        onClose={handleSubMenuClose}
                        MenuListProps={{
                          "aria-labelledby": `menu_${menu.Menu_Id}`,
                          className: "firstUlWrapper",
                        }}
                        PaperProps={{
                          className: "xHeaderPopupCardWrapper",
                          elevation: 0,
                        }}>
                        <List className='popupMenuList'>
                          {menu.Submenu.length > 0 &&
                            menu.Submenu.map((submenu: Menus, subkey: number) => (
                              <ListItem
                                className='popupMenuListItems'
                                sx={{
                                  margin: menu.Submenu.length === subkey + 1 ? "0" : "0 0 10px 0",
                                }}
                                key={subkey?.toLocaleString()}
                                // onClick={() =>
                                //   handleRedirect(
                                //     submenu?.URL,
                                //     submenu?.Internal,
                                //     submenu.IsCurrentTab
                                //   )
                                // }
                              >
                                <ListItemButton
                                  component='a'
                                  href={getHrefforAnchors(
                                    submenu?.URL,
                                    langCode,
                                    submenu?.Internal,
                                  )}
                                  target={
                                    submenu?.Internal
                                      ? "_self"
                                      : submenu.IsCurrentTab
                                        ? "_self"
                                        : "_blank"
                                  }>
                                  <ListItemAvatar className='iconGapRight'>
                                    <Avatar className='avatarbox'>
                                      <img
                                        src={formRelativeURL(gcpUrl, bucketName, submenu.menuicon)}
                                        alt='menuicon'
                                        width={24}
                                        height={24}
                                      />
                                    </Avatar>
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={
                                      <Fragment>
                                        <Typography
                                          variant='h6medium'
                                          m={0}
                                          className={`menu-submenu-link submenuTitle`}>
                                          {submenu.Label}
                                        </Typography>
                                        {!submenu.Internal && (
                                          <IconButton
                                            edge='end'
                                            aria-label='launch'
                                            className='externalLinkIcon'>
                                            <LaunchIcon className='iconLaunch' />
                                          </IconButton>
                                        )}
                                      </Fragment>
                                    }
                                    secondary={submenu.Description}
                                    secondaryTypographyProps={{
                                      variant: "h6medium",
                                      color: theme.palette.header.VARIANT1.TITLE,
                                      margin: 0,
                                    }}
                                  />
                                </ListItemButton>
                              </ListItem>
                            ))}
                        </List>
                        {menu.content_type_value &&
                          menu.content_type_value.Title &&
                          menu.content_type_value.Image &&
                          menu.content_type_value.RedirectionUrl &&
                          Object.keys(menu.content_type_value).length !== 0 && (
                            <Fragment>
                              <Divider orientation='vertical' className='menuVerticalDivider' />
                              <Card
                                className='subMenuCards'
                                onClick={() =>
                                  handleContentRedirect(
                                    menu.content_type_value.RedirectionUrl,
                                    menu.content_type_value.ContentType,
                                  )
                                }
                                data-testid='content-redirect'>
                                <CardMedia
                                  image={menu.content_type_value?.Image}
                                  title='card'
                                  className='subMenuCardsMedia'
                                />
                                <CardContent
                                  style={{
                                    paddingBottom: menu.content_type_value?.Description
                                      ? "16px"
                                      : "11px",
                                  }}>
                                  <Typography
                                    gutterBottom
                                    variant='h6medium'
                                    component='div'
                                    className='doublebr'
                                    m={0}>
                                    {menu.content_type_value?.Title}
                                  </Typography>
                                  {menu.content_type_value?.Description && (
                                    <Typography
                                      variant='p3regular'
                                      color='headerParagraphColor'
                                      className='singlebr single-item'
                                      m={0}
                                      dangerouslySetInnerHTML={{
                                        __html: menu.content_type_value?.Description || "",
                                      }}></Typography>
                                  )}
                                </CardContent>
                              </Card>
                            </Fragment>
                          )}
                      </Menu>
                    )}
                  </ListItem>
                ))}
              </List>
              <Box className='rightMenuButtonWrapper'>
                {data?.search === "true" && (
                  <IconButton
                    onClick={handleClickOpen}
                    data-testid='right-menu-btn-wrapper'
                    size='large'
                    className='hovernone'
                    aria-label='show 4 new mails'
                    color='inherit'>
                    <SearchIcon />
                  </IconButton>
                )}
                {openSearch && (
                  <AdvanceSearchPopup
                    onClosePopup={handleClickClose}
                    secondaryArgs={secondaryArgs}
                    gcpUrl={gcpUrl}
                    bucketName={bucketName}
                  />
                )}
                {data?.icon_setting?.tag_logo === "true" && (
                  <RpiTagPopupIconButton
                    onClick={handleOpenModal}
                    data-testid='rpi-tag-popup-icon-button'
                  />
                )}
                {/* ecom page header */}
                {data?.icon_setting?.cart_logo === "true" && (
                  <EcomHeader
                    isCartIconEnable={isCartIconEnable}
                    navigateToCartPage={navigateToCartPage}
                    isProductUpdateCount={isProductUpdateCount}
                  />
                )}
                {language && language?.length > 0 ? (
                  <IconButton
                    onClick={handleClick}
                    size='large'
                    className='hovernone iconFlag header-space-between-items'
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? "true" : undefined}>
                    <Box className='headerFlagIcon'>
                      <img alt='flagicon' src={getFlag()} width={24} height={24} />
                    </Box>
                  </IconButton>
                ) : null}

                {Object.keys(userLoginData || {}).length > 0 ? (
                  <Box className='authWrapper'>
                    <img
                      alt='loginavatar'
                      src={avtarImg}
                      width='30'
                      height='30'
                      onClick={handleOpen}
                      data-testid='login-avatar'
                    />
                    <Typography
                      style={{ whiteSpace: "nowrap" }}
                      color='headerParagraphColor'
                      ml={2}>
                      {name}
                    </Typography>

                    <Menu anchorEl={anchorE2} open={openMenu} onClose={handleCloseMenu}>
                      <MenuItem
                        disableRipple
                        onClick={handleChangePassword}
                        data-testid='change-password'>
                        {t("change_password")}
                      </MenuItem>
                      <MenuItem disableRipple onClick={handleMyProfile} data-testid='my-profile'>
                        {t("my_profile")}
                      </MenuItem>
                      <MenuItem disableRipple onClick={logoutButtonHandle} data-testid='logout'>
                        {t("logout_text")}
                      </MenuItem>
                    </Menu>
                  </Box>
                ) : isLoginEnabled ? (
                  data?.cta_title && (
                    <Button
                      variant='primaryButton2'
                      className='header-button primaryButton2 sm'
                      onClick={onLogin}
                      data-testid='header-login'>
                      {data?.cta_title}
                    </Button>
                  )
                ) : null}
              </Box>
              <LanguageDesktopView
                open={open}
                language={language}
                anchorEl={anchorEl}
                handleClose={handleClose}
                handleLanguageRedirect={handleLanguageRedirect}
              />
            </Box>
          </Box>
        </AppBar>
      </Hidden>
      {/* </Fragment> */}
    </Box>
  );
};

export default Header;
