import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import graphQlList from "../../../graphql/fetch";
import FetchError from "../Component/RewardCards/Common/FetchError";
import NoDataFound from "../Component/RewardCards/Common/NoDataFound";
import MyRewards from "../Component/RewardCards/MyRewards/MyRewards";
import MyRewardsSkeleton from "../Component/RewardCards/MyRewards/MyRewardsSkeleton";
import { REWARDS_TABS } from "../helperProfile";
import { useCustomStyle } from "./Rewards.style";

const MyRewardsContainer = ({ secondaryArgs, memberId, type, searchText, filterText }) => {
  const classes = useCustomStyle();
  const [rewards, setRewards] = useState<{ rewardList: any[]; total: any } | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1); // Track current page for pagination

  const fetchMyRewardsAndUsedRewards = async () => {
    const { prelemBaseEndpoint: { language = "en", deliveryEndPointGq = "" } = {}, sitename } =
      secondaryArgs;
    try {
      setLoading(true);
      const { data, errors } = await graphQlList?.fetchMyRewardAndUsedRewardData(
        {
          memberId: memberId,
          pagination: {
            start: !searchText ? page : 1, // Set start to current page
            rows: REWARDS_TABS.NUMBER_OF_ROWS_PER_PAGE, // Number of items per page
          },
          sort: filterText ? filterText : "desc",
          filter: {
            search: searchText ? searchText : "",
            status: type === REWARDS_TABS.MY_REWARDS ? "issued" : "completed",
            reward_type: "static_coupon",
            category: "",
          },
        },
        language,
        sitename,
        deliveryEndPointGq,
      );
      if (data?.users_getMemberIssuedRewardList) {
        const newRewards = data.users_getMemberIssuedRewardList.rewardList;
        // If rewards are received, update state
        setRewards((prevState) => ({
          rewardList: prevState ? [...prevState.rewardList, ...newRewards] : newRewards,
          total: data.users_getMemberIssuedRewardList.total, // Set the total as the whole object
        }));

        // Check if we have loaded all rewards based on the filtered total
        const filteredTotal = data.users_getMemberIssuedRewardList.total?.filtered || 0;
        const currentLoaded = rewards?.rewardList.length || 0;

        // Stop loading if the number of loaded rewards is equal or greater than filtered total
        if (currentLoaded + newRewards.length >= filteredTotal) {
          setHasMore(false);
        }
      } else {
        console.error("getMemberIssuedRewardListAPIFailed", errors);
        setError(errors?.[0]?.message ? errors?.[0]?.message : `Failed to fetch ${type}`);
      }
    } catch (err: any) {
      console.error("Error fetching rewards:", err);
      setError(err.message || "An unexpected error occurred");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (memberId) {
      setRewards(null);
      fetchMyRewardsAndUsedRewards();
    }
  }, [memberId, type, searchText, filterText]);

  // Load more data when the user scrolls to the bottom
  const loadMoreData = () => {
    if (!loading && hasMore) {
      setPage((prevPage) => prevPage + 1);
      fetchMyRewardsAndUsedRewards();
    }
  };

  return (
    <Box className={`${classes.RewardsWrapper} rewardsWrapperSection`}>
      <Box sx={{ width: "100%" }} className='MyRewardsAndUsedRewards'>
        {loading && !error && !rewards && <MyRewardsSkeleton />}
        {error && !loading && <FetchError message={error} />}
        {rewards?.rewardList?.length === 0 && <NoDataFound message={"No Data Found"} />}
        {(rewards?.rewardList?.length ?? 0) > 0 && (
          <InfiniteScroll
            dataLength={rewards?.rewardList?.length || 0} // Length of current rewards list
            next={loadMoreData} // Function to load more data
            hasMore={hasMore} // Whether there are more items to load
            loader={loading && !error ? <MyRewardsSkeleton /> : null} // Loader to show while loading
            scrollThreshold={0.8} // Trigger load more when 90% of the page is scrolled
            scrollableTarget='MyRewardsAndUsedRewards'>
            {rewards?.rewardList?.map((item) => (
              <MyRewards
                secondaryArgs={secondaryArgs}
                key={item?.issued_reward_id}
                item={item}
                type={type}
              />
            ))}
          </InfiniteScroll>
        )}
      </Box>
    </Box>
  );
};

export default MyRewardsContainer;
