import { Box, TextareaAutosize, useTheme } from "@mui/material";
import { useState } from "react";
import { fetchValidations, getMaxLength, handleLength } from "../../helper";
import ErrorMessageHandle from "../Error/ErrorMessageHandle";
import MaxLengthValidationMessage from "../Error/MaxLengthValidationMessage";

const TextArea = ({
  field,
  errors,
  isDisabled,
  register,
  clearErrors,
  handleOnBlur,
  handleChange,
  isRendering,
  minRows = 6,
}: any) => {
  const inlineCss = `
  @media screen and (max-height: 600px) and (orientation: landscape) {
    textarea{
      height:auto !important;
      overflow-y:hidden !important;
  }
  }
  @media only screen and (max-width: 767px){
    textarea{
      height:220px !important;
      overflow-y:auto !important;
  }
  
}

  `;
  const theme = useTheme();
  const [restOfChar, setRestOfChar] = useState({
    lengthOfState: 0,
    restOfLength: 0,
    reachLimit: false,
  });
  const validations = fetchValidations(field?.validations);

  return (
    <Box>
      <style>{inlineCss}</style>
      <TextareaAutosize
        disabled={isDisabled}
        aria-label='minimum height'
        minRows={minRows}
        placeholder={field?.placeholder}
        name={field.name}
        id={field.name}
        error={errors[field.name]}
        className={`${isRendering ? "form-outline textarea" : "authoring textarea"} ${errors[field.name] ? "textbox-error" : "textbox-default"}`}
        maxLength={getMaxLength(field?.validations)}
        sx={{
          "& textarea:focus": {
            border: `${theme.palette.borderFocusedColor} !important`,
          },
        }}
        style={{
          width: "100%",
          resize: "none",
          padding: "12px",
          border: errors[field.name]
            ? isRendering
              ? `solid 1px #F33E41`
              : `solid 1px ${theme.palette.customColor.ERROR}`
            : isRendering
              ? `solid 1px #D9DBE9`
              : `solid 1px ${theme.palette.customColor.LINE}`,
          borderRadius: "5px",
          background: errors[field.name] ? "#FFEBEE" : "transparent",
          fontFamily: "Inter",
          fontSize: theme.fontSize?.fontSize_14,
          color: theme.palette.customColor.PRIMARY,
        }}
        {...register(field.name, {
          ...validations,
          onChange: (event) => {
            if (errors[field.name]) {
              clearErrors(field.name);
            }
            const { target: { value = "" } = {} } = event;
            handleLength(value, field?.validations, setRestOfChar, restOfChar);
            if (handleChange) {
              handleChange(event);
            }
          },
          onBlur: (event) => {
            handleOnBlur(event);
          },
        })}
      />
      <ErrorMessageHandle error={errors[field?.name]} />
      <MaxLengthValidationMessage
        validations={field?.validations}
        error={errors[field?.name]}
        restOfChar={restOfChar}
      />
    </Box>
  );
};
export default TextArea;
