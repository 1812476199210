import { Box, Button, Chip, Grid, Typography } from "@mui/material";
import { Coin, MoneyIcon, PercentageIcon } from "@platformx/shared/static-assets";
import { PlatFormXDateTimeFormat } from "@platformx/utilities";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import graphQlList from "../../../../../graphql/fetch";
import { handleCopy, isExpired, REWARDS_TABS, showDetailMessage } from "../../../helperProfile";
import OfferCard from "../Common/OfferCard";
import { useCustomStyle } from "./MyRewards.style";

interface Detail {
  images?: { image: string }[];
  conditions_description?: string;
  usage_instruction?: string;
  brand_name?: string;
  brand_description?: string;
}

const MyRewards = ({ item, secondaryArgs, type }) => {
  const classes = useCustomStyle();
  const { t } = useTranslation();
  const [showDetail, setShowDetail] = useState(false);
  const [detail, setDetail] = useState<Detail | null>(null);
  const [buttonText, setButtonText] = useState("Copy Code");
  const [isDetailAvailable, setIsDetailAvailable] = useState(false);
  const toggleDetail = () => {
    setShowDetail((prevState) => !prevState);
  };
  const clickHandler = (code) => {
    handleCopy(code, setButtonText, "Copy Code");
  };
  const expiredItem = isExpired(item?.issued_coupon?.active_to);

  const fetchRewardDetailById = async () => {
    const { prelemBaseEndpoint: { language = "en", deliveryEndPointGq = "" } = {}, sitename } =
      secondaryArgs;
    try {
      const { data, errors } = await graphQlList.fetchRewardDetailByID(
        {
          rewardId: item?.reward_id,
        },
        language,
        sitename,
        deliveryEndPointGq,
      );
      if (data?.users_getRewardDetails) {
        setDetail(data.users_getRewardDetails);
        showDetailMessage(data.users_getRewardDetails, setIsDetailAvailable);
      } else {
        console.error("ContentAPIFailed", errors);
        return null;
      }
    } catch (err: any) {
      console.error("Error fetching rewards:", err);
    }
  };
  const renderIcon = (issued_coupon) => {
    if (issued_coupon?.value_type === "money") {
      return <img src={MoneyIcon} className='coinImage icons' alt='money discount' />;
    }
    if (issued_coupon?.value_type === "percentage") {
      return <img src={PercentageIcon} className='coinImage icons' alt='Percentage discount' />;
    } else {
      return <img src={Coin} className='coinImage icons' alt='coin' />;
    }
  };

  useEffect(() => {
    fetchRewardDetailById();
  }, []);

  return (
    <Box
      className={`${expiredItem ? "expiredRewards" : ""} ${classes.MyRewardsCardWrapper} MyRewardsCardWrapperSection`}>
      <Box className='cardDetail'>
        <Box width='100%' display='flex'>
          <Grid item>
            <Box className='rewardProduct'>
              {detail && <OfferCard imageUrl={detail?.images?.[0]?.image} />}
            </Box>
          </Grid>
          <Grid item xs ml={2} className='coupanDetailSection'>
            <Box>
              <Typography variant='h4regular' className='noTopMargin lessGap one-line-ellipsis'>
                {item?.reward_name}
              </Typography>
              <Typography variant='h5regular' className='noTopMargin lessGap two-line-ellipsis'>
                {item?.rewardDescription}
              </Typography>
            </Box>
            <Box mt={2} display={"flex"}>
              {item?.issued_coupon?.coupon_code && (
                <Box mr={1}>
                  <Typography variant='h5regular' component='span' className='noMargin'>
                    Coupon Code: {item?.issued_coupon?.coupon_code}
                  </Typography>
                </Box>
              )}
              <Box display='flex'>
                <Chip
                  label={<Typography variant='p4regular'>{item?.issued_coupon?.value}</Typography>}
                  className='customchip'
                  icon={renderIcon(item?.issued_coupon)}
                />
                {item?.issued_coupon?.active_to && (
                  <Typography variant='h5regular' className='noTopMargin labelColor' ml={2}>
                    Expires: {PlatFormXDateTimeFormat(item?.issued_coupon?.active_to, false)}
                  </Typography>
                )}
              </Box>
            </Box>
          </Grid>
        </Box>
        <Grid item className='buttonWrapper'>
          <Box className='orderActionColumn'>
            {type === REWARDS_TABS.MY_REWARDS ? (
              <Button
                variant='tertiaryButton1'
                onClick={() => clickHandler(item?.issued_coupon?.coupon_code)}
                className='sm'
                disabled={expiredItem || !item?.issued_coupon?.coupon_code}
                type='button'
                sx={{ width: "100%" }}>
                {buttonText}
              </Button>
            ) : (
              <Button
                variant='tertiaryButton2'
                className='sm dashed'
                disabled={true}
                type='button'
                sx={{ width: "100%", marginBottom: 0, border: "dashed", color: "green" }}>
                Applied
              </Button>
            )}
            <Button
              variant='tertiaryButton2'
              className='sm'
              onClick={toggleDetail}
              disabled={expiredItem}
              type='button'
              sx={{ width: "100%", marginBottom: 0 }}>
              {showDetail ? "Hide Details" : t("view_details")}
            </Button>
          </Box>
        </Grid>
      </Box>
      {showDetail && (
        <Box className='viewDetails'>
          <Typography variant='h5regular' className='noTopMargin'>
            {isDetailAvailable ? "Details" : "No details available"}
          </Typography>
          <Box className='description'>
            {detail?.brand_name && (
              <Typography
                variant='h5regular'
                className='noMargin'
                dangerouslySetInnerHTML={{ __html: detail?.brand_name }}
              />
            )}
            {detail?.brand_description && (
              <Typography
                variant='p4regular'
                dangerouslySetInnerHTML={{ __html: detail?.brand_description }}
              />
            )}
            {detail?.usage_instruction && (
              <Typography
                variant='p4regular'
                dangerouslySetInnerHTML={{ __html: detail?.usage_instruction }}
              />
            )}
            {detail?.conditions_description && (
              <Typography
                variant='p4regular'
                dangerouslySetInnerHTML={{ __html: detail?.conditions_description }}
              />
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default MyRewards;
