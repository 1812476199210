import { OLD_CONTENT_TYPES } from "../../constants/CommonConstants";
import useUserSession from "../useUserSession/useUserSession";

interface Access {
  canAccessContent: (category: string, subcategory: string) => boolean;
  canAccessAction: (category: string, subcategory: string | string[], action: string) => boolean;
}

const useAccess = (): Access => {
  const [getSession] = useUserSession();
  const { permissions } = getSession();

  /* Method to validate the action accesses like sidebar menus */
  const canAccessContent = (category: string, subcategory: string): boolean => {
    let isValid = false;

    if (category?.toLowerCase() === "public") {
      return true;
    }
    //this code needs to be rewritten after permission is implemented
    isValid =
      category?.toLowerCase() !== "content" ||
      (category?.toLowerCase() === "content" &&
        OLD_CONTENT_TYPES.some((x) => x.toLowerCase() === subcategory.toLowerCase()))
        ? permissions?.some(
            (permission) =>
              (permission.category?.toLowerCase() === category?.toLowerCase() &&
                (subcategory === "" || permission?.sub_category?.trim() === "")) ||
              (permission?.category?.toLowerCase() === category?.toLowerCase() &&
                permission?.sub_category?.toLowerCase() === subcategory?.toLowerCase()),
          )
        : true;
    return isValid;
  };

  /* Method to validate the action accesses like create, update, delete, */
  const canAccessAction = (
    category: string,
    subcategory: string | string[],
    allowedAction: string,
  ): boolean => {
    if (permissions[0]?.actions[0]?.toLowerCase() === "all") {
      return true;
    }
    const subCategories = Array.isArray(subcategory) ? subcategory : [subcategory.toLowerCase()];
    // const accessObject = permissions?.find((permission: any) => {
    //   return (
    //     permission.category?.toLowerCase() === category?.toLowerCase() &&
    //     subCategories.includes(permission.sub_category?.toLowerCase())
    //   );
    // });
    const accessObject = permissions?.find((permission: any) => {
      return subCategories.includes(permission.sub_category?.toLowerCase());
    });
    const lowerCaseActions = accessObject?.actions.map((x) => x.toLowerCase()) || [];
    //this code needs to be rewritten after permission is implemented
    // return Array.isArray(subcategory)
    //   ? true
    //   : category?.toLowerCase() !== "content" ||
    //       (category?.toLowerCase() === "content" &&
    //         OLD_CONTENT_TYPES.some((x) => x.toLowerCase() === subcategory.toLowerCase()))
    //     ? lowerCaseActions.includes(allowedAction?.toLowerCase())
    //     : true;
    return Array.isArray(subcategory)
      ? true
      : lowerCaseActions.includes(allowedAction?.toLowerCase());
  };

  return { canAccessContent, canAccessAction };
};

export default useAccess;
