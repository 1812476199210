import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import {
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { PlatFormXDateTimeFormat, formCroppedUrl } from "@platformx/utilities";
import { Fragment, Suspense, lazy, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import "../../Style.css";
import ImageRender from "../../components/ImageRender";
import { useCustomStyle } from "../Article/Article.style";

const Share = lazy(() => import("../../components/Share/Share"));
const RecentCarousel = lazy(() => import("../../components/RecentCarousel/RecentCarousel"));

const Article = ({
  content = {},
  analytics,
  authoringHelper,
  secondaryArgs = {},
  showRecentArticles = true,
}: any) => {
  const { bucketName = "", gcpUrl = "" } = secondaryArgs;
  const classes = useCustomStyle();
  const theme = useTheme();
  const { ref } = useInView({
    /* Optional options */
    threshold: 0,
  });
  const firstRender = useRef(true);
  const { t, i18n } = useTranslation();

  const getArticleUrl = () => {
    let id = content?.current_page_url;
    if (id && id.charAt(0) === "/") {
      id = id.substring(1);
    }
    return `${secondaryArgs?.prelemBaseEndpoint?.PublishEndPoint}${secondaryArgs?.prelemBaseEndpoint?.language}/article/${id}`;
  };
  useEffect(() => {
    if (typeof window !== "undefined") {
      i18n.changeLanguage(secondaryArgs?.prelemBaseEndpoint?.language);
    }
  }, []);
  const defaultStructureData = () => {
    let ArticleStructureData;
    try {
      ArticleStructureData = {
        "@context": "https://schema.org",
        "@type": "ItemList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            url: content?.Button1_RedirectURL,
            name: content?.Button1_Value,
          },
          {
            "@type": "ListItem",
            position: 2,
            url: content?.Slots[0].EditorialItemPath,
            name: content?.Slots[0].Title,
          },
          {
            "@type": "ListItem",
            position: 3,
            url: content?.Slots[1].EditorialItemPath,
            name: content?.Slots[1].Title,
          },
          {
            "@type": "ListItem",
            position: 4,
            url: content?.Slots[2].EditorialItemPath,
            name: content?.Slots[2].Title,
          },
        ],
      };
    } catch (e) {
      ArticleStructureData = {};
    }

    return ArticleStructureData;
  };

  const generateStructureData = () => {
    let ArticleStructureData;
    const tempSD = String(authoringHelper?.lastSavedStructuredData);

    if (firstRender.current === true) {
      const defaultSD = defaultStructureData();
      const stringifyStructureData = defaultSD && JSON.stringify(defaultSD);
      authoringHelper?.sendDefaultStructureDataForResetToAuthoringCB(stringifyStructureData || "");

      if (String(tempSD).length > 0) {
        ArticleStructureData = JSON.parse(tempSD);
      } else {
        ArticleStructureData = defaultStructureData();
      }
      firstRender.current = false;
    } else {
      ArticleStructureData = defaultStructureData();
    }
    return ArticleStructureData;
  };

  useEffect(() => {
    if (analytics?.isAuthoring && analytics?.isSeoEnabled) {
      const structureData = generateStructureData();
      const stringifyStructureData = structureData && JSON.stringify(structureData);
      authoringHelper?.sendStructureDataToAuthoringCB(stringifyStructureData || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content?.Slots]);
  const articleStyle = `
  blockquote{
    margin: 0;
    border-left: 3px solid black;
    padding: 5px 10px;
    font-style:italic;
  }
  .descAsset {
    height: 366px;
    width: 100%;
  }
  @media only screen and (max-width: 600px) {
    .descAsset {
    width: 100%;
    height: 100%;
  }`;

  const publishUrl = window.location.origin + "/";
  const language = secondaryArgs?.prelemBaseEndpoint?.language;
  const embedPageURL = publishUrl + language + "/embed/article" + content?.current_page_url;
  const landingPageURL = publishUrl + language + "/article" + content?.current_page_url;

  const embedData = {
    Page: embedPageURL,
    Title: content?.title,
    LandingPage: landingPageURL,
    Author: content?.developed_by,
    Description: content?.description,
    creationDate: content?.developed_date,
    ContentURL: content?.PageSettings?.SocialOgURL,
    Thumbnail: formCroppedUrl(
      gcpUrl,
      bucketName,
      content?.original_image?.original_image_relative_path,
      content?.original_image?.ext,
    ),
  };
  return (
    <div
      ref={authoringHelper?.innerRef}
      className={`${classes.articleDetailPageWrapper} articleDetailPage`}>
      <Grid container ref={ref}>
        <style>{articleStyle}</style>
        <Grid item xs={12} className='articleHeroImage'>
          <Box
            sx={{
              // height: { md: "457px", sm: "457px", xs: "205px", lg: "457px" },
              aspectRatio: {
                xs: "4 / 3",
                sm: "4 / 3",
                md: "4 / 3",
                em: "4 / 3",
                lg: "3 / 1",
                xl: "3 / 1",
              },
              position: "relative",
            }}>
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                background: "linear-gradient(180deg, rgba(0,0,0,0.0001) 0%, #000000 100%)",
                mixBlendMode: "normal",
                opacity: "0.5",
                zIndex: 1,
              }}></div>
            <ImageRender
              originalImage={content?.original_image}
              publishedImages={content?.published_images}
              secondaryArgs={secondaryArgs}
              imgOrder={{
                1440: "hero",
                1280: "hero",
                1024: "card2",
                768: "card2",
                600: "card2",
                320: "card2",
              }}
            />
            {/* <img
              src={fetchCroppedUrl(content?.Banner, content?.published_images, { 1440: 'landscape', 1280: 'landscape', 1024: 'portrait', 768: 'portrait', 600: 'square', 320: 'square' })}
              alt={"Banner_Image"}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            /> */}
            {/* <Box sx={{ display: 'flex',
                            position: { xs: 'initial', md: 'absolute' },
                            width: { xs: '100%', sm: '100%', md: '700px' },
                            margin: '-450px auto 0px 230px',
                            color: 'white'
                            }}>
                            <KeyboardArrowLeftIcon />
                            <Link
                                to="/" style={{ textDecoration: 'none', color: 'white'}}
                                > <Typography variant='h3medium'> Back </Typography>
                            </Link>
                        </Box> */}
            <Box
              sx={{
                position: { xs: "absolute", md: "relative" },
                bottom: "10px",
                width: { xs: "100%", sm: "100%", md: "700px" },
                marginTop: "-15px",
                margin: { md: "-10px auto" },
                zIndex: 2,
              }}>
              <Tooltip title={content?.title} placement='top'>
                <Typography variant='h1bold' id='title' color='textColor' className='heroTitle'>
                  {content?.title}
                </Typography>
              </Tooltip>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} className='articleDescription prelem-pb'>
          <Card
            className='articleCardDescription'
            sx={{
              boxShadow: "none",
              borderRadius: "0px",
              margin: { xs: "0", md: "0 auto" },
              width: { xs: "100%", sm: "100%", md: "700px" },
              padding: { xs: "0px 3% 0px 3%", md: "0px" },
            }}>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "24px 0",
              }}>
              <Box sx={{ display: "flex" }}>
                {/* <img
                  alt="Article"
                  width="16px"
                  height="20px"
                  src={ProfileIcon}
                ></img> */}
                <AccountCircleOutlinedIcon className='avtartIcon' width='16px' height='20px' />
                <Typography variant='p4regular' className='noMarginBoth' sx={{ ml: "5px" }}>
                  {content?.page_lastmodifiedby}
                </Typography>
              </Box>
              <Typography variant='p4regular' className='noMarginBoth'>
                {PlatFormXDateTimeFormat(content?.developed_date)}
              </Typography>
            </Box>
            <Divider variant='fullWidth' />
            <CardContent style={{ padding: "12px 0px 12px 0px" }}>
              <Typography
                variant='p3regular'
                dangerouslySetInnerHTML={{ __html: content?.description }}></Typography>
            </CardContent>
            <Divider variant='fullWidth' />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexFlow: { xs: "column", md: "row" },
                mb: "30px",
                mt: "25px",
              }}>
              {content?.tags && content?.tags?.length > 0 && (
                <Box sx={{ mb: { xs: "15px", md: 0 } }}>
                  <Typography variant='h4medium' className='title'>
                    {t("related_tags")}
                  </Typography>
                  {content?.tags?.map((tag: string) => {
                    return (
                      <Fragment key={tag}>
                        {tag && (
                          <Chip
                            label={tag}
                            key={tag}
                            variant='outlined'
                            style={{
                              fontSize: "14px",
                              fontWeight: "normal",
                              marginRight: "10px",
                              marginTop: "5px",
                              backgroundColor: "#fff",
                              // backgroundColor: "#e6eaed",
                              borderRadius: theme.borderRadius.value,
                              border: "1px solid #14142B",
                            }}
                          />
                        )}
                      </Fragment>
                    );
                  })}
                </Box>
              )}
              <Box>
                <Typography variant='h4medium'>{t("share_text")}</Typography>
                <Suspense fallback={<div></div>}>
                  <Share
                    domainUrl={getArticleUrl()}
                    shareUrl={content?.settings?.socialog_url}
                    embedData={embedData}
                    whiteIcon={false}
                    border={`solid 1px ${theme.palette.prelemType1.LINE}`}
                  />
                </Suspense>
              </Box>
            </Box>
            <Suspense fallback={<div></div>}>
              <Box>
                {showRecentArticles && (
                  <RecentCarousel
                    heading={t("related_articles")}
                    filter='Article' //{content?.ContentType}
                    secondaryArgs={secondaryArgs}
                    // tags={content?.tags}
                  />
                )}
              </Box>
            </Suspense>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Article;
