import { Box, CardMedia, Typography } from "@mui/material";
import { getImage } from "@platformx/utilities";
import { getIcon } from "../../../Utils/helperFns";
import { useCustomStyle } from "./WheelCard.style";

const WheelCard = ({ content, secondaryArgs, onWheelCardClick }) => {
  const classes = useCustomStyle();

  const onClickCard = () => {
    onWheelCardClick(content);
  };

  const formedUrl = getImage(content, secondaryArgs);
  const { color, imageUrl } = formedUrl;
  return (
    <Box className={`${classes.XCard5Wrapper} XCard5Box`} onClick={onClickCard}>
      <Box className='XCard5innderBox'>
        <Box className='imgWrapper'>
          <CardMedia
            component={imageUrl ? "img" : "div"}
            image={imageUrl ? imageUrl : ""}
            title={imageUrl ? content?.Thumbnail?.AltText : "image"}
            sx={{ backgroundColor: color ? color : "", height: "100%", width: "100%" }}
          />
          <Box className='mainContentBox'>
            <Box className='contentWrapperCard5Box'>
              <Box className='iconWrapper'>
                <img
                  src={getIcon(
                    content?.ContentType,
                    content?.Content_icon,
                    secondaryArgs?.gcpUrl,
                    secondaryArgs?.bucketName,
                  )}
                  alt='icon'
                  width={25}
                  height={25}
                />
              </Box>
              <Box className='titleWrapper'>
                <Typography variant='p1regular' color='textColor'>
                  {content?.Title}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default WheelCard;
