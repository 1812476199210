import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, IconButton, Typography } from "@mui/material";
// To add pagination buttons
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { XAnimatedLoader } from "@platformx/shared/static-assets";
import { PlatFormXDateTimeFormat } from "@platformx/utilities";
import { useEffect, useState } from "react";
import graphQlList from "../../../../../graphql/fetch";
import { isExpired, REWARDS_TABS } from "../../../helperProfile";
import FetchError from "../Common/FetchError";
import NoDataFound from "../Common/NoDataFound";
import { useCustomStyle } from "./ExpiringReward.style";

interface Row {
  point_id: string | null;
  point_desc: string | null;
  created_at: string | null;
  expires_at: string | null;
  points: number | 0;
  type: string | null;
  sNo: number | 0;
}

const ExpiringReward = ({ secondaryArgs, memberId }) => {
  const classes = useCustomStyle();
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<string>("");
  const [rows, setRowData] = useState<Row[]>([]);
  const [page, setPage] = useState(1); // Current page
  const [pageData, setPageData] = useState<Map<number, Row[]>>(new Map()); // Cache for page data
  const [loading, setLoading] = useState<boolean>(false);
  const rowsPerPage = REWARDS_TABS.NUMBER_OF_ROWS_PER_PAGE_IN_TABLE;
  const [total, setTotal] = useState(rowsPerPage);
  const [error, setError] = useState("");

  // Sorting function
  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // Sort rows based on the selected column
  const sortedRows = [...rows].sort((a, b) => {
    if (orderBy === "") return 0;

    const aValue = a[orderBy as keyof Row];
    const bValue = b[orderBy as keyof Row];

    if (aValue === null) return 1;
    if (bValue === null) return -1;

    if (aValue < bValue) {
      return order === "asc" ? -1 : 1;
    }
    if (aValue > bValue) {
      return order === "asc" ? 1 : -1;
    }
    return 0;
  });

  // Fetch data with pagination
  const expiringPoints = async (pageNum: number) => {
    const { prelemBaseEndpoint: { language = "en", deliveryEndPointGq = "" } = {}, sitename } =
      secondaryArgs;
    try {
      setLoading(true);
      const { data, errors } = await graphQlList.expiringCoupon(
        {
          memberId: memberId,
          pagination: {
            start: pageNum, // Dynamic page number
            rows: rowsPerPage, // Number of rows per page
          },
          sort: "desc",
          filter: {
            search: "",
          },
        },
        language,
        sitename,
        deliveryEndPointGq,
      );
      if (data?.users_pointsToExpire?.pointsList) {
        const newRewards = data.users_pointsToExpire?.pointsList;
        setTotal(data.users_pointsToExpire?.total?.filtered);
        // Update the cache with the new page data

        const updatedRewards = newRewards.map((reward, index) => ({
          ...reward,
          sNo:
            pageNum === 1
              ? index + 1
              : (pageNum - 1) *
                  (newRewards?.length < rowsPerPage ? rowsPerPage : newRewards?.length) +
                index +
                1,
        }));
        setPageData((prevData) => new Map(prevData.set(pageNum, updatedRewards)));
        setRowData(updatedRewards);
        setError("");
      } else {
        console.error("ContentAPIFailed", errors);
        setError(errors?.[0]?.message ? errors?.[0]?.message : "Failed to fetch Expiring rewards");
      }
    } catch (err: any) {
      console.error("Error fetching rewards:", err);
      setError(err.message || "An unexpected error occurred");
    } finally {
      setLoading(false);
    }
  };

  // Handle page change (Next and Previous)
  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };
  const calculatePageRange = () => {
    if (page === 1) {
      return `${page} - ${rows?.length}`;
    } else {
      return `${(page - 1) * rowsPerPage + 1} - ${(page - 1) * rowsPerPage + rows?.length}`;
    }
  };

  // Effect hook to fetch data if not already in cache
  useEffect(() => {
    // Check if data for the current page is already cached
    if (!pageData.has(page)) {
      expiringPoints(page); // Fetch data only if not cached
    } else {
      // Use cached data
      setRowData(pageData.get(page) || []);
    }
  }, [page, pageData, memberId]);

  return (
    <Box className={`${classes.ExpiringRewardWrapper} ExpiringRewardWrapperSection`}>
      {sortedRows?.length > 0 && (
        <>
          <TableContainer className='expiringTable'>
            <Table>
              <TableHead className='tabelheading'>
                <TableRow className='tabelheadingRow'>
                  <TableCell width='30px'>
                    <TableSortLabel
                      active={orderBy === "sNo"}
                      direction={orderBy === "sNo" ? order : "asc"}
                      onClick={() => handleRequestSort("sNo")}>
                      <Typography variant='h5semibold' className='noMargin title'>
                        Sl. No
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell width='250px'>
                    <TableSortLabel
                      active={orderBy === "point_desc"}
                      direction={orderBy === "point_desc" ? order : "asc"}
                      onClick={() => handleRequestSort("point_desc")}>
                      <Typography variant='h5semibold' className='noMargin title'>
                        Point Description
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell width='100px'>
                    <TableSortLabel
                      active={orderBy === "created_at"}
                      direction={orderBy === "created_at" ? order : "asc"}
                      onClick={() => handleRequestSort("created_at")}>
                      <Typography variant='h5semibold' className='noMargin title'>
                        Created At
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell width='100px'>
                    <TableSortLabel
                      active={orderBy === "expires_at"}
                      direction={orderBy === "expires_at" ? order : "asc"}
                      onClick={() => handleRequestSort("expires_at")}>
                      <Typography variant='h5semibold' className='noMargin title'>
                        Expires At
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell width='80px'>
                    <TableSortLabel
                      active={orderBy === "points"}
                      direction={orderBy === "points" ? order : "asc"}
                      onClick={() => handleRequestSort("points")}>
                      <Typography variant='h5semibold' className='noMargin title'>
                        Points
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedRows.map((row) => {
                  const expiredStatus = row?.expires_at ? isExpired(row?.expires_at) : null;
                  return (
                    <TableRow hover key={row.point_id} className='tabelRow'>
                      <TableCell className='tabelcell'>
                        <Typography
                          variant='p4regular'
                          className='noMargin'
                          sx={{ opacity: expiredStatus ? "0.5" : 1 }}>
                          {row.sNo}
                        </Typography>
                      </TableCell>
                      <TableCell className='tabelcell'>
                        <Typography
                          variant='p4semibold'
                          className='noMargin ellipsis-two-lines'
                          sx={{ opacity: expiredStatus ? "0.5" : 1 }}>
                          {row.point_desc}
                        </Typography>
                      </TableCell>
                      <TableCell className='tabelcell'>
                        <Typography
                          variant='p4regular'
                          className='noMargin'
                          sx={{ opacity: expiredStatus ? "0.5" : 1 }}>
                          {PlatFormXDateTimeFormat(row?.created_at, false)}
                        </Typography>
                      </TableCell>
                      <TableCell className='tabelcell'>
                        <Typography variant='p4regular' className='noMargin'>
                          <Typography
                            component='span'
                            variant='p4regular'
                            sx={{ opacity: expiredStatus ? "0.5" : 1 }}>
                            {PlatFormXDateTimeFormat(row?.expires_at, false)}
                          </Typography>
                          {row?.expires_at &&
                            (isExpired(row?.expires_at) ? (
                              <Typography variant='p4regular' className='noMargin' color='error'>
                                Expired
                              </Typography>
                            ) : (
                              <Typography variant='p4regular' className='noMargin expiringsoon'>
                                Expiring soon
                              </Typography>
                            ))}
                        </Typography>
                      </TableCell>
                      <TableCell className='tabelcell'>
                        <Typography
                          variant='p4semibold'
                          className='noMargin'
                          sx={{ opacity: expiredStatus ? "0.5" : 1 }}>
                          {row.points}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {/* Pagination Controls */}
          <Box className='paginationwrapper'>
            <Typography variant='h5regular' component='span' className='pagerText'>
              {calculatePageRange()} of {total}
            </Typography>
            <Box sx={{ position: "relative" }} className='previous'>
              <IconButton
                disabled={page === 1}
                onClick={() => handleChangePage(page - 1)}
                sx={{ width: "30px", height: "30px" }}>
                <ArrowBackIosIcon className='icons' />
              </IconButton>
            </Box>
            <Box sx={{ position: "relative" }} className='next'>
              <IconButton
                onClick={() => handleChangePage(page + 1)}
                disabled={
                  total / rowsPerPage === page || total <= rowsPerPage || rows.length < rowsPerPage
                }
                sx={{ width: "30px", height: "30px" }}>
                <ArrowForwardIosIcon className='icons' />
              </IconButton>
            </Box>
          </Box>
        </>
      )}
      {loading && (
        <Box className='circularLoader'>
          <Box className='circularLoaderWrapper'>
            <Box className='innerLoader'>
              <img src={XAnimatedLoader} alt='Loading...' style={{ width: "40px" }} />
            </Box>
          </Box>
        </Box>
      )}
      {!loading && !error && sortedRows?.length === 0 && <NoDataFound message={"No Data Found"} />}
      {error && !loading && <FetchError message={error} />}
    </Box>
  );
};

export default ExpiringReward;
