import { getDynamicContentListApiCall } from "@platformx/utilities";
import { useEffect, useRef, useState } from "react";
import CustomDialog from "../../../CustomDialog/CustomDialog";
import { getUserDetailsService } from "../../helperProfile";
import Wheel from "../WheelSpin/Wheel";

const WheelPopUp = ({ secondaryArgs }) => {
  const [dialogOpen, setDialogOpen] = useState(true);
  const [selectedWheel, setSelectedWheel] = useState<any>(null);
  const memberId = useRef("");
  const userId = localStorage.getItem("userId");

  const getContentList = async () => {
    try {
      const contentListFromAPI = await getDynamicContentListApiCall({
        params: {
          filter: "ALL",
          tags: ["Wheel"],
          cdpFilterParent: ["Home"],
          searchTerm: "",
        },
        secondaryArgs: secondaryArgs,
        start: 0,
        numberOfRows: 1,
        publishRpiKey: null,
      });
      if (contentListFromAPI?.length > 0) {
        const wheelWithHomeTag = contentListFromAPI.find((item) => item.tags.includes("Home"));
        setSelectedWheel(wheelWithHomeTag);
      } else {
        setSelectedWheel(null);
      }
    } catch (err: any) {
      setSelectedWheel(null);
    }
  };

  const getUserDetailsServiceApi = async () => {
    if (userId) {
      const res = await getUserDetailsService({
        userId: userId,
        secondaryArgs: secondaryArgs,
      });
      const { status = 0, data: { data: { publish_viewProfile = {} } = {} } = {} } = res;
      if (status === 200 && Object.keys(publish_viewProfile || {}).length > 0) {
        memberId.current = publish_viewProfile.member_id;
        await getContentList();
      }
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedWheel(null);
  };

  useEffect(() => {
    setTimeout(function () {
      getUserDetailsServiceApi();
    }, 2000);
  }, []);

  return (
    <>
      {selectedWheel ? (
        <CustomDialog
          open={dialogOpen}
          onClose={handleDialogClose}
          maxWidth='600px'
          minHeight='488px'
          margin='12px'>
          <Wheel
            content={selectedWheel}
            member_id={memberId.current}
            secondaryArgs={secondaryArgs}
          />
        </CustomDialog>
      ) : null}
    </>
  );
};

export default WheelPopUp;
