import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    customDialogPopup: {
      "&.customDialog": {
        "& .customDialogWrapper": {
          position: "relative",
          padding: theme.spacing(1.5),
          overflow: "hidden",
          background: theme.palette.prelemType1.BACKGROUND,
          "& .closeIcon": {
            position: "absolute",
            top: "16px",
            right: "16px",
            zIndex: 1,
            cursor: "pointer",
            color: theme.palette.prelemType1.TITLE,
          },
        },
      },
    },
  };
});
