import { gql } from "@apollo/client";

export const FETCH_CONTENT_DATA = gql`
  query getContentData($input: publish_JSON) {
    publish_getContentData(input: $input)
  }
`;
export const GET_AVAILABLE_REWARDS_QUERY = gql`
  query getMemberRewardList(
    $memberId: String!
    $pagination: users_Paginate!
    $sort: users_sortOption!
    $filter: users_filter!
  ) {
    users_getMemberRewardList(
      memberId: $memberId
      pagination: $pagination
      sort: $sort
      filter: $filter
    )
  }
`;
export const GET_MY_REWARD_AND_USED_REWARDS_QUERY = gql`
  query getMemberIssuedRewardList(
    $memberId: String!
    $pagination: users_Paginate!
    $sort: users_sortOption!
    $filter: users_filter!
  ) {
    users_getMemberIssuedRewardList(
      memberId: $memberId
      pagination: $pagination
      sort: $sort
      filter: $filter
    ) {
      rewardList {
        reward_name
        reward_id
        issued_reward_id
        reward_type
        status
        user_id
        user_name
        user_email
        issued_coupon {
          value_type
          coupon_code
          active_from
          value
          active_to
          used_at
        }
      }
      total {
        filtered
      }
    }
  }
`;

export const GET_REWARDS_DETAIL_BY_ID_QUERY = gql`
  query getRewardDetails($rewardId: String!) {
    users_getRewardDetails(rewardId: $rewardId) {
      reward_name
      reward_id
      reward_type
      rewards_desc
      is_active
      cost_in_points
      price
      usage_limit
      images {
        image
        type
      }
      activity {
        all_time
        from
        to
      }
      visibility {
        all_time
        from
        to
      }
      days_inactive
      date_valid
      categories {
        name
        id
      }
      coupon_value
      coupon_value_type
      conditions_description
      usage_instruction
      brand_description
      brand_name
    }
  }
`;
export const REDEEM_COUPON = gql`
  mutation assignRewards(
    $rewardId: String!
    $memberId: String!
    $isPoints: Boolean!
    $quantity: Int!
    $dateValid: String!
    $type: String!
  ) {
    users_assignRewards(
      memberId: $memberId
      rewardId: $rewardId
      isPoints: $isPoints
      quantity: $quantity
      dateValid: $dateValid
      type: $type
    ) {
      message
    }
  }
`;
export const EXPIRING_POINTS = gql`
  query users_pointsToExpire(
    $memberId: String!
    $pagination: users_Paginate!
    $sort: users_sortOption!
    $filter: users_filter!
  ) {
    users_pointsToExpire(
      memberId: $memberId
      pagination: $pagination
      sort: $sort
      filter: $filter
    ) {
      pointsList {
        point_id
        point_desc
        created_at
        expires_at
        points
        type
      }
      total {
        filtered
      }
    }
  }
`;
export const CUSTOM_EVENT_SCHEMA_LIST = gql`
  query users_getCustomEventSchemaList($pagination: users_Paginate!, $sort: users_sortOption!) {
    users_getCustomEventSchemaList(pagination: $pagination, sort: $sort) {
      eventSchemaList {
        event_type
        event_name
        event_schema {
          fields {
            name
            type
            description
          }
        }
        active
        created_at
      }
      total {
        filtered
      }
    }
  }
`;
export const POST_CUSTOM_EVENT = gql`
  mutation triggerCustomEvents($input: users_customEventReq!) {
    users_triggerCustomEvents(input: $input) {
      customEventId
      message
    }
  }
`;

export const ADD_OR_REMOVE_COUPONS = gql`
  mutation publish_addOrRemoveCoupon(
    $cartId: String!
    $memberId: String!
    $couponCode: String!
    $issuedRewardId: String!
    $status: String!
    $assignCouponId: String
  ) {
    publish_addOrRemoveCoupon(
      cartId: $cartId
      memberId: $memberId
      couponCode: $couponCode
      issuedRewardId: $issuedRewardId
      status: $status
      assignCouponId: $assignCouponId
    )
  }
`;

export const FETCH_CONTENT_SCHEMA_DETAIL = gql`
  query FETCH_CONTENT_SCHEMA_DETAIL($contentType: String!, $pagePath: String!) {
    publish_fetchSchemaContent(contentType: $contentType, pagePath: $pagePath)
  }
`;
