import { Box, Card, CardContent, Container, Grid, Typography } from "@mui/material";
import { formCroppedUrlString } from "@platformx/utilities";
import { useInView } from "react-intersection-observer";
import Slider from "react-slick";
import "../../Style.css";
import BasicButton from "../../components/BasicButton/BasicButton";
import ContentReplace from "../../components/ContentReplace/ContentReplace";
import { usePrelemImpression } from "../../components/ImpressionHooks/PrelemImpressionHook";
import { SkeletonLoader } from "../../components/SkeletonLoader/SkeletonLoader";
import prelemTypes from "../../globalStyle";
import useContentData from "../../utils/DynamicSchema/useContentData";
import "./ServiceShowcase2.css";
import { useCustomStyle } from "./ServiceShowcase2.style";

const ServiceShowcase2 = ({ content, analytics, authoringHelper, secondaryArgs }) => {
  const classes = useCustomStyle();
  const globalClasses = prelemTypes();
  const ButtonObj1 = {
    Button_Name: "Button1_Name",
    Button_RedirectURL: "Button1_RedirectURL",
    Button_Type: "Button1_Type",
    Button_Value: "Button1_Value",
    Button_Action: "Button1_Action",
    Button_Content: "Button1_Content",
  };
  const ButtonDataObj1 = {
    Button_Name: content?.Button1_Name,
    Button_RedirectURL: content?.Button1_RedirectURL,
    Button_Type: content?.Button1_Type,
    Button_Value: content?.Button1_Value,
    Button_Action: content?.Button1_Action,
    Button_Content: content?.Button1_Content,
  };
  const { bucketName, gcpUrl } = secondaryArgs;
  const { ref, inView } = useInView({
    threshold: 0,
  });

  const windowSettings = {
    slidesToShow: 3,
    slidesToScroll: 3,
    infinite: false,
    dots: false,
    arrow: true,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const tabletSettings = {
    slidesToShow: 2,
    slidesToScroll: 2,
    infinite: false,
    dots: true,
    arrows: false,
  };

  const mobileSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    dots: true,
    arrows: false,
  };

  const sliderSettings =
    secondaryArgs?.prelemBaseEndpoint?.device === "tablet"
      ? { ...tabletSettings }
      : secondaryArgs?.prelemBaseEndpoint?.device === "mobile"
        ? { ...mobileSettings }
        : { ...windowSettings };

  usePrelemImpression(analytics, inView, secondaryArgs);
  const { contentValue, loader } = useContentData(content, secondaryArgs);

  return (
    <div
      ref={authoringHelper?.innerRef}
      className={`${classes.serviceshowCase2Wrapper} ${globalClasses.prelemType1} prelem prelemType1 service-showcase2 serviceshowCase2Bg`}>
      {/* <style>{minCss}</style> */}
      <Container
        className={
          authoringHelper?.isEditPage ? "grid_full_width prelem-py" : "grid_container prelem-py"
        }
        ref={ref}>
        <Grid container className='gridContainer'>
          <Grid item xs={12} sm={12} md={12} em={8} lg={9} className='serviceShowcaseContent'>
            <Typography
              variant='h2medium'
              id='title'
              sx={{
                display: authoringHelper?.isEditing || content?.title ? "block" : "none",
              }}>
              {content.title}
            </Typography>
            <Typography
              variant='p3regular'
              id='description'
              sx={{
                display: authoringHelper?.isEditing || content?.description ? "block" : "none",
              }}>
              {content.description}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} em={4} lg={3} className='alignButton'>
            {ButtonDataObj1?.Button_Name ? (
              <Box>
                <BasicButton
                  variant='primaryButton1'
                  openButtonEditWindow={authoringHelper?.openButtonEditWindowInAuthoringCB}
                  isAuthoring={analytics?.isAuthoring}
                  currentBtnEditing={authoringHelper?.selectedButtonNameForEditing}
                  analyticsEnabled={analytics?.isAnalyticsEnabled}
                  ButtonObj={ButtonObj1}
                  isEditing={authoringHelper?.isEditing}
                  buttonDataObj={ButtonDataObj1}
                  secondaryArgs={secondaryArgs}
                  analytics={analytics}
                />
              </Box>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={12} md={12} className='editContent'>
            {loader ? (
              <Box
                className='skeletonbox'
                sx={{ width: "100%", height: "100%", minHeight: "500px" }}>
                <SkeletonLoader />
              </Box>
            ) : (
              <>
                {contentValue?.length > 0 ? (
                  <Box
                    sx={{
                      position: "relative",
                      "&:hover": {
                        ".add-content-overlay": {
                          display: authoringHelper?.authoringHoverShow ? "flex !important" : "none",
                        },
                      },
                    }}>
                    <Grid container item className={`sliderWp sliderBlock`}>
                      <Slider {...sliderSettings} className='slider-container1'>
                        {contentValue?.map((value, key) => {
                          return (
                            <Grid
                              item
                              key={key}
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              className='heightPercenntage'>
                              <Card className='cardWrapper'>
                                <CardContent className='cardContent'>
                                  <Box className='boxWrapper'>
                                    <Box className='imageWrapper'>
                                      <img
                                        src={
                                          formCroppedUrlString(
                                            gcpUrl,
                                            bucketName,
                                            value?.serviceicon?.original_image
                                              ?.original_image_relative_path,
                                            value?.serviceicon?.original_image?.ext,
                                          ).src
                                        }
                                        height='46'
                                        width='46'
                                        style={{ objectFit: "contain" }}
                                        alt='serviceimage'
                                      />
                                    </Box>
                                    <Typography
                                      className='text-truncated-2line title'
                                      variant='h4bold'>
                                      {value?.title}
                                    </Typography>
                                  </Box>
                                  <Typography
                                    className='text-truncated-5line description'
                                    variant='p4regular'
                                    sx={{ marginBottom: 0 }}>
                                    {value?.description}
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Grid>
                          );
                        })}
                      </Slider>
                      <ContentReplace
                        onReplaceClick={() => secondaryArgs?.multiSlot?.onToggleContentGallery()}
                      />
                    </Grid>
                  </Box>
                ) : null}
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ServiceShowcase2;
