import { createTheme, Theme } from "@mui/material/styles";
import { CSSProperties } from "react";
import ThemeConstants from "./x-authoringThemeVariable";

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    h1bold: true;
    h1semibold: true;
    h1regular: true;
    h1medium: true;
    h2bold: true;
    h2semibold: true;
    h2regular: true;
    h2medium: true;
    h3bold: true;
    h3semibold: true;
    h3regular: true;
    h3medium: true;
    h4bold: true;
    h4semibold: true;
    h4regular: true;
    h4medium: true;
    h5bold: true;
    h5semibold: true;
    h5regular: true;
    h5medium: true;
    h6bold: true;
    h6semibold: true;
    h6regular: true;
    h6medium: true;
    p1bold: true;
    p1semibold: true;
    p1medium: true;
    p1regular: true;
    p2bold: true;
    p2semibold: true;
    p2medium: true;
    p2regular: true;
    p3bold: true;
    p3semibold: true;
    p3medium: true;
    p3regular: true;
    p4bold: true;
    p4semibold: true;
    p4medium: true;
    p4regular: true;
    labelregular: true;
    labelbold: true;
  }
}
declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    primaryButton: true;
    secondaryButton: true;
    primaryButtonLight: true;
    secondaryButtonLight: true;
    tertiaryButton: true;
    quaternaryButton: true;
  }
}
declare module "@mui/material/Chip" {
  interface ChipPropsVariantOverrides {
    draftBadge: true;
    publishBadge: true;
    pendingBadge: true;
  }
}
declare module "@mui/material/Link" {
  interface LinkPropsVariantOverrides {
    primary: true;
  }
}
declare module "@mui/material/styles" {
  // eslint-disable-next-line no-shadow
  interface Theme {
    prelemMargin: {
      value: string;
    };
    prelemPaddingTop: {
      value: string;
    };
    prelemPaddingBottom: {
      value: string;
    };
    borderRadius: {
      value: string;
      value1: string;
    };
    fontFamily: {
      primary: string;
      secondary: string;
    };
    fontSize?: {
      fontSize_12: string;
      fontSize_14: string;
      fontSize_16: string;
      fontSize_18: string;
      fontSize_20: string;
      fontSize_24: string;
      fontSize_28: string;
    };
    fontWeight: {
      fontWeightLight: string;
      fontWeightRegular: string;
      fontWeightMedium: string;
      fontWeightSemiBold: string;
      fontWeightBold: string;
    };
  }
  interface ThemeOptions {
    prelemMargin?: {
      value: CSSProperties["margin"];
    };
    prelemPaddingBottom?: {
      value: CSSProperties["paddingBottom"];
    };
    prelemPaddingTop?: {
      value: CSSProperties["paddingTop"];
    };
    borderRadius?: {
      value: CSSProperties["borderRadius"];
      value1: CSSProperties["borderRadius"];
    };
    fontFamily?: {
      primary: string;
      secondary: string;
    };
    fontSize?: {
      fontSize_12: string;
      fontSize_14: string;
      fontSize_16: string;
      fontSize_18: string;
      fontSize_20: string;
      fontSize_24: string;
      fontSize_28: string;
    };
    fontWeight: {
      fontWeightLight: string;
      fontWeightRegular: string;
      fontWeightMedium: string;
      fontWeightSemiBold: string;
      fontWeightBold: string;
    };
  }
  interface Palette {
    overlay?: any;
    autoRenewIcon?: any;
    customColor?: any;
    notification?: any;
    textColor?: string;
    activeColor?: string;
    borderFocusedColor?: string;
    textColorPrimary?: string;
    textColorSecondary?: string;
    textColortertiary?: string;
  }
  interface PaletteOptions {
    overlay?: {
      editOverlay: string;
      bgOverlay: string;
      cardOverlay?: string;
    };
    autoRenewIcon?: any;
    customColor?: any;
    notification?: any;
    textColor?: string;
    activeColor?: string;
    borderFocusedColor?: string;
    textColorPrimary?: string;
    textColorSecondary?: string;
    textColortertiary?: string;
  }
}
declare module "@mui/system" {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    em: true;
    lg: true;
    xl: true;
  }
}
const AuthroingTheme = (): Theme =>
  createTheme({
    prelemMargin: {
      value: "0px",
    },
    prelemPaddingBottom: {
      value: "12px",
    },
    prelemPaddingTop: {
      value: "12px",
    },
    borderRadius: {
      value: ThemeConstants.RADIUS.VALUE1,
      value1: ThemeConstants.RADIUS.VALUE2,
    },
    fontFamily: {
      primary: ThemeConstants.FONTNAMEPRIMARY,
      secondary: ThemeConstants.FONTNAMESECONDARY,
    },
    fontSize: {
      //Use only for Svg Icons size
      fontSize_12: "12px",
      fontSize_14: "14px",
      fontSize_16: "16px",
      fontSize_18: "18px",
      fontSize_20: "20px",
      fontSize_24: "24px",
      fontSize_28: "28px",
    },
    fontWeight: {
      fontWeightLight: "300",
      fontWeightRegular: "400",
      fontWeightMedium: "500",
      fontWeightSemiBold: "600",
      fontWeightBold: "700",
    },
    breakpoints: {
      values: {
        xs: ThemeConstants.XS,
        sm: ThemeConstants.SM,
        md: ThemeConstants.MD,
        em: ThemeConstants.EM,
        lg: ThemeConstants.LG,
        xl: ThemeConstants.XL,
      },
    },
    palette: {
      // primary: {
      //   main: ThemeConstants.PRIMARY_COLOR[950],
      //   contrastText: ThemeConstants.ACCENTS.COLOR.WHITE,
      // },
      // secondary: {
      //   main: ThemeConstants.WARNING_COLOR[900],
      //   contrastText: ThemeConstants.ACCENTS.COLOR.WHITE,
      // },
      overlay: {
        editOverlay: ThemeConstants.OVERYLAY.EDITOVERLAY,
        bgOverlay: ThemeConstants.OVERYLAY.BGOVERLAY,
        cardOverlay: ThemeConstants.OVERYLAY.CARDOVERLAY,
      },
      customColor: ThemeConstants.COLOR,
      textColor: ThemeConstants.COLOR.BGLIGHT,
      activeColor: ThemeConstants.COLOR.TERTIARY, //activecolor
      notification: ThemeConstants.NOTIFICATION,
      borderFocusedColor: ThemeConstants.INPUT.BORDER_ACTIVE, //element border on focus
      textColorPrimary: ThemeConstants.COLOR.PRIMARY,
      textColorSecondary: ThemeConstants.COLOR.SECONDARY,
      textColortertiary: ThemeConstants.COLOR.TERTIARY,
    },
    typography: {
      allVariants: {
        fontFamily: ThemeConstants.FONTNAMEPRIMARY,
      },
    },
    components: {
      MuiButton: {
        variants: [
          {
            props: { variant: "primaryButton" },
            style: {
              background: ThemeConstants.BUTTON.PRIMARY.BACKGROUND_COLOR,
              borderRadius: ThemeConstants.ELEMENT.BUTTON.BORDER_RADIUS,
              color: ThemeConstants.BUTTON.PRIMARY.COLOR,
              textTransform: "capitalize",
              fontSize: ThemeConstants.FONTSIZE_16,
              fontWeight: ThemeConstants.FONTWEIGHT_SEMIBOLD,
              height: ThemeConstants.ELEMENT.BUTTON.MEDIUM_HEIGHT,
              padding: ThemeConstants.ELEMENT.BUTTON.PADDING.md,
              margin: `${ThemeConstants.ELEMENT.BUTTON.MARGIN.MDMARGIN}`,
              minWidth: `${ThemeConstants.ELEMENT.BUTTON.MIN_WIDTH}`,
              "&:hover": {
                background: ThemeConstants.BUTTON.PRIMARY.BACKGROUND_COLOR_HOVER,
              },
              "&:disabled": {
                color: ThemeConstants.BUTTON.PRIMARY.COLOR,
                background: ThemeConstants.BUTTON.PRIMARY.BACKGROUND_COLOR,
                opacity: 0.6,
              },
              [`@media (max-width:${ThemeConstants.MD}px)`]: {
                height: ThemeConstants.ELEMENT.BUTTON.MEDIUM_HEIGHT,
                padding: ThemeConstants.ELEMENT.BUTTON.PADDING.sm,
                fontSize: ThemeConstants.FONTSIZE_16,
                margin: `${ThemeConstants.ELEMENT.BUTTON.MARGIN.SMMARGIN}`,
              },
              "&.sm, &.md, &.lg, &.xl ": {
                [`@media (max-width:${ThemeConstants.MD}px)`]: {
                  height: ThemeConstants.ELEMENT.BUTTON.SMALL_HEIGHT,
                  padding: ThemeConstants.ELEMENT.BUTTON.PADDING.sm,
                  fontSize: ThemeConstants.FONTSIZE_16,
                  "&.sm, &.md, &.lg, &.xl ": {
                    height: ThemeConstants.ELEMENT.BUTTON.MEDIUM_HEIGHT,
                    padding: ThemeConstants.ELEMENT.BUTTON.PADDING.md,
                    fontSize: ThemeConstants.FONTSIZE_16,
                  },
                },
              },
              "&.sm": {
                padding: ThemeConstants.ELEMENT.BUTTON.PADDING.sm,
                fontSize: ThemeConstants.FONTSIZE_14,
                height: ThemeConstants.ELEMENT.BUTTON.SMALL_HEIGHT,
              },
              "&.md": {
                padding: ThemeConstants.ELEMENT.BUTTON.PADDING.md,
                fontSize: ThemeConstants.FONTSIZE_16,
                height: ThemeConstants.ELEMENT.BUTTON.MEDIUM_HEIGHT,
              },
              "&.lg": {
                padding: ThemeConstants.ELEMENT.BUTTON.PADDING.lg,
                fontSize: ThemeConstants.FONTSIZE_16,
                height: ThemeConstants.ELEMENT.BUTTON.LARGE_HEIGHT,
              },
              "&.xl": {
                padding: ThemeConstants.ELEMENT.BUTTON.PADDING.xl,
                fontSize: ThemeConstants.FONTSIZE_18,
                height: ThemeConstants.ELEMENT.BUTTON.XLARGE_HEIGHT,
              },
            },
          },
          {
            props: { variant: "secondaryButton" },
            style: {
              background: ThemeConstants.BUTTON.SECONDARY.BACKGROUND_COLOR,
              border: ThemeConstants.BUTTON.SECONDARY.BORDER,
              borderRadius: ThemeConstants.ELEMENT.BUTTON.BORDER_RADIUS,
              color: ThemeConstants.BUTTON.SECONDARY.COLOR,
              textTransform: "capitalize",
              fontSize: ThemeConstants.FONTSIZE_16,
              fontWeight: ThemeConstants.FONTWEIGHT_SEMIBOLD,
              height: ThemeConstants.ELEMENT.BUTTON.MEDIUM_HEIGHT,
              padding: ThemeConstants.ELEMENT.BUTTON.PADDING.md,
              margin: `${ThemeConstants.ELEMENT.BUTTON.MARGIN.MDMARGIN}`,
              minWidth: `${ThemeConstants.ELEMENT.BUTTON.MIN_WIDTH}`,
              "&:hover": {
                color: ThemeConstants.BUTTON.SECONDARY.COLOR_HOVER,
                background: ThemeConstants.BUTTON.SECONDARY.BACKGROUND_COLOR_HOVER,
              },
              "&:disabled": {
                color: ThemeConstants.BUTTON.SECONDARY.COLOR,
                background: ThemeConstants.BUTTON.SECONDARY.BACKGROUND_COLOR,
                opacity: 0.6,
              },
              [`@media (max-width:${ThemeConstants.MD}px)`]: {
                height: ThemeConstants.ELEMENT.BUTTON.MEDIUM_HEIGHT,
                padding: ThemeConstants.ELEMENT.BUTTON.PADDING.sm,
                fontSize: ThemeConstants.FONTSIZE_16,
                margin: `${ThemeConstants.ELEMENT.BUTTON.MARGIN.SMMARGIN}`,
              },
              "&.sm, &.md, &.lg, &.xl ": {
                [`@media (max-width:${ThemeConstants.MD}px)`]: {
                  height: ThemeConstants.ELEMENT.BUTTON.SMALL_HEIGHT,
                  padding: ThemeConstants.ELEMENT.BUTTON.PADDING.sm,
                  fontSize: ThemeConstants.FONTSIZE_16,
                  "&.sm, &.md, &.lg, &.xl ": {
                    height: ThemeConstants.ELEMENT.BUTTON.MEDIUM_HEIGHT,
                    padding: ThemeConstants.ELEMENT.BUTTON.PADDING.md,
                    fontSize: ThemeConstants.FONTSIZE_16,
                  },
                },
              },
              "&.sm": {
                padding: ThemeConstants.ELEMENT.BUTTON.PADDING.sm,
                fontSize: ThemeConstants.FONTSIZE_14,
                height: ThemeConstants.ELEMENT.BUTTON.SMALL_HEIGHT,
              },
              "&.md": {
                padding: ThemeConstants.ELEMENT.BUTTON.PADDING.md,
                fontSize: ThemeConstants.FONTSIZE_16,
                height: ThemeConstants.ELEMENT.BUTTON.MEDIUM_HEIGHT,
              },
              "&.lg": {
                padding: ThemeConstants.ELEMENT.BUTTON.PADDING.lg,
                fontSize: ThemeConstants.FONTSIZE_16,
                height: ThemeConstants.ELEMENT.BUTTON.LARGE_HEIGHT,
              },
              "&.xl": {
                padding: ThemeConstants.ELEMENT.BUTTON.PADDING.xl,
                fontSize: ThemeConstants.FONTSIZE_18,
                height: ThemeConstants.ELEMENT.BUTTON.XLARGE_HEIGHT,
              },
            },
          },
          {
            props: { variant: "tertiaryButton" },
            style: {
              background: ThemeConstants.BUTTON.TERTIARY.BACKGROUND_COLOR,
              border: ThemeConstants.BUTTON.TERTIARY.BORDER,
              borderRadius: ThemeConstants.ELEMENT.BUTTON.BORDER_RADIUS,
              color: ThemeConstants.BUTTON.TERTIARY.COLOR,
              textTransform: "capitalize",
              fontSize: ThemeConstants.FONTSIZE_16,
              fontWeight: ThemeConstants.FONTWEIGHT_SEMIBOLD,
              height: ThemeConstants.ELEMENT.BUTTON.MEDIUM_HEIGHT,
              // padding: ThemeConstants.ELEMENT.BUTTON.PADDING.md,
              padding: 0,
              margin: `${ThemeConstants.ELEMENT.BUTTON.MARGIN.MDMARGIN}`,
              minWidth: `${ThemeConstants.ELEMENT.BUTTON.MEDIUM_HEIGHT}`,
              width: `${ThemeConstants.ELEMENT.BUTTON.MEDIUM_HEIGHT}`,
              "& svg": {
                fill: ThemeConstants.BUTTON.TERTIARY.COLOR,
                fontSize: ThemeConstants.FONTSIZE_28,
              },
              "&:hover": {
                color: ThemeConstants.BUTTON.TERTIARY.COLOR_HOVER,
                background: ThemeConstants.BUTTON.TERTIARY.BACKGROUND_COLOR_HOVER,
                "& svg": {
                  fill: ThemeConstants.BUTTON.TERTIARY.COLOR_HOVER,
                },
              },
              "&:disabled": {
                color: ThemeConstants.BUTTON.TERTIARY.COLOR,
                background: ThemeConstants.BUTTON.TERTIARY.BACKGROUND_COLOR,
                opacity: 0.6,
              },
              [`@media (max-width:${ThemeConstants.MD}px)`]: {
                height: ThemeConstants.ELEMENT.BUTTON.MEDIUM_HEIGHT,
                padding: ThemeConstants.ELEMENT.BUTTON.PADDING.sm,
                fontSize: ThemeConstants.FONTSIZE_16,
                margin: `${ThemeConstants.ELEMENT.BUTTON.MARGIN.SMMARGIN}`,
                "& svg": {
                  fontSize: ThemeConstants.FONTSIZE_24,
                },
              },
              "&.sm, &.md, &.lg, &.xl ": {
                [`@media (max-width:${ThemeConstants.MD}px)`]: {
                  height: ThemeConstants.ELEMENT.BUTTON.SMALL_HEIGHT,
                  padding: ThemeConstants.ELEMENT.BUTTON.PADDING.sm,
                  fontSize: ThemeConstants.FONTSIZE_16,
                  "&.sm, &.md, &.lg, &.xl ": {
                    height: ThemeConstants.ELEMENT.BUTTON.MEDIUM_HEIGHT,
                    padding: ThemeConstants.ELEMENT.BUTTON.PADDING.md,
                    fontSize: ThemeConstants.FONTSIZE_16,
                  },
                },
              },
              "&.sm": {
                padding: ThemeConstants.ELEMENT.BUTTON.PADDING.sm,
                fontSize: ThemeConstants.FONTSIZE_14,
                height: ThemeConstants.ELEMENT.BUTTON.SMALL_HEIGHT,
                minWidth: ThemeConstants.ELEMENT.BUTTON.SMALL_HEIGHT,
                width: ThemeConstants.ELEMENT.BUTTON.SMALL_HEIGHT,
              },
              "&.md": {
                padding: ThemeConstants.ELEMENT.BUTTON.PADDING.md,
                fontSize: ThemeConstants.FONTSIZE_16,
                height: ThemeConstants.ELEMENT.BUTTON.MEDIUM_HEIGHT,
                minWidth: ThemeConstants.ELEMENT.BUTTON.MEDIUM_HEIGHT,
                width: ThemeConstants.ELEMENT.BUTTON.MEDIUM_HEIGHT,
              },
              "&.lg": {
                padding: ThemeConstants.ELEMENT.BUTTON.PADDING.lg,
                fontSize: ThemeConstants.FONTSIZE_16,
                height: ThemeConstants.ELEMENT.BUTTON.LARGE_HEIGHT,
                minWidth: ThemeConstants.ELEMENT.BUTTON.LARGE_HEIGHT,
                width: ThemeConstants.ELEMENT.BUTTON.LARGE_HEIGHT,
              },
              "&.xl": {
                padding: ThemeConstants.ELEMENT.BUTTON.PADDING.xl,
                fontSize: ThemeConstants.FONTSIZE_18,
                height: ThemeConstants.ELEMENT.BUTTON.XLARGE_HEIGHT,
                minWidth: ThemeConstants.ELEMENT.BUTTON.XLARGE_HEIGHT,
                width: ThemeConstants.ELEMENT.BUTTON.XLARGE_HEIGHT,
              },
            },
          },
          {
            props: { variant: "primaryButtonLight" },
            style: {
              background: ThemeConstants.BUTTON.PRIMARYLIGHT.BACKGROUND_COLOR,
              borderRadius: ThemeConstants.ELEMENT.BUTTON.BORDER_RADIUS,
              color: ThemeConstants.BUTTON.PRIMARYLIGHT.COLOR,
              textTransform: "capitalize",
              fontSize: ThemeConstants.FONTSIZE_16,
              fontWeight: ThemeConstants.FONTWEIGHT_SEMIBOLD,
              height: ThemeConstants.ELEMENT.BUTTON.MEDIUM_HEIGHT,
              padding: ThemeConstants.ELEMENT.BUTTON.PADDING.md,
              margin: `${ThemeConstants.ELEMENT.BUTTON.MARGIN.MDMARGIN}`,
              minWidth: `${ThemeConstants.ELEMENT.BUTTON.MIN_WIDTH}`,
              "&:hover": {
                background: ThemeConstants.BUTTON.PRIMARYLIGHT.BACKGROUND_COLOR_HOVER,
                color: ThemeConstants.BUTTON.PRIMARYLIGHT.COLOR_HOVER,
              },
              "&:disabled": {
                color: ThemeConstants.BUTTON.PRIMARYLIGHT.COLOR,
                background: ThemeConstants.BUTTON.PRIMARYLIGHT.BACKGROUND_COLOR,
                opacity: 0.6,
              },
              [`@media (max-width:${ThemeConstants.MD}px)`]: {
                height: ThemeConstants.ELEMENT.BUTTON.MEDIUM_HEIGHT,
                padding: ThemeConstants.ELEMENT.BUTTON.PADDING.sm,
                fontSize: ThemeConstants.FONTSIZE_16,
                margin: `${ThemeConstants.ELEMENT.BUTTON.MARGIN.SMMARGIN}`,
              },
              "&.sm, &.md, &.lg, &.xl ": {
                [`@media (max-width:${ThemeConstants.MD}px)`]: {
                  height: ThemeConstants.ELEMENT.BUTTON.SMALL_HEIGHT,
                  padding: ThemeConstants.ELEMENT.BUTTON.PADDING.sm,
                  fontSize: ThemeConstants.FONTSIZE_16,
                  "&.sm, &.md, &.lg, &.xl ": {
                    height: ThemeConstants.ELEMENT.BUTTON.MEDIUM_HEIGHT,
                    padding: ThemeConstants.ELEMENT.BUTTON.PADDING.md,
                    fontSize: ThemeConstants.FONTSIZE_16,
                  },
                },
              },
              "&.sm": {
                padding: ThemeConstants.ELEMENT.BUTTON.PADDING.sm,
                fontSize: ThemeConstants.FONTSIZE_14,
                height: ThemeConstants.ELEMENT.BUTTON.SMALL_HEIGHT,
              },
              "&.md": {
                padding: ThemeConstants.ELEMENT.BUTTON.PADDING.md,
                fontSize: ThemeConstants.FONTSIZE_16,
                height: ThemeConstants.ELEMENT.BUTTON.MEDIUM_HEIGHT,
              },
              "&.lg": {
                padding: ThemeConstants.ELEMENT.BUTTON.PADDING.lg,
                fontSize: ThemeConstants.FONTSIZE_16,
                height: ThemeConstants.ELEMENT.BUTTON.LARGE_HEIGHT,
              },
              "&.xl": {
                padding: ThemeConstants.ELEMENT.BUTTON.PADDING.xl,
                fontSize: ThemeConstants.FONTSIZE_18,
                height: ThemeConstants.ELEMENT.BUTTON.XLARGE_HEIGHT,
              },
            },
          },
          {
            props: { variant: "secondaryButtonLight" },
            style: {
              background: ThemeConstants.BUTTON.SECONDARYLIGHT.BACKGROUND_COLOR,
              border: ThemeConstants.BUTTON.SECONDARYLIGHT.BORDER,
              borderRadius: ThemeConstants.ELEMENT.BUTTON.BORDER_RADIUS,
              color: ThemeConstants.BUTTON.SECONDARYLIGHT.COLOR,
              textTransform: "capitalize",
              fontSize: ThemeConstants.FONTSIZE_16,
              fontWeight: ThemeConstants.FONTWEIGHT_SEMIBOLD,
              height: ThemeConstants.ELEMENT.BUTTON.MEDIUM_HEIGHT,
              padding: ThemeConstants.ELEMENT.BUTTON.PADDING.md,
              margin: `${ThemeConstants.ELEMENT.BUTTON.MARGIN.MDMARGIN}`,
              minWidth: `${ThemeConstants.ELEMENT.BUTTON.MIN_WIDTH}`,
              "&:hover": {
                color: ThemeConstants.BUTTON.SECONDARYLIGHT.COLOR_HOVER,
                background: ThemeConstants.BUTTON.SECONDARYLIGHT.BACKGROUND_COLOR_HOVER,
              },
              "&:disabled": {
                color: ThemeConstants.BUTTON.SECONDARYLIGHT.COLOR,
                background: ThemeConstants.BUTTON.SECONDARYLIGHT.BACKGROUND_COLOR,
                opacity: 0.6,
              },
              [`@media (max-width:${ThemeConstants.MD}px)`]: {
                height: ThemeConstants.ELEMENT.BUTTON.MEDIUM_HEIGHT,
                padding: ThemeConstants.ELEMENT.BUTTON.PADDING.sm,
                fontSize: ThemeConstants.FONTSIZE_16,
                margin: `${ThemeConstants.ELEMENT.BUTTON.MARGIN.SMMARGIN}`,
              },
              "&.sm, &.md, &.lg, &.xl ": {
                [`@media (max-width:${ThemeConstants.MD}px)`]: {
                  height: ThemeConstants.ELEMENT.BUTTON.SMALL_HEIGHT,
                  padding: ThemeConstants.ELEMENT.BUTTON.PADDING.sm,
                  fontSize: ThemeConstants.FONTSIZE_16,
                  "&.sm, &.md, &.lg, &.xl ": {
                    height: ThemeConstants.ELEMENT.BUTTON.MEDIUM_HEIGHT,
                    padding: ThemeConstants.ELEMENT.BUTTON.PADDING.md,
                    fontSize: ThemeConstants.FONTSIZE_16,
                  },
                },
              },
              "&.sm": {
                padding: ThemeConstants.ELEMENT.BUTTON.PADDING.sm,
                fontSize: ThemeConstants.FONTSIZE_14,
                height: ThemeConstants.ELEMENT.BUTTON.SMALL_HEIGHT,
              },
              "&.md": {
                padding: ThemeConstants.ELEMENT.BUTTON.PADDING.md,
                fontSize: ThemeConstants.FONTSIZE_16,
                height: ThemeConstants.ELEMENT.BUTTON.MEDIUM_HEIGHT,
              },
              "&.lg": {
                padding: ThemeConstants.ELEMENT.BUTTON.PADDING.lg,
                fontSize: ThemeConstants.FONTSIZE_16,
                height: ThemeConstants.ELEMENT.BUTTON.LARGE_HEIGHT,
              },
              "&.xl": {
                padding: ThemeConstants.ELEMENT.BUTTON.PADDING.xl,
                fontSize: ThemeConstants.FONTSIZE_18,
                height: ThemeConstants.ELEMENT.BUTTON.XLARGE_HEIGHT,
              },
            },
          },
          {
            props: { variant: "quaternaryButton" },
            style: {
              background: ThemeConstants.BUTTON.QUATERNARY.BACKGROUND_COLOR,
              borderRadius: ThemeConstants.ELEMENT.BUTTON.BORDER_RADIUS,
              color: ThemeConstants.BUTTON.QUATERNARY.COLOR,
              textTransform: "capitalize",
              fontSize: ThemeConstants.FONTSIZE_14,
              fontWeight: ThemeConstants.FONTWEIGHT_REGULAR,
              height: ThemeConstants.ELEMENT.BUTTON.SMALL_HEIGHT,
              padding: ThemeConstants.ELEMENT.BUTTON.PADDING.md,
              margin: `${ThemeConstants.ELEMENT.BUTTON.MARGIN.MDMARGIN}`,
              minWidth: `${ThemeConstants.ELEMENT.BUTTON.MIN_WIDTH}`,
              "&:hover": {
                background: ThemeConstants.BUTTON.QUATERNARY.BACKGROUND_COLOR_HOVER,
              },
              "&:disabled": {
                color: ThemeConstants.BUTTON.QUATERNARY.COLOR,
                background: ThemeConstants.BUTTON.QUATERNARY.BACKGROUND_COLOR,
                opacity: 0.6,
              },
              [`@media (max-width:${ThemeConstants.MD}px)`]: {
                height: ThemeConstants.ELEMENT.BUTTON.MEDIUM_HEIGHT,
                padding: ThemeConstants.ELEMENT.BUTTON.PADDING.sm,
                fontSize: ThemeConstants.FONTSIZE_16,
                margin: `${ThemeConstants.ELEMENT.BUTTON.MARGIN.SMMARGIN}`,
              },
              "&.sm, &.md, &.lg, &.xl ": {
                [`@media (max-width:${ThemeConstants.MD}px)`]: {
                  height: ThemeConstants.ELEMENT.BUTTON.SMALL_HEIGHT,
                  padding: ThemeConstants.ELEMENT.BUTTON.PADDING.sm,
                  fontSize: ThemeConstants.FONTSIZE_16,
                  "&.sm, &.md, &.lg, &.xl ": {
                    height: ThemeConstants.ELEMENT.BUTTON.MEDIUM_HEIGHT,
                    padding: ThemeConstants.ELEMENT.BUTTON.PADDING.md,
                    fontSize: ThemeConstants.FONTSIZE_16,
                  },
                },
              },
              "&.sm": {
                padding: ThemeConstants.ELEMENT.BUTTON.PADDING.sm,
                fontSize: ThemeConstants.FONTSIZE_14,
                height: ThemeConstants.ELEMENT.BUTTON.SMALL_HEIGHT,
              },
              "&.md": {
                padding: ThemeConstants.ELEMENT.BUTTON.PADDING.md,
                fontSize: ThemeConstants.FONTSIZE_16,
                height: ThemeConstants.ELEMENT.BUTTON.MEDIUM_HEIGHT,
              },
              "&.lg": {
                padding: ThemeConstants.ELEMENT.BUTTON.PADDING.lg,
                fontSize: ThemeConstants.FONTSIZE_16,
                height: ThemeConstants.ELEMENT.BUTTON.LARGE_HEIGHT,
              },
              "&.xl": {
                padding: ThemeConstants.ELEMENT.BUTTON.PADDING.xl,
                fontSize: ThemeConstants.FONTSIZE_18,
                height: ThemeConstants.ELEMENT.BUTTON.XLARGE_HEIGHT,
              },
            },
          },
        ],
      },
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            h1bold: "h1",
            h1semibold: "h1",
            h1medium: "h1",
            h1regular: "h1",
            h2bold: "h2",
            h2semibold: "h2",
            h2medium: "h1",
            h2regular: "h2",
            h3bold: "h3",
            h3semibold: "h3",
            h3medium: "h3",
            h3regular: "h3",
            h4bold: "h4",
            h4semibold: "h4",
            h4regular: "h4",
            h4medium: "h4",
            h5bold: "h5",
            h5semibold: "h5",
            h5regular: "h5",
            h5medium: "h5",
            h6bold: "h6",
            h6semibold: "h6",
            h6regular: "h6",
            h6medium: "h6",
            p1bold: "p",
            p1semibold: "p",
            p1medium: "p",
            p1regular: "p",
            p2bold: "p",
            p2semibold: "p",
            p2medium: "p",
            p2regular: "p",
            p3bold: "p",
            p3semibold: "p",
            p3medium: "p",
            p3regular: "p",
            p4bold: "p",
            p4semibold: "p",
            p4medium: "p",
            p4regular: "p",
            labelregular: "label",
            labelbold: "label",
          },
        },
        variants: [
          {
            props: { variant: "h1regular" },
            style: {
              fontFamily: ThemeConstants.FONTNAMEPRIMARY,
              color: ThemeConstants.COLOR.PRIMARY,
              fontWeight: ThemeConstants.FONTWEIGHT_REGULAR,
              fontSize: ThemeConstants.FONTSIZE_28,
              lineHeight: ThemeConstants.LINEHEIGHT_36,
              marginTop: ThemeConstants.TYPOGRAPHY.HEADING.H1.MARGIN.LGTOP,
              marginBottom: ThemeConstants.TYPOGRAPHY.HEADING.H1.MARGIN.LGBOTTOM,
              [`@media(max-width:${ThemeConstants.SM}px)`]: {
                fontSize: ThemeConstants.FONTSIZE_24,
                lineHeight: ThemeConstants.LINEHEIGHT_32,
                marginTop: ThemeConstants.TYPOGRAPHY.HEADING.H1.MARGIN.SMTOP,
                marginBottom: ThemeConstants.TYPOGRAPHY.HEADING.H1.MARGIN.SMBOTTOM,
              },
            },
          },
          {
            props: { variant: "h1medium" },
            style: {
              fontFamily: ThemeConstants.FONTNAMEPRIMARY,
              color: ThemeConstants.COLOR.PRIMARY,
              fontWeight: ThemeConstants.FONTWEIGHT_MEDIUM,
              fontSize: ThemeConstants.FONTSIZE_28,
              lineHeight: ThemeConstants.LINEHEIGHT_36,
              marginTop: ThemeConstants.TYPOGRAPHY.HEADING.H1.MARGIN.LGTOP,
              marginBottom: ThemeConstants.TYPOGRAPHY.HEADING.H1.MARGIN.LGBOTTOM,
              [`@media(max-width:${ThemeConstants.SM}px)`]: {
                fontSize: ThemeConstants.FONTSIZE_24,
                lineHeight: ThemeConstants.LINEHEIGHT_32,
                marginTop: ThemeConstants.TYPOGRAPHY.HEADING.H1.MARGIN.SMTOP,
                marginBottom: ThemeConstants.TYPOGRAPHY.HEADING.H1.MARGIN.SMBOTTOM,
              },
            },
          },
          {
            props: { variant: "h1semibold" },
            style: {
              fontFamily: ThemeConstants.FONTNAMEPRIMARY,
              color: ThemeConstants.COLOR.PRIMARY,
              fontWeight: ThemeConstants.FONTWEIGHT_SEMIBOLD,
              fontSize: ThemeConstants.FONTSIZE_28,
              lineHeight: ThemeConstants.LINEHEIGHT_36,
              marginTop: ThemeConstants.TYPOGRAPHY.HEADING.H1.MARGIN.LGTOP,
              marginBottom: ThemeConstants.TYPOGRAPHY.HEADING.H1.MARGIN.LGBOTTOM,
              /*display: "inline-block",
              width: "100%",*/
              [`@media(max-width:${ThemeConstants.SM}px)`]: {
                fontSize: ThemeConstants.FONTSIZE_24,
                lineHeight: ThemeConstants.LINEHEIGHT_32,
                marginTop: ThemeConstants.TYPOGRAPHY.HEADING.H1.MARGIN.SMTOP,
                marginBottom: ThemeConstants.TYPOGRAPHY.HEADING.H1.MARGIN.SMBOTTOM,
              },
            },
          },
          {
            props: { variant: "h1bold" },
            style: {
              fontFamily: ThemeConstants.FONTNAMEPRIMARY,
              color: ThemeConstants.COLOR.PRIMARY,
              fontWeight: ThemeConstants.FONTWEIGHT_BOLD,
              fontSize: ThemeConstants.FONTSIZE_28,
              lineHeight: ThemeConstants.LINEHEIGHT_36,
              marginTop: ThemeConstants.TYPOGRAPHY.HEADING.H1.MARGIN.LGTOP,
              marginBottom: ThemeConstants.TYPOGRAPHY.HEADING.H1.MARGIN.LGBOTTOM,
              [`@media(max-width:${ThemeConstants.SM}px)`]: {
                fontSize: ThemeConstants.FONTSIZE_24,
                lineHeight: ThemeConstants.LINEHEIGHT_32,
                marginTop: ThemeConstants.TYPOGRAPHY.HEADING.H1.MARGIN.SMTOP,
                marginBottom: ThemeConstants.TYPOGRAPHY.HEADING.H1.MARGIN.SMBOTTOM,
              },
            },
          },
          {
            props: { variant: "h2regular" },
            style: {
              fontFamily: ThemeConstants.FONTNAMEPRIMARY,
              color: ThemeConstants.COLOR.PRIMARY,
              fontWeight: ThemeConstants.FONTWEIGHT_REGULAR,
              fontSize: ThemeConstants.FONTSIZE_24,
              lineHeight: ThemeConstants.LINEHEIGHT_32,
              marginTop: ThemeConstants.TYPOGRAPHY.HEADING.H2.MARGIN.LGTOP,
              marginBottom: ThemeConstants.TYPOGRAPHY.HEADING.H2.MARGIN.LGBOTTOM,
              /*display: "inline-block",
              width: "100%",*/
              [`@media(max-width:${ThemeConstants.SM}px)`]: {
                fontSize: ThemeConstants.FONTSIZE_20,
                lineHeight: ThemeConstants.LINEHEIGHT_28,
                marginTop: ThemeConstants.TYPOGRAPHY.HEADING.H2.MARGIN.SMTOP,
                marginBottom: ThemeConstants.TYPOGRAPHY.HEADING.H2.MARGIN.SMBOTTOM,
              },
            },
          },
          {
            props: { variant: "h2medium" },
            style: {
              fontFamily: ThemeConstants.FONTNAMEPRIMARY,
              color: ThemeConstants.COLOR.PRIMARY,
              fontWeight: ThemeConstants.FONTWEIGHT_MEDIUM,
              fontSize: ThemeConstants.FONTSIZE_24,
              lineHeight: ThemeConstants.LINEHEIGHT_32,
              marginTop: ThemeConstants.TYPOGRAPHY.HEADING.H2.MARGIN.LGTOP,
              marginBottom: ThemeConstants.TYPOGRAPHY.HEADING.H2.MARGIN.LGBOTTOM,
              /*display: "inline-block",
              width: "100%",*/
              [`@media(max-width:${ThemeConstants.SM}px)`]: {
                fontSize: ThemeConstants.FONTSIZE_20,
                lineHeight: ThemeConstants.LINEHEIGHT_28,
                marginTop: ThemeConstants.TYPOGRAPHY.HEADING.H2.MARGIN.SMTOP,
                marginBottom: ThemeConstants.TYPOGRAPHY.HEADING.H2.MARGIN.SMBOTTOM,
              },
            },
          },
          {
            props: { variant: "h2semibold" },
            style: {
              fontFamily: ThemeConstants.FONTNAMEPRIMARY,
              color: ThemeConstants.COLOR.PRIMARY,
              fontWeight: ThemeConstants.FONTWEIGHT_SEMIBOLD,
              fontSize: ThemeConstants.FONTSIZE_24,
              lineHeight: ThemeConstants.LINEHEIGHT_32,
              marginTop: ThemeConstants.TYPOGRAPHY.HEADING.H2.MARGIN.LGTOP,
              marginBottom: ThemeConstants.TYPOGRAPHY.HEADING.H2.MARGIN.LGBOTTOM,
              /*display: "inline-block",
              width: "100%",*/
              [`@media(max-width:${ThemeConstants.SM}px)`]: {
                fontSize: ThemeConstants.FONTSIZE_20,
                lineHeight: ThemeConstants.LINEHEIGHT_28,
                marginTop: ThemeConstants.TYPOGRAPHY.HEADING.H2.MARGIN.SMTOP,
                marginBottom: ThemeConstants.TYPOGRAPHY.HEADING.H2.MARGIN.SMBOTTOM,
              },
            },
          },
          {
            props: { variant: "h2bold" },
            style: {
              fontFamily: ThemeConstants.FONTNAMEPRIMARY,
              color: ThemeConstants.COLOR.PRIMARY,
              fontWeight: ThemeConstants.FONTWEIGHT_BOLD,
              fontSize: ThemeConstants.FONTSIZE_24,
              lineHeight: ThemeConstants.LINEHEIGHT_32,
              marginTop: ThemeConstants.TYPOGRAPHY.HEADING.H2.MARGIN.LGTOP,
              marginBottom: ThemeConstants.TYPOGRAPHY.HEADING.H2.MARGIN.LGBOTTOM,
              /*display: "inline-block",
              width: "100%",*/
              [`@media(max-width:${ThemeConstants.SM}px)`]: {
                fontSize: ThemeConstants.FONTSIZE_20,
                lineHeight: ThemeConstants.LINEHEIGHT_28,
                marginTop: ThemeConstants.TYPOGRAPHY.HEADING.H2.MARGIN.SMTOP,
                marginBottom: ThemeConstants.TYPOGRAPHY.HEADING.H2.MARGIN.SMBOTTOM,
              },
            },
          },
          {
            props: { variant: "h3regular" },
            style: {
              fontFamily: ThemeConstants.FONTNAMEPRIMARY,
              color: ThemeConstants.COLOR.PRIMARY,
              fontWeight: ThemeConstants.FONTWEIGHT_REGULAR,
              fontSize: ThemeConstants.FONTSIZE_20,
              lineHeight: ThemeConstants.LINEHEIGHT_28,
              marginTop: ThemeConstants.TYPOGRAPHY.HEADING.H3.MARGIN.LGTOP,
              marginBottom: ThemeConstants.TYPOGRAPHY.HEADING.H3.MARGIN.LGBOTTOM,
              [`@media(max-width:${ThemeConstants.SM}px)`]: {
                fontSize: ThemeConstants.FONTSIZE_18,
                lineHeight: ThemeConstants.LINEHEIGHT_26,
                marginTop: ThemeConstants.TYPOGRAPHY.HEADING.H3.MARGIN.SMTOP,
                marginBottom: ThemeConstants.TYPOGRAPHY.HEADING.H3.MARGIN.SMBOTTOM,
              },
            },
          },
          {
            props: { variant: "h3medium" },
            style: {
              fontFamily: ThemeConstants.FONTNAMEPRIMARY,
              color: ThemeConstants.COLOR.PRIMARY,
              fontWeight: ThemeConstants.FONTWEIGHT_MEDIUM,
              fontSize: ThemeConstants.FONTSIZE_20,
              lineHeight: ThemeConstants.LINEHEIGHT_28,
              marginTop: ThemeConstants.TYPOGRAPHY.HEADING.H3.MARGIN.LGTOP,
              marginBottom: ThemeConstants.TYPOGRAPHY.HEADING.H3.MARGIN.LGBOTTOM,
              [`@media(max-width:${ThemeConstants.SM}px)`]: {
                fontSize: ThemeConstants.FONTSIZE_18,
                lineHeight: ThemeConstants.LINEHEIGHT_26,
                marginTop: ThemeConstants.TYPOGRAPHY.HEADING.H3.MARGIN.SMTOP,
                marginBottom: ThemeConstants.TYPOGRAPHY.HEADING.H3.MARGIN.SMBOTTOM,
              },
            },
          },
          {
            props: { variant: "h3semibold" },
            style: {
              fontFamily: ThemeConstants.FONTNAMEPRIMARY,
              color: ThemeConstants.COLOR.PRIMARY,
              fontWeight: ThemeConstants.FONTWEIGHT_SEMIBOLD,
              fontSize: ThemeConstants.FONTSIZE_20,
              lineHeight: ThemeConstants.LINEHEIGHT_28,
              marginTop: ThemeConstants.TYPOGRAPHY.HEADING.H3.MARGIN.LGTOP,
              marginBottom: ThemeConstants.TYPOGRAPHY.HEADING.H3.MARGIN.LGBOTTOM,
              [`@media(max-width:${ThemeConstants.SM}px)`]: {
                fontSize: ThemeConstants.FONTSIZE_18,
                lineHeight: ThemeConstants.LINEHEIGHT_26,
                marginTop: ThemeConstants.TYPOGRAPHY.HEADING.H3.MARGIN.SMTOP,
                marginBottom: ThemeConstants.TYPOGRAPHY.HEADING.H3.MARGIN.SMBOTTOM,
              },
            },
          },
          {
            props: { variant: "h3bold" },
            style: {
              fontFamily: ThemeConstants.FONTNAMEPRIMARY,
              color: ThemeConstants.COLOR.PRIMARY,
              fontWeight: ThemeConstants.FONTWEIGHT_BOLD,
              fontSize: ThemeConstants.FONTSIZE_20,
              lineHeight: ThemeConstants.LINEHEIGHT_28,
              marginTop: ThemeConstants.TYPOGRAPHY.HEADING.H3.MARGIN.LGTOP,
              marginBottom: ThemeConstants.TYPOGRAPHY.HEADING.H3.MARGIN.LGBOTTOM,
              [`@media(max-width:${ThemeConstants.SM}px)`]: {
                fontSize: ThemeConstants.FONTSIZE_18,
                lineHeight: ThemeConstants.LINEHEIGHT_26,
                marginTop: ThemeConstants.TYPOGRAPHY.HEADING.H3.MARGIN.SMTOP,
                marginBottom: ThemeConstants.TYPOGRAPHY.HEADING.H3.MARGIN.SMBOTTOM,
              },
            },
          },
          /*heading that will look like paragraph except colors */
          {
            props: { variant: "h4regular" },
            style: {
              fontFamily: ThemeConstants.FONTNAMESECONDARY,
              color: ThemeConstants.COLOR.PRIMARY,
              fontWeight: ThemeConstants.FONTWEIGHT_REGULAR,
              fontSize: ThemeConstants.FONTSIZE_18,
              lineHeight: ThemeConstants.LINEHEIGHT_26,
              marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P1.MARGIN.LGTOP,
              marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P1.MARGIN.LGBOTTOM,
              [`@media(max-width:${ThemeConstants.SM}px)`]: {
                fontSize: ThemeConstants.FONTSIZE_16,
                lineHeight: ThemeConstants.LINEHEIGHT_24,
                marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P1.MARGIN.SMTOP,
                marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P1.MARGIN.SMBOTTOM,
              },
            },
          },
          {
            props: { variant: "h4medium" },
            style: {
              fontFamily: ThemeConstants.FONTNAMEPRIMARY,
              color: ThemeConstants.COLOR.PRIMARY,
              fontWeight: ThemeConstants.FONTWEIGHT_MEDIUM,
              fontSize: ThemeConstants.FONTSIZE_18,
              lineHeight: ThemeConstants.LINEHEIGHT_26,
              marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P1.MARGIN.LGTOP,
              marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P1.MARGIN.LGBOTTOM,
              [`@media(max-width:${ThemeConstants.SM}px)`]: {
                fontSize: ThemeConstants.FONTSIZE_16,
                lineHeight: ThemeConstants.LINEHEIGHT_24,
                marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P1.MARGIN.SMTOP,
                marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P1.MARGIN.SMBOTTOM,
              },
            },
          },
          {
            props: { variant: "h4semibold" },
            style: {
              fontFamily: ThemeConstants.FONTNAMEPRIMARY,
              color: ThemeConstants.COLOR.PRIMARY,
              fontWeight: ThemeConstants.FONTWEIGHT_SEMIBOLD,
              fontSize: ThemeConstants.FONTSIZE_18,
              lineHeight: ThemeConstants.LINEHEIGHT_26,
              marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P1.MARGIN.LGTOP,
              marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P2.MARGIN.LGBOTTOM,
              [`@media(max-width:${ThemeConstants.SM}px)`]: {
                fontSize: ThemeConstants.FONTSIZE_16,
                lineHeight: ThemeConstants.LINEHEIGHT_24,
                marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P2.MARGIN.SMTOP,
                marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P2.MARGIN.SMBOTTOM,
              },
            },
          },
          {
            props: { variant: "h4bold" },
            style: {
              fontFamily: ThemeConstants.FONTNAMEPRIMARY,
              color: ThemeConstants.COLOR.PRIMARY,
              fontWeight: ThemeConstants.FONTWEIGHT_BOLD,
              fontSize: ThemeConstants.FONTSIZE_18,
              lineHeight: ThemeConstants.LINEHEIGHT_26,
              marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P1.MARGIN.LGTOP,
              marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P2.MARGIN.LGBOTTOM,
              [`@media(max-width:${ThemeConstants.SM}px)`]: {
                fontSize: ThemeConstants.FONTSIZE_16,
                lineHeight: ThemeConstants.LINEHEIGHT_24,
                marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P2.MARGIN.SMTOP,
                marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P2.MARGIN.SMBOTTOM,
              },
            },
          },

          {
            props: { variant: "h5regular" },
            style: {
              fontFamily: ThemeConstants.FONTNAMESECONDARY,
              color: ThemeConstants.COLOR.PRIMARY,
              fontWeight: ThemeConstants.FONTWEIGHT_REGULAR,
              fontSize: ThemeConstants.FONTSIZE_16,
              lineHeight: ThemeConstants.LINEHEIGHT_24,
              marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P2.MARGIN.LGTOP,
              marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P2.MARGIN.LGBOTTOM,
              [`@media(max-width:${ThemeConstants.SM}px)`]: {
                fontSize: ThemeConstants.FONTSIZE_14,
                lineHeight: ThemeConstants.LINEHEIGHT_22,
                marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P2.MARGIN.SMTOP,
                marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P2.MARGIN.SMBOTTOM,
              },
            },
          },
          {
            props: { variant: "h5medium" },
            style: {
              fontFamily: ThemeConstants.FONTNAMESECONDARY,
              color: ThemeConstants.COLOR.PRIMARY,
              fontWeight: ThemeConstants.FONTWEIGHT_MEDIUM,
              fontSize: ThemeConstants.FONTSIZE_16,
              lineHeight: ThemeConstants.LINEHEIGHT_24,
              marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P2.MARGIN.LGTOP,
              marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P2.MARGIN.LGBOTTOM,
              // textShadow: "0.5px 0.5px 0.5px rgba(0,0,0,0.3)",
              [`@media(max-width:${ThemeConstants.SM}px)`]: {
                fontSize: ThemeConstants.FONTSIZE_14,
                lineHeight: ThemeConstants.LINEHEIGHT_22,
                marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P2.MARGIN.SMTOP,
                marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P2.MARGIN.SMBOTTOM,
              },
              // "&:hover": {
              //   textShadow: "none",
              // },
            },
          },
          {
            props: { variant: "h5semibold" },
            style: {
              fontFamily: ThemeConstants.FONTNAMESECONDARY,
              color: ThemeConstants.COLOR.PRIMARY,
              fontWeight: ThemeConstants.FONTWEIGHT_SEMIBOLD,
              fontSize: ThemeConstants.FONTSIZE_16,
              lineHeight: ThemeConstants.LINEHEIGHT_24,
              marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P2.MARGIN.LGTOP,
              marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P2.MARGIN.LGBOTTOM,
              [`@media(max-width:${ThemeConstants.SM}px)`]: {
                marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P2.MARGIN.SMTOP,
                marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P2.MARGIN.SMBOTTOM,
              },
            },
          },
          {
            props: { variant: "h5bold" },
            style: {
              fontFamily: ThemeConstants.FONTNAMESECONDARY,
              color: ThemeConstants.COLOR.PRIMARY,
              fontWeight: ThemeConstants.FONTWEIGHT_BOLD,
              fontSize: ThemeConstants.FONTSIZE_16,
              lineHeight: ThemeConstants.LINEHEIGHT_24,
              marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P2.MARGIN.LGTOP,
              marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P2.MARGIN.LGBOTTOM,
              [`@media(max-width:${ThemeConstants.SM}px)`]: {
                fontSize: ThemeConstants.FONTSIZE_14,
                lineHeight: ThemeConstants.LINEHEIGHT_22,
                marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P2.MARGIN.SMTOP,
                marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P2.MARGIN.SMBOTTOM,
              },
            },
          },
          {
            props: { variant: "h6regular" },
            style: {
              fontFamily: ThemeConstants.FONTNAMESECONDARY,
              color: ThemeConstants.COLOR.PRIMARY,
              fontWeight: ThemeConstants.FONTWEIGHT_REGULAR,
              fontSize: ThemeConstants.FONTSIZE_14,
              lineHeight: ThemeConstants.LINEHEIGHT_22,
              marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.LGTOP,
              marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.LGBOTTOM,
              [`@media(max-width:${ThemeConstants.SM}px)`]: {
                fontSize: ThemeConstants.FONTSIZE_12,
                lineHeight: ThemeConstants.LINEHEIGHT_20,
                marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.SMTOP,
                marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.SMBOTTOM,
              },
            },
          },
          {
            props: { variant: "h6medium" },
            style: {
              fontFamily: ThemeConstants.FONTNAMESECONDARY,
              color: ThemeConstants.COLOR.PRIMARY,
              fontWeight: ThemeConstants.FONTWEIGHT_MEDIUM,
              fontSize: ThemeConstants.FONTSIZE_14,
              lineHeight: ThemeConstants.LINEHEIGHT_22,
              marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.LGTOP,
              marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.LGBOTTOM,
              [`@media(max-width:${ThemeConstants.SM}px)`]: {
                fontSize: ThemeConstants.FONTSIZE_12,
                lineHeight: ThemeConstants.LINEHEIGHT_20,
                marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.SMTOP,
                marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.SMBOTTOM,
              },
            },
          },
          {
            props: { variant: "h6semibold" },
            style: {
              fontFamily: ThemeConstants.FONTNAMESECONDARY,
              color: ThemeConstants.COLOR.PRIMARY,
              fontWeight: ThemeConstants.FONTWEIGHT_SEMIBOLD,
              fontSize: ThemeConstants.FONTSIZE_14,
              lineHeight: ThemeConstants.LINEHEIGHT_22,
              marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.LGTOP,
              marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.LGBOTTOM,
              [`@media(max-width:${ThemeConstants.SM}px)`]: {
                fontSize: ThemeConstants.FONTSIZE_12,
                lineHeight: ThemeConstants.LINEHEIGHT_20,
                marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.SMTOP,
                marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.SMBOTTOM,
              },
            },
          },
          {
            props: { variant: "h6bold" },
            style: {
              fontFamily: ThemeConstants.FONTNAMESECONDARY,
              color: ThemeConstants.COLOR.PRIMARY,
              fontWeight: ThemeConstants.FONTWEIGHT_BOLD,
              fontSize: ThemeConstants.FONTSIZE_14,
              lineHeight: ThemeConstants.LINEHEIGHT_22,
              marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.LGTOP,
              marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.LGBOTTOM,
              [`@media(max-width:${ThemeConstants.SM}px)`]: {
                fontSize: ThemeConstants.FONTSIZE_12,
                lineHeight: ThemeConstants.LINEHEIGHT_20,
                marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.SMTOP,
                marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.SMBOTTOM,
              },
            },
          },

          {
            props: { variant: "p1regular" },
            style: {
              fontFamily: ThemeConstants.FONTNAMESECONDARY,
              color: ThemeConstants.COLOR.SECONDARY,
              fontWeight: ThemeConstants.FONTWEIGHT_REGULAR,
              fontSize: ThemeConstants.FONTSIZE_18,
              lineHeight: ThemeConstants.LINEHEIGHT_26,
              marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P1.MARGIN.LGTOP,
              marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P1.MARGIN.LGBOTTOM,
              [`@media(max-width:${ThemeConstants.SM}px)`]: {
                fontSize: ThemeConstants.FONTSIZE_16,
                lineHeight: ThemeConstants.LINEHEIGHT_24,
                marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P1.MARGIN.SMTOP,
                marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P1.MARGIN.SMBOTTOM,
              },
            },
          },
          {
            props: { variant: "p1medium" },
            style: {
              fontFamily: ThemeConstants.FONTNAMEPRIMARY,
              color: ThemeConstants.COLOR.SECONDARY,
              fontWeight: ThemeConstants.FONTWEIGHT_MEDIUM,
              fontSize: ThemeConstants.FONTSIZE_18,
              lineHeight: ThemeConstants.LINEHEIGHT_26,
              marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P1.MARGIN.LGTOP,
              marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P1.MARGIN.LGBOTTOM,
              [`@media(max-width:${ThemeConstants.SM}px)`]: {
                fontSize: ThemeConstants.FONTSIZE_16,
                lineHeight: ThemeConstants.LINEHEIGHT_24,
                marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P1.MARGIN.SMTOP,
                marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P1.MARGIN.SMBOTTOM,
              },
            },
          },
          {
            props: { variant: "p1semibold" },
            style: {
              fontFamily: ThemeConstants.FONTNAMEPRIMARY,
              color: ThemeConstants.COLOR.SECONDARY,
              fontWeight: ThemeConstants.FONTWEIGHT_SEMIBOLD,
              fontSize: ThemeConstants.FONTSIZE_18,
              lineHeight: ThemeConstants.LINEHEIGHT_26,
              marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P1.MARGIN.LGTOP,
              marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P2.MARGIN.LGBOTTOM,
              [`@media(max-width:${ThemeConstants.SM}px)`]: {
                fontSize: ThemeConstants.FONTSIZE_16,
                lineHeight: ThemeConstants.LINEHEIGHT_24,
                marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P2.MARGIN.SMTOP,
                marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P2.MARGIN.SMBOTTOM,
              },
            },
          },
          {
            props: { variant: "p1bold" },
            style: {
              fontFamily: ThemeConstants.FONTNAMEPRIMARY,
              color: ThemeConstants.COLOR.SECONDARY,
              fontWeight: ThemeConstants.FONTWEIGHT_BOLD,
              fontSize: ThemeConstants.FONTSIZE_18,
              lineHeight: ThemeConstants.LINEHEIGHT_26,
              marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P1.MARGIN.LGTOP,
              marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P2.MARGIN.LGBOTTOM,
              [`@media(max-width:${ThemeConstants.SM}px)`]: {
                fontSize: ThemeConstants.FONTSIZE_16,
                lineHeight: ThemeConstants.LINEHEIGHT_24,
                marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P2.MARGIN.SMTOP,
                marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P2.MARGIN.SMBOTTOM,
              },
            },
          },

          {
            props: { variant: "p2regular" },
            style: {
              fontFamily: ThemeConstants.FONTNAMESECONDARY,
              color: ThemeConstants.COLOR.SECONDARY,
              fontWeight: ThemeConstants.FONTWEIGHT_REGULAR,
              fontSize: ThemeConstants.FONTSIZE_16,
              lineHeight: ThemeConstants.LINEHEIGHT_24,
              marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P2.MARGIN.LGTOP,
              marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P2.MARGIN.LGBOTTOM,
              [`@media(max-width:${ThemeConstants.SM}px)`]: {
                fontSize: ThemeConstants.FONTSIZE_14,
                lineHeight: ThemeConstants.LINEHEIGHT_22,
                marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P2.MARGIN.SMTOP,
                marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P2.MARGIN.SMBOTTOM,
              },
            },
          },
          {
            props: { variant: "p2medium" },
            style: {
              fontFamily: ThemeConstants.FONTNAMESECONDARY,
              color: ThemeConstants.COLOR.SECONDARY,
              fontWeight: ThemeConstants.FONTWEIGHT_MEDIUM,
              fontSize: ThemeConstants.FONTSIZE_16,
              lineHeight: ThemeConstants.LINEHEIGHT_24,
              marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P2.MARGIN.LGTOP,
              marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P2.MARGIN.LGBOTTOM,
              [`@media(max-width:${ThemeConstants.SM}px)`]: {
                fontSize: ThemeConstants.FONTSIZE_14,
                lineHeight: ThemeConstants.LINEHEIGHT_22,
                marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P2.MARGIN.SMTOP,
                marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P2.MARGIN.SMBOTTOM,
              },
            },
          },
          {
            props: { variant: "p2semibold" },
            style: {
              fontFamily: ThemeConstants.FONTNAMESECONDARY,
              color: ThemeConstants.COLOR.SECONDARY,
              fontWeight: ThemeConstants.FONTWEIGHT_SEMIBOLD,
              fontSize: ThemeConstants.FONTSIZE_16,
              lineHeight: ThemeConstants.LINEHEIGHT_24,
              marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P2.MARGIN.LGTOP,
              marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P2.MARGIN.LGBOTTOM,
              [`@media(max-width:${ThemeConstants.SM}px)`]: {
                marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P2.MARGIN.SMTOP,
                marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P2.MARGIN.SMBOTTOM,
              },
            },
          },
          {
            props: { variant: "p2bold" },
            style: {
              fontFamily: ThemeConstants.FONTNAMESECONDARY,
              color: ThemeConstants.COLOR.SECONDARY,
              fontWeight: ThemeConstants.FONTWEIGHT_BOLD,
              fontSize: ThemeConstants.FONTSIZE_16,
              lineHeight: ThemeConstants.LINEHEIGHT_24,
              marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P2.MARGIN.LGTOP,
              marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P2.MARGIN.LGBOTTOM,
              [`@media(max-width:${ThemeConstants.SM}px)`]: {
                fontSize: ThemeConstants.FONTSIZE_14,
                lineHeight: ThemeConstants.LINEHEIGHT_22,
                marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P2.MARGIN.SMTOP,
                marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P2.MARGIN.SMBOTTOM,
              },
            },
          },

          {
            props: { variant: "p3regular" },
            style: {
              fontFamily: ThemeConstants.FONTNAMESECONDARY,
              color: ThemeConstants.COLOR.SECONDARY,
              fontWeight: ThemeConstants.FONTWEIGHT_REGULAR,
              fontSize: ThemeConstants.FONTSIZE_14,
              lineHeight: ThemeConstants.LINEHEIGHT_22,
              marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.LGTOP,
              marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.LGBOTTOM,
              [`@media(max-width:${ThemeConstants.SM}px)`]: {
                fontSize: ThemeConstants.FONTSIZE_12,
                lineHeight: ThemeConstants.LINEHEIGHT_20,
                marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.SMTOP,
                marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.SMBOTTOM,
              },
            },
          },
          {
            props: { variant: "p3medium" },
            style: {
              fontFamily: ThemeConstants.FONTNAMESECONDARY,
              color: ThemeConstants.COLOR.SECONDARY,
              fontWeight: ThemeConstants.FONTWEIGHT_MEDIUM,
              fontSize: ThemeConstants.FONTSIZE_14,
              lineHeight: ThemeConstants.LINEHEIGHT_22,
              marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.LGTOP,
              marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.LGBOTTOM,
              [`@media(max-width:${ThemeConstants.SM}px)`]: {
                fontSize: ThemeConstants.FONTSIZE_12,
                lineHeight: ThemeConstants.LINEHEIGHT_20,
                marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.SMTOP,
                marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.SMBOTTOM,
              },
            },
          },
          {
            props: { variant: "p3semibold" },
            style: {
              fontFamily: ThemeConstants.FONTNAMESECONDARY,
              color: ThemeConstants.COLOR.SECONDARY,
              fontWeight: ThemeConstants.FONTWEIGHT_SEMIBOLD,
              fontSize: ThemeConstants.FONTSIZE_14,
              lineHeight: ThemeConstants.LINEHEIGHT_22,
              marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.LGTOP,
              marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.LGBOTTOM,
              [`@media(max-width:${ThemeConstants.SM}px)`]: {
                fontSize: ThemeConstants.FONTSIZE_12,
                lineHeight: ThemeConstants.LINEHEIGHT_20,
                marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.SMTOP,
                marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.SMBOTTOM,
              },
            },
          },
          {
            props: { variant: "p3bold" },
            style: {
              fontFamily: ThemeConstants.FONTNAMESECONDARY,
              color: ThemeConstants.COLOR.SECONDARY,
              fontWeight: ThemeConstants.FONTWEIGHT_BOLD,
              fontSize: ThemeConstants.FONTSIZE_14,
              lineHeight: ThemeConstants.LINEHEIGHT_22,
              marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.LGTOP,
              marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.LGBOTTOM,
              [`@media(max-width:${ThemeConstants.SM}px)`]: {
                fontSize: ThemeConstants.FONTSIZE_12,
                lineHeight: ThemeConstants.LINEHEIGHT_20,
                marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.SMTOP,
                marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.SMBOTTOM,
              },
            },
          },
          {
            props: { variant: "p4regular" },
            style: {
              fontFamily: ThemeConstants.FONTNAMESECONDARY,
              color: ThemeConstants.COLOR.SECONDARY,
              fontWeight: ThemeConstants.FONTWEIGHT_REGULAR,
              fontSize: ThemeConstants.FONTSIZE_12,
              lineHeight: ThemeConstants.LINEHEIGHT_18,
              marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.LGTOP,
              marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.LGBOTTOM,
              [`@media(max-width:${ThemeConstants.SM}px)`]: {
                marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.SMTOP,
                marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.SMBOTTOM,
              },
            },
          },
          {
            props: { variant: "p4medium" },
            style: {
              fontFamily: ThemeConstants.FONTNAMESECONDARY,
              color: ThemeConstants.COLOR.SECONDARY,
              fontWeight: ThemeConstants.FONTWEIGHT_MEDIUM,
              fontSize: ThemeConstants.FONTSIZE_12,
              lineHeight: ThemeConstants.LINEHEIGHT_18,
              marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.LGTOP,
              marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.LGBOTTOM,
              [`@media(max-width:${ThemeConstants.SM}px)`]: {
                marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.SMTOP,
                marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.SMBOTTOM,
              },
            },
          },
          {
            props: { variant: "p4semibold" },
            style: {
              fontFamily: ThemeConstants.FONTNAMESECONDARY,
              color: ThemeConstants.COLOR.SECONDARY,
              fontWeight: ThemeConstants.FONTWEIGHT_SEMIBOLD,
              fontSize: ThemeConstants.FONTSIZE_12,
              lineHeight: ThemeConstants.LINEHEIGHT_18,
              marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.LGTOP,
              marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.LGBOTTOM,
              [`@media(max-width:${ThemeConstants.SM}px)`]: {
                marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.SMTOP,
                marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.SMBOTTOM,
              },
            },
          },
          {
            props: { variant: "p4bold" },
            style: {
              fontFamily: ThemeConstants.FONTNAMESECONDARY,
              color: ThemeConstants.COLOR.SECONDARY,
              fontWeight: ThemeConstants.FONTWEIGHT_BOLD,
              fontSize: ThemeConstants.FONTSIZE_12,
              lineHeight: ThemeConstants.LINEHEIGHT_18,
              marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.LGTOP,
              marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.LGBOTTOM,
              [`@media(max-width:${ThemeConstants.SM}px)`]: {
                marginTop: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.SMTOP,
                marginBottom: ThemeConstants.TYPOGRAPHY.PARAGRAPH.P3.MARGIN.SMBOTTOM,
              },
            },
          },

          {
            props: { variant: "labelregular" },
            style: {
              fontFamily: ThemeConstants.FONTNAMESECONDARY,
              color: ThemeConstants.LABEL.PRIMARY.COLOR,
              fontWeight: ThemeConstants.FONTWEIGHT_REGULAR,
              fontSize: ThemeConstants.FONTSIZE_12,
              lineHeight: ThemeConstants.LINEHEIGHT_18,
              display: "inline-block",
              [`@media(max-width:${ThemeConstants.SM}px)`]: {
                paddingBottom: ThemeConstants.ELEMENT.LABEL.PADDING.SMBOTTOM,
              },
            },
          },
          {
            props: { variant: "labelbold" },
            style: {
              fontFamily: ThemeConstants.FONTNAMESECONDARY,
              color: ThemeConstants.LABEL.PRIMARY.COLOR,
              fontWeight: ThemeConstants.FONTWEIGHT_BOLD,
              fontSize: ThemeConstants.FONTSIZE_12,
              lineHeight: ThemeConstants.LINEHEIGHT_18,
              display: "inline-block",
              [`@media(max-width:${ThemeConstants.SM}px)`]: {
                paddingBottom: ThemeConstants.ELEMENT.LABEL.PADDING.SMBOTTOM,
              },
            },
          },
        ],
      },
      MuiTab: {
        styleOverrides: {
          root: {
            "&.Platform-x-Tab-root.Mui-selected": {
              color: ThemeConstants.TAB.VARIANT1.TITLE_ACTIVE,
            },
            // ".Platform-x-Tabs-flexContainer": {
            //   justifyContent: "center",
            //   marginBottom: "20px",
            // },
            // ".MuiTabs-flexContainer": {
            //   justifyContent: "center",
            //   marginBottom: "20px",
            // },
            // "&.Platform-x-Tab-root": {
            //   // border: "1px solid #d5d5d5",
            //   padding: "10px 25px",
            //   minWidth: "150px",
            //   fontFamily: ThemeConstants.FONTNAMEPRIMARY,
            //   textTransform: "capitalize",
            //   fontSize: ThemeConstants.FONTSIZE_16,
            //   fontWeight: ThemeConstants.FONTWEIGHT_REGULAR,
            //   [`@media (max-width:${ThemeConstants.LG}px)`]: {
            //     padding: "20px 10px",
            //     minWidth: "180px",
            //     minHeight: "0",
            //     fontSize: "16px",
            //   },
            //   [`@media (max-width:${ThemeConstants.MD}px)`]: {
            //     padding: "13px 10px",
            //     minWidth: "150px",
            //   },
            // },
            // ".Platform-x-Typography-root": {
            //   [`@media (max-width:${ThemeConstants.MD}px)`]: {
            //     fontSize: ThemeConstants.FONTSIZE_14,
            //   },
            // },
          },
        },
      },
      MuiSelect: {
        variants: [
          {
            props: { variant: "filled" },
            style: {
              "&.Platform-x-InputBase-root, &.custom-dropdown": {
                background: ThemeConstants.SELECT.PLACEHOLDER,
                borderRadius: ThemeConstants.ELEMENT.SELECT.BORDER_RADIUS,
                border: ThemeConstants.SELECT.BORDER,
                "& .Platform-x-FilledInput-input, & #select-small": {
                  padding: ThemeConstants.ELEMENT.SELECT.PADDING.md,
                },
                "& svg": {
                  color: ThemeConstants.SELECT.TEXT,
                },
                "&.Mui-focused": {
                  background: ThemeConstants.SELECT.PLACEHOLDER,
                  border: ThemeConstants.SELECT.BORDER_ACTIVE,
                },
                "&.Platform-x-MenuItem-gutters.Mui-selected": {
                  fontWeight: ThemeConstants.FONTWEIGHT_BOLD,
                  background: ThemeConstants.SELECT.PLACEHOLDER,
                },
                "&.Mui-disabled": {
                  "& .Mui-disabled": {
                    "-webkit-text-fill-color": ThemeConstants.SELECT.LABEL,
                  },
                },
                "& .Platform-x-InputBase-input:focus": {
                  background: ThemeConstants.SELECT.PLACEHOLDER,
                  border: "none",
                },
                "&:before": { display: "none" },
                "&:after": { display: "none" },
              },
              "&.Platform-x-FormLabel-root": {
                "&.Mui-focused": {
                  color: "transparent",
                },
              },
              "&.Platform-x-InputBase-root, &.Platform-x-OutlinedInput-root, &.Platform-x-InputBase-formControl":
                {
                  "&.Mui-error": {
                    background: ThemeConstants.INPUT.BACKGROUND_ERROR,
                    border: ThemeConstants.INPUT.BORDER_ERROR,
                    "&.Mui-focused": {
                      background: ThemeConstants.INPUT.BACKGROUND_ERROR,
                      border: ThemeConstants.INPUT.BORDER_ERROR,
                    },
                  },
                },
            },
          },
        ],
      },
      MuiTextField: {
        variants: [
          {
            props: { variant: "filled" },
            style: {
              textTransform: "none",
              "& input:focus": {
                background: ThemeConstants.INPUT.BACKGROUND_FOCUSED,
              },
              "& input, & textarea": {
                padding: ThemeConstants.ELEMENT.TEXTBOX.PADDING.md,
                borderRadius: ThemeConstants.ELEMENT.TEXTBOX.BORDER_RADIUS,
                fontSize: ThemeConstants.FONTSIZE_14,
                width: "100%",
                "&.Mui-disabled": {
                  WebkitTextFillColor: ThemeConstants.INPUT.TEXT,
                  opacity: 0.5,
                },
              },
              "& textarea": {
                // paddingLeft: 0,
              },
              "& .Platform-x-FilledInput-root, &.Platform-x-OutlinedInput-root": {
                background: ThemeConstants.INPUT.PLACEHOLDER,
                border: ThemeConstants.INPUT.BORDER,
                borderRadius: ThemeConstants.ELEMENT.TEXTBOX.BORDER_RADIUS,
                color: ThemeConstants.INPUT.TEXT,
                fontSize: ThemeConstants.FONTSIZE_14,
                padding: "0px !important",
                width: "100%",
                "& .Platform-x-InputAdornment-positionEnd,& .MuiInputAdornment-positionEnd": {
                  width: "40px",
                  paddingRight: "10px",
                },
                "&::after, &::before": { display: "none" },
                "&:hover": {
                  background: ThemeConstants.INPUT.BACKGROUND_FOCUSED,
                },
                "& svg": {
                  fill: ThemeConstants.INPUT.TEXT,
                },
              },
              "& .Platform-x-FilledInput-root.Mui-focused": {
                background: ThemeConstants.INPUT.BACKGROUND_FOCUSED,
                border: ThemeConstants.INPUT.BORDER_ACTIVE,
                borderRadius: ThemeConstants.ELEMENT.TEXTBOX.BORDER_RADIUS,
                "& svg": {
                  fill: ThemeConstants.INPUT.TEXT,
                },
              },
              "& fieldset": { border: "none" },
              "& .Platform-x-FilledInput-root.Mui-disabled": {
                background: `${ThemeConstants.INPUT.DISABLED} !important`,
              },
              ".textfield-close-icon": {
                opacity: 0,
              },
              ".Mui-focused": {
                ".textfield-close-icon": {
                  opacity: 1,
                },
              },
              ".Mui-disabled": {
                "&.Platform-x-FormHelperText-root": {
                  color: `${ThemeConstants.INPUT.DISABLED} !important`,
                },
                "& svg": {
                  opacity: 0.2,
                },
              },
              "&. Mui-success": {
                "& input:focus, input": {
                  background: ThemeConstants.INPUT.BACKGROUND_SUCCESS,
                },
                "& .Platform-x-FilledInput-root": {
                  background: ThemeConstants.INPUT.BACKGROUND_SUCCESS,
                },
                "& .Platform-x-FilledInput-root.Mui-focused": {
                  border: ThemeConstants.INPUT.BORDER_SUCCESS,
                },
                "& label": {
                  color: `${ThemeConstants.INPUT.BACKGROUND_SUCCESS_TEXT} !important`,
                  fontSize: ThemeConstants.FONTSIZE_14,
                  fontWeight: ThemeConstants.FONTWEIGHT_REGULAR,
                  "&.Mui-disabled": {
                    color: ThemeConstants.INPUT.DISABLED,
                  },
                },
                "& .Platform-x-InputBase-root + p": {
                  color: ThemeConstants.INPUT.BACKGROUND_SUCCESS_TEXT,
                  fontSize: ThemeConstants.FONTSIZE_14,
                  fontWeight: ThemeConstants.FONTWEIGHT_REGULAR,
                },
                "& .textfield-close-icon": {
                  color: ThemeConstants.INPUT.BACKGROUND_SUCCESS_TEXT,
                },
                "& .Platform-x-FilledInput-root.Mui-disabled": {
                  background: `${ThemeConstants.INPUT.DISABLED} !important`,
                  "& input": {
                    background: `${ThemeConstants.INPUT.DISABLED} !important`,
                  },
                },
                ".Mui-disabled": {
                  "&.Platform-x-FormHelperText-root": {
                    color: `${ThemeConstants.INPUT.DISABLED} !important`,
                  },
                  "& svg": {
                    opacity: 0.2,
                  },
                },
              },
              "& .Mui-error": {
                "& input:focus, input": {
                  background: ThemeConstants.INPUT.BACKGROUND_ERROR,
                },
                "&.Platform-x-FormHelperText-root": {
                  margin: "10px 0px 0px 0px",
                },
                "&.Platform-x-FilledInput-root": {
                  border: ThemeConstants.INPUT.BORDER_ERROR,
                  background: ThemeConstants.INPUT.BACKGROUND_ERROR,
                },
                "& label": {
                  color: `${ThemeConstants.INPUT.BACKGROUND_ERROR_TEXT} !important`,
                  fontSize: ThemeConstants.FONTSIZE_14,
                  fontWeight: ThemeConstants.FONTWEIGHT_REGULAR,
                  "&.Mui-disabled": {
                    color: ThemeConstants.INPUT.DISABLED,
                  },
                },
                "& .Platform-x-InputBase-root + p": {
                  color: ThemeConstants.INPUT.BACKGROUND_ERROR_TEXT,
                  fontSize: ThemeConstants.FONTSIZE_14,
                  fontWeight: ThemeConstants.FONTWEIGHT_REGULAR,
                },
                "& .textfield-close-icon": {
                  color: ThemeConstants.INPUT.BACKGROUND_ERROR_TEXT,
                },
                "& .Platform-x-FilledInput-root.Mui-disabled": {
                  background: `${ThemeConstants.INPUT.DISABLED} !important`,
                  "& input": {
                    background: `${ThemeConstants.INPUT.DISABLED} !important`,
                  },
                },
                ".Mui-disabled": {
                  "&.Platform-x-FormHelperText-root, .textfield-close-icon": {
                    color: `${ThemeConstants.INPUT.DISABLED} !important`,
                  },
                  "& svg": {
                    opacity: 0.2,
                  },
                },
              },
            },
          },
        ],
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            "&.Platform-x-Autocomplete-root": {
              "&.auto-complete-textbox": {
                "& input[role='combobox']": {
                  padding: ThemeConstants.ELEMENT.SELECT.PADDING.md,
                  opacity: 1,
                  ":focus": {
                    background: ThemeConstants.SELECT.PLACEHOLDER,
                  },
                },
              },
              "& .Platform-x-Autocomplete-endAdornment": {
                right: "8px",
                "& svg": {
                  fill: ThemeConstants.SELECT.TEXT,
                },
                "& .Platform-x-Autocomplete-clearIndicator": {
                  display: "none",
                },
              },
              "& .Platform-x-FilledInput-root.Mui-focused": {
                background: ThemeConstants.SELECT.PLACEHOLDER,
              },
              "&.Mui-disabled": {},
              "&.error": {
                "& input:focus, input": {
                  background: ThemeConstants.INPUT.BACKGROUND_ERROR,
                },
                "& .Platform-x-FilledInput-root": {
                  background: ThemeConstants.INPUT.BACKGROUND_ERROR,
                },
                "& .Platform-x-FilledInput-root.Mui-focused": {
                  border: ThemeConstants.INPUT.BORDER_ERROR,
                },
                "& label": {
                  color: `${ThemeConstants.INPUT.BACKGROUND_ERROR_TEXT} !important`,
                  fontSize: ThemeConstants.FONTSIZE_14,
                  fontWeight: ThemeConstants.FONTWEIGHT_REGULAR,
                  "&.Mui-disabled": {
                    color: ThemeConstants.INPUT.DISABLED,
                  },
                },
                "& .Platform-x-InputBase-root + p": {
                  color: ThemeConstants.INPUT.BACKGROUND_ERROR_TEXT,
                  fontSize: ThemeConstants.FONTSIZE_14,
                  fontWeight: ThemeConstants.FONTWEIGHT_REGULAR,
                },
                "& .textfield-close-icon": {
                  color: ThemeConstants.INPUT.BACKGROUND_ERROR_TEXT,
                },
                "& .Platform-x-FilledInput-root.Mui-disabled": {
                  background: `${ThemeConstants.INPUT.DISABLED} !important`,
                  "& input": {
                    background: `${ThemeConstants.INPUT.DISABLED} !important`,
                  },
                },
                ".Mui-disabled": {
                  "&.Platform-x-FormHelperText-root, .textfield-close-icon": {
                    color: `${ThemeConstants.INPUT.DISABLED} !important`,
                  },
                  "& svg": {
                    opacity: 0.2,
                  },
                },
              },
            },
            "&.Platform-x-Autocomplete-root + .Platform-x-Autocomplete-popper": {
              "& .Platform-x-Paper-root": {
                boxShadow:
                  "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                paddingTop: "10px",
                paddingBottom: "10px",
                // background: ThemeConstants.SELECT.PLACEHOLDER,
                "& .Platform-x-Autocomplete-listbox": {
                  background: ThemeConstants.SELECT.PLACEHOLDER,
                  color: ThemeConstants.SELECT.TEXT,
                  overflowX: "hidden",
                  "& li": {
                    color: ThemeConstants.SELECT.TEXT,
                    padding: "8px",
                    cursor: "pointer",
                    display: "flex",
                    whiteSpace: "nowrap",
                    margin: 0,
                    "& .Platform-x-Box-root": {
                      display: "flex",
                      alignItems: "center",
                      width: "25px",
                      marginRight: "10px",
                    },
                    "&:hover": {
                      background: ThemeConstants.SELECT.TEXT_BACKGROUND_HOVER,
                      color: ThemeConstants.SELECT.TEXT_HOVER,
                    },
                  },
                },
              },
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            "&.Platform-x-InputBase-root.Platform-x-OutlinedInput-root, &.MuiInputBase-root.MuiOutlinedInput-root":
              {
                background: ThemeConstants.SELECT.PLACEHOLDER,
                border: ThemeConstants.SELECT.BORDER,
                padding: 0,
                "& input": {
                  padding: ThemeConstants.ELEMENT.SELECT.PADDING.md,
                  color: ThemeConstants.SELECT.TEXT_SELECTED,
                  WebkitTextFillColor: ThemeConstants.SELECT.TEXT_SELECTED,
                  "&.Platform-x-Autocomplete-input": {
                    textAlign: "left",
                    "&.Mui-disabled": {
                      opacity: 0.5,
                    },
                  },
                },
                "&.Mui-focused": {
                  background: ThemeConstants.SELECT.PLACEHOLDER,
                  border: ThemeConstants.SELECT.BORDER_ACTIVE,
                },
                "& fieldset": {
                  border: "none",
                },
                "& .Platform-x-InputAdornment-positionEnd, & .MuiInputAdornment-positionEnd": {
                  paddingRight: "8px",
                  "& svg": {
                    color: ThemeConstants.SELECT.TEXT,
                  },
                },
                "&.error": {
                  background: ThemeConstants.INPUT.BACKGROUND_ERROR,
                  "& input:focus, input": {
                    background: ThemeConstants.INPUT.BACKGROUND_ERROR,
                  },
                  "& .Platform-x-InputBase-root + p": {
                    color: ThemeConstants.INPUT.BACKGROUND_ERROR_TEXT,
                    fontSize: ThemeConstants.FONTSIZE_14,
                    fontWeight: ThemeConstants.FONTWEIGHT_REGULAR,
                  },
                  ".Mui-disabled": {
                    "&.Platform-x-FormHelperText-root, .textfield-close-icon": {
                      color: `${ThemeConstants.INPUT.DISABLED} !important`,
                      background: ThemeConstants.SELECT.PLACEHOLDER,
                    },
                    "& svg": {
                      opacity: 0.2,
                    },
                  },
                },
              },
            "&.Mui-disabled": {
              opacity: 0.5,
              color: ThemeConstants.SELECT.TEXT,
              background: `${ThemeConstants.INPUT.DISABLED} !important`,
              "& svg": {
                opacity: 0.5,
              },
            },
            "& input.Mui-disabled": {
              WebkitTextFillColor: ThemeConstants.SELECT.TEXT,
            },
          },
        },
      },
      MuiDivider: {
        variants: [
          {
            props: { variant: "fullWidth" },
            style: {
              borderColor: ThemeConstants.DIVIDER.PRIMARY.COLOR,
            },
          },
        ],
      },
      MuiLink: {
        variants: [
          {
            props: { variant: "overline" },
            style: {
              color: ThemeConstants.LINK.PRIMARY.COLOR,
              fontSize: ThemeConstants.FONTSIZE_12,
              lineHeight: ThemeConstants.LINEHEIGHT_20,
              "&:hover": {
                color: ThemeConstants.LINK.PRIMARY.HOVER_COLOR,
                textDecoration: ThemeConstants.LINK.PRIMARY.TEXT_DECORATION,
              },
            },
          },
        ],
      },

      MuiAccordion: {
        styleOverrides: {
          root: {
            // border: `solid ${ThemeConstants.ELEMENT.ACCORDIAN.BORDER} ${ThemeConstants.ACCORDIAN.BORDER_COLOR}`,
            borderRadius: ThemeConstants.ELEMENT.ACCORDIAN.BORDER_RADIUS,
            background: ThemeConstants.ACCORDIAN.BACKGROUND,
            color: ThemeConstants.ACCORDIAN.COLOR,
            "&.accordianHeading, &.Platform-x-Accordion-root": {
              // marginBottom: ThemeConstants.ELEMENT.ACCORDIAN.MARGINBOTTOM,
              // padding: ThemeConstants.ELEMENT.ACCORDIAN.PADDING1,
              svg: { color: ThemeConstants.COLOR.PRIMARY },
            },
            "&.accordianHeading": {
              "&.lg": { padding: ThemeConstants.ELEMENT.ACCORDIAN.PADDING2 },
              svg: { color: ThemeConstants.COLOR.PRIMARY },
              "&:last-child": {
                marginBottom: 0,
              },
              "&::before": {
                display: "none",
              },
            },
            "& .accordiansummary, &.Platform-x-AccordionSummary-content": {
              margin: 0,
              padding: 0,
              textAlign: "left",
              "& .Platform-x-Typography-root": {
                margin: 0,
                padding: 0,
                marginRight: "20px",
              },
            },
            "& .accordianDetail": { padding: 0 },
            "& .accordianDetail .Platform-x-Typography-root, &.Platform-x-Typography-root": {
              padding: 0,
              // margin: "4px 20px 16px 0",
            },
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            color: ThemeConstants.COLOR.PRIMARY,
          },
        },
      },
      MuiChip: {
        variants: [
          {
            props: { variant: "draftBadge" },
            style: {
              color: ThemeConstants.CHIP.DRAFT.COLOR,
              background: ThemeConstants.CHIP.DRAFT.BACKGROUND_COLOR,
              border: ThemeConstants.CHIP.DRAFT.BORDER,
              borderRadius: ThemeConstants.CHIP.BORDER_RADIUS,
              textTransform: "capitalize",
              fontSize: ThemeConstants.FONTSIZE_12,
              fontWeight: ThemeConstants.FONTWEIGHT_MEDIUM,
              height: ThemeConstants.CHIP.MIN_HEIGHT,
              padding: ThemeConstants.CHIP.PADDING,
              margin: ThemeConstants.CHIP.MARGIN,
              "& span": {
                padding: 0,
              },
            },
          },
          {
            props: { variant: "publishBadge" },
            style: {
              color: ThemeConstants.CHIP.PENDING.COLOR,
              background: ThemeConstants.CHIP.PENDING.BACKGROUND_COLOR,
              border: ThemeConstants.CHIP.PENDING.BORDER,
              borderRadius: ThemeConstants.CHIP.BORDER_RADIUS,
              textTransform: "capitalize",
              fontSize: ThemeConstants.FONTSIZE_12,
              fontWeight: ThemeConstants.FONTWEIGHT_MEDIUM,
              height: ThemeConstants.CHIP.MIN_HEIGHT,
              padding: ThemeConstants.CHIP.PADDING,
              margin: ThemeConstants.CHIP.MARGIN,
              "& span": {
                padding: 0,
              },
            },
          },
          {
            props: { variant: "pendingBadge" },
            style: {
              color: ThemeConstants.CHIP.PENDING.COLOR,
              background: ThemeConstants.CHIP.PENDING.BACKGROUND_COLOR,
              border: ThemeConstants.CHIP.PENDING.BORDER,
              borderRadius: ThemeConstants.CHIP.BORDER_RADIUS,
              textTransform: "capitalize",
              fontSize: ThemeConstants.FONTSIZE_12,
              fontWeight: ThemeConstants.FONTWEIGHT_MEDIUM,
              height: ThemeConstants.CHIP.MIN_HEIGHT,
              padding: ThemeConstants.CHIP.PADDING,
              margin: ThemeConstants.CHIP.MARGIN,
              "& span": {
                padding: 0,
              },
            },
          },
        ],
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            color: ThemeConstants.TOOLTIP.PRIMARY.COLOR,
            opacity: "0.95",
            borderRadius: "4px",
            backgroundColor: ThemeConstants.TOOLTIP.PRIMARY.BACKGROUNDCOLOR,
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            "&.Platform-x-Radio-colorPrimary": {
              "& svg": {
                fontSize: "20px",
                border: `solid 4px ${ThemeConstants.COLOR.LINE}`,
                borderRadius: "50%",
                background: ThemeConstants.COLOR.LINE,
                "& path": {
                  display: "none",
                },
              },
              "&.Mui-checked": {
                boxShadow: "none",
                "& svg": {
                  border: `solid 4px ${ThemeConstants.COLOR.PRIMARY}`,
                  background: "transparent",
                  "& path": {
                    display: "none",
                  },
                },
                "& .PrivateSwitchBase-input:hover": {
                  background: "transparent",
                },
              },
            },
            "&.Platform-x-Radio-colorDefault": {
              "& span": {
                border: `solid 4px ${ThemeConstants.COLOR.LINE}`,
                background: ThemeConstants.COLOR.LINE,
                borderRadius: "50%",
              },
              "& span:before": {
                display: "none",
              },
              "&.Mui-checked": {
                boxShadow: "none",
                "& > span": {
                  border: `solid 4px ${ThemeConstants.COLOR.PRIMARY}`,
                  background: "transparent",
                },
                "& .PrivateSwitchBase-input:hover": {
                  background: "transparent",
                },
              },
            },
            ".Platform-x-FormControlLabel-label": {
              textTransform: "captilize",
            },
          },
        },
      },
    },
  });

export default AuthroingTheme;
