import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    wheelCarousal: {
      "&.wheelCarousalWrapper": {
        background: theme.palette.prelemType1.BACKGROUND,
        border: `solid 1px ${theme.palette.prelemType1.LINE}`,
        "& .grid_container.prelem-py": {
          width: "100%",
          padding: "20px !important",
          "& #Title": {
            color: theme.palette.prelemType1.Title,
            fontSize: "20px",
            margin: 0,
          },
          "& .noResultFound": {
            "& p": {
              color: theme.palette.prelemType1.Title,
            },
          },
          "& .XCard5Box": {
            "& .XCard5innderBox": {
              "& .imgWrapper": {
                [theme.breakpoints.between("em", "lg")]: {
                  height: "200px",
                },
                [theme.breakpoints.up("lg")]: {
                  height: "300px",
                },
              },
            },
          },
        },
        "& .textWrapper": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        },
        "& .navigationWrapper": {
          display: "flex",
          minWidth: "68px",
          justifyContent: "space-between",
        },
        "& .noResultFound": {
          display: "flex",
          justifyContent: "center",
          width: "inherit",
          padding: "30px 0",
        },
        "& .add-content-overlay": {
          background: `rgba(${theme.palette.overlay["editOverlay"]})`,
          position: "absolute",
          width: "100%",
          height: "100%",
          top: "0",
          left: "",
          display: "none",
          alignItems: "center",
          justifyContent: "center",
          zIndex: "1",
        },
        "& .arrowIcons": {
          fill: theme.palette.prelemType3.TITLE,
          cursor: "pointer",
          "&.arrowIconsDisabled": {
            opacity: 0.5,
          },
        },
      },
    },
  };
});
