import { Box, Button, Divider, Typography } from "@mui/material";
import { CouponProps } from "./Coupon.Types";

const CouponBox = ({
  CouponCode,
  Title,
  Description,
  Description2,
  Expiry,
  onCouponApply,
}: CouponProps) => {
  return (
    <Box className='TextboxExtra'>
      <Box className='TextboxExtraPadding'>
        <Box className='BoxOtherCoupon'>
          <Typography
            sx={{ visibility: CouponCode ? "normal" : "hidden" }}
            className='TypograpgyDivOtherCoupon'
            variant='h5medium'>
            {CouponCode}
          </Typography>
          <Button
            onClick={() => onCouponApply(CouponCode)}
            disabled={CouponCode ? false : true}
            variant='primaryButton1'
            sx={{
              minWidth: "auto",
            }}>
            Apply
          </Button>
        </Box>

        <Divider className='dividerColor' />
        <Box>
          <Typography className='TypograpgySave' variant='h5medium'>
            {Title}
          </Typography>
          <Typography className='TypograpgyOffOnMin' variant='h6regular'>
            {Description}
          </Typography>
          <Box className='BoxTypograpgyExpiryAndDate'>
            <Box>
              <Typography className='OtherCouponsTypograpgyExpiry' variant='h7regular'>
                Expiry:
              </Typography>
            </Box>
            <Box>
              <Typography className='OtherCouponTypograpgyDate' variant='h7semibold'>
                {Expiry ?? "-"}
              </Typography>
            </Box>
          </Box>
          {Description2 ? (
            <>
              <Divider className='dividerColor' />
              <Typography variant='p4regular' className='TypographyMarginTop'>
                {Description2}
              </Typography>
            </>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default CouponBox;
