import { Box, Grid, Skeleton } from "@mui/material";

const LoaderComponent = ({ folderMenuOpen }) => {
  return (
    <Grid container spacing={3}>
      {Array.from([1, 2, 3, 4, 5, 6]).map((index) => (
        <>
          <Grid
            key={index}
            item
            xs={12}
            sm={12}
            md={folderMenuOpen ? 3 : 2.4}
            sx={{ padding: "5px" }}>
            <Box
              className='skeleton skeleton-card'
              sx={{ height: { xs: "200px", sm: "250px" }, borderRadius: "5px" }}>
              <Skeleton variant='rectangular' width='100%' height='100%' />
            </Box>
          </Grid>
          <Grid key={`${index}-`} item xs={6} sm={6} md={3} sx={{ padding: "5px" }}>
            <Box
              className='skeleton skeleton-card'
              sx={{ height: { xs: "200px", sm: "250px", borderRadius: "5px" } }}>
              <Skeleton variant='rectangular' width='100%' height='100%' />
            </Box>
          </Grid>
        </>
      ))}
    </Grid>
  );
};

export default LoaderComponent;
