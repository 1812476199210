import { Box, Typography, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import graphQlList from "../../../../graphql/fetch";
import ToastService from "../../../ToastContainer/ToastService";
import { WheelColors } from "../../helperProfile";
import Confettipopup from "./Confettipopup";
import "./Wheel.css";
import CenteredSkeleton from "./WheelSkelton";
import { useCustomStyle } from "./WheelSpin.style";

const Wheel = ({ content, member_id, secondaryArgs }) => {
  const classes = useCustomStyle();
  const theme = useTheme();
  const [showPopup, setShowPopup] = useState(false);
  const [sectors, setSectors] = useState<any[]>([]);
  const [winPrize, setWinPrize] = useState(null);
  const spinButtonRef = useRef<HTMLDivElement | null>(null);
  const wheelCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const friction = 0.995; // 0.995=soft, 0.99=mid, 0.98=hard
  let angVel = 0; // Angular velocity
  let ang = 0; // Angle in radians
  let spinButtonClicked = false;
  const { PI } = Math;
  const TAU = 2 * PI;
  const arc = TAU / (sectors.length || 1); // Guard against empty sectors initially
  const { t } = useTranslation();
  type Line = {
    key: number;
    x1: number;
    y1: number;
    x2: number;
    y2: number;
    stroke: string;
    strokeWidth: number;
  };
  const [lines, setLines] = useState<Line[]>([]);

  const outerCircleRadius = 159; // Half of circle (width/height)
  const innerRadius = 145; // Distance where minute markers will start
  const circleDiameter = 320; // Diameter of the watch face
  const events = {
    listeners: {},
    addListener(eventName: string, fn: Function) {
      this.listeners[eventName] = this.listeners[eventName] || [];
      this.listeners[eventName].push(fn);
    },
    fire(eventName: string, ...args: any[]) {
      if (this.listeners[eventName]) {
        for (const fn of this.listeners[eventName]) {
          fn(...args);
        }
      }
    },
  };

  const rand = (m: number, M: number) => Math.random() * (M - m) + m;
  const getIndex = () => Math.floor(sectors.length - (ang / TAU) * sectors.length) % sectors.length;

  // Drawing a single sector
  const drawSector = (sector: any, i: number, ctx: CanvasRenderingContext2D) => {
    const rad = ctx.canvas.width / 2;
    const angle = arc * i;
    ctx.save();

    // COLOR
    ctx.beginPath();
    ctx.fillStyle = sector.background;
    ctx.moveTo(rad, rad);
    ctx.arc(rad, rad, rad, angle, angle + arc);
    ctx.lineTo(rad, rad);
    ctx.fill();

    // TEXT
    const labelText =
      sector?.reward_name?.length > 14
        ? `${sector.reward_name.slice(0, 12)}...`
        : sector.reward_name;
    ctx.translate(rad, rad);
    ctx.rotate(angle + arc / 2);
    ctx.textAlign = "right";
    ctx.fillStyle = sector.color;
    ctx.font = `14px ${theme.fontFamily.primary}`;
    ctx.fillText(labelText, rad - 10, 10);
    ctx.restore();
  };

  // Rotate the wheel based on the current angle
  const rotate = (ctx: CanvasRenderingContext2D, spinEl: HTMLElement | null) => {
    const sector = sectors[getIndex()];
    ctx.canvas.style.transform = `rotate(${ang - PI / 2}rad)`;

    // Set styles for the spin button
    if (spinEl) {
      spinEl.style.background = sector.background;
      spinEl.style.color = sector.color;
    }
  };

  // Frame update for animation
  const frame = (ctx: CanvasRenderingContext2D, spinEl: HTMLElement | null) => {
    if (!angVel && spinButtonClicked) {
      const finalSector = sectors[getIndex()];
      events.fire("spinEnd", finalSector);
      spinButtonClicked = false;
      return;
    }

    angVel *= friction;
    if (angVel < 0.002) angVel = 0;
    ang += angVel;
    ang %= TAU;
    rotate(ctx, spinEl);
  };

  // Animation engine to keep updating the frame
  const engine = (ctx: CanvasRenderingContext2D, spinEl: HTMLElement | null) => {
    frame(ctx, spinEl);
    requestAnimationFrame(() => engine(ctx, spinEl));
  };

  // Spin button handler
  const spinHandler = () => {
    if (!angVel) angVel = rand(0.3, 0.5); //0.5, 0.8
    spinButtonClicked = true;
  };

  const confirmationPopUpClose = () => {
    setShowPopup(false);
  };

  const createMinuteLines = () => {
    const newLines: Line[] = [];
    // Create 120 lines, each 3 degrees apart
    for (let angle = 0; angle < 360; angle += 3) {
      const rad = (angle * Math.PI) / 180; // Convert angle to radians

      // Start coordinates (inner circle)
      const startX = outerCircleRadius + innerRadius * Math.cos(rad);
      const startY = outerCircleRadius + innerRadius * Math.sin(rad);

      // End coordinates (outer circle)
      const endX = outerCircleRadius + outerCircleRadius * Math.cos(rad);
      const endY = outerCircleRadius + outerCircleRadius * Math.sin(rad);

      // Push the calculated line to the array
      newLines.push({
        key: angle,
        x1: startX,
        y1: startY,
        x2: endX,
        y2: endY,
        stroke: "#333",
        strokeWidth: 1,
      });
    }
    setLines(newLines);
  };

  const fetchContentSchema = async () => {
    try {
      const { prelemBaseEndpoint: { deliveryEndPointGq = "", language = "" } = {}, sitename } =
        secondaryArgs;
      const { data, errors } = await graphQlList.fetchContentSchemaDetail(
        {
          contentType: "wheel",
          pagePath: content?.Id,
        },
        language,
        sitename,
        deliveryEndPointGq,
      );
      if (errors) {
        ToastService.failToast(errors?.[0]?.message || t("errorRequest"));
      } else {
        const dropdownData = data?.publish_fetchSchemaContent?.select;
        let dropdownDataArray = dropdownData ? JSON?.parse(dropdownData) : [];
        if (dropdownDataArray.length % 2 !== 0) {
          dropdownDataArray = [...dropdownDataArray, { label: "Try Again!", id: "NA" }];
        }
        const updatedSectors = dropdownDataArray.map((reward, index) => {
          const colorIndex = index % WheelColors.length;
          return {
            background: WheelColors?.[colorIndex]?.backgroundColor,
            color: WheelColors[colorIndex]?.textColor,
            reward_name: reward?.label || `Prize ${index + 1}`,
            reward_id: reward?.id,
            member_id: member_id,
          };
        });
        setSectors(updatedSectors);
      }
    } catch {
      ToastService.failToast(t("errorRequest"));
      setSectors([]);
    }
  };

  useEffect(() => {
    fetchContentSchema();
    createMinuteLines();
  }, []);

  useEffect(() => {
    if (sectors.length === 0) return;
    const spinEl = spinButtonRef.current;
    const wheelCanvas = wheelCanvasRef.current;
    if (wheelCanvas) {
      const ctx = wheelCanvas.getContext("2d");
      if (ctx) {
        sectors.forEach((sector, i) => drawSector(sector, i, ctx)); // Initial draw
        rotate(ctx, spinEl); // Initial rotat ion
        engine(ctx, spinEl); // Start engine

        if (spinEl) {
          spinEl.addEventListener("click", spinHandler);
        }
        return () => {
          if (spinEl) {
            spinEl.removeEventListener("click", spinHandler);
          }
        };
      }
    }
  }, [sectors]);

  events.addListener("spinEnd", (sector: any) => {
    if (sector?.reward_id !== "NA") {
      setWinPrize(sector);
      setShowPopup(true);
    }
  });

  return (
    <Box className={`${classes.spinAndWheel} spinAndWheelSection`}>
      {sectors?.length > 0 ? (
        <>
          <Typography variant='h2regular' id='Title1' sx={{ marginBottom: "0" }}>
            {content?.Title}
          </Typography>
          <Typography variant='p3regular' id='Title2'>
            {content?.Description}
          </Typography>
          <Box
            className='wheelWrapperSection'
            sx={{
              width: circleDiameter,
            }}>
            <Box
              className='outerCircle'
              sx={{
                width: circleDiameter,
                height: circleDiameter,
              }}>
              <svg
                width={circleDiameter}
                height={circleDiameter}
                viewBox={`0 0 ${circleDiameter} ${circleDiameter}`}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  transform: "rotate(-90deg)",
                }}>
                {/* Minute lines */}
                {lines.map((line) => (
                  <line
                    key={line.key}
                    x1={line.x1}
                    y1={line.y1}
                    x2={line.x2}
                    y2={line.y2}
                    stroke={"#BF9E44"}
                    strokeWidth={line.strokeWidth}
                  />
                ))}
              </svg>
            </Box>
            <Box id='spin_the_wheel'>
              <canvas id='wheel' ref={wheelCanvasRef} width={274} height={274}></canvas>
              <Box id='spin' ref={spinButtonRef}>
                SPIN
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <CenteredSkeleton />
      )}
      {showPopup && (
        <Confettipopup
          secondaryArgs={secondaryArgs}
          winPrize={winPrize}
          member_id={member_id}
          open={showPopup}
          handleClose={confirmationPopUpClose}
        />
      )}
    </Box>
  );
};

export default Wheel;
