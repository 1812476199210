import { Box, Tooltip, Typography } from "@mui/material";

const TitleSubTitle = ({
  title = "",
  subTitle = "",
  titleVariant,
  subTitleVariant,
  titleColor = "",
  subTitleColor = "",
  // eslint-disable-next-line react/jsx-no-useless-fragment
  toolTipIcon = <></>,
  toolTipText = "",
}) => {
  return (
    <Box>
      {title && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant={titleVariant}>{title}</Typography>
          {/* <XTypography variant={titleVariant} label={title} /> */}
          <Tooltip title={toolTipText} placement='top'>
            {toolTipIcon}
          </Tooltip>
        </Box>
      )}
      {subTitle && (
        <Box>
          <Typography variant={subTitleVariant}>{subTitle}</Typography>
          {/* <XTypography variant={subTitleVariant} label={subTitle} /> */}
        </Box>
      )}
    </Box>
  );
};
export default TitleSubTitle;
