import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    MyRewardsCardWrapper: {
      "&.MyRewardsCardWrapperSection": {
        width: "100%",
        background: theme.palette.prelemType1.BACKGROUND,
        border: `solid 1px ${theme.palette.prelemType1.LINE}`,
        marginBottom: "16px",
        borderRadius: theme?.borderRadius.value1,
        "&:hover": {
          border: `solid 1px ${theme.palette.prelemType1.TITLE}`,
        },
        "& .dashed": {
          border: `dashed 1px  ${theme.palette.prelemType1.LINE}`,
          color: `dashed 1px  ${theme.palette.prelemType1.PARAGRAPH}`,
        },
        "& .cardDetail": {
          padding: "16px",
          display: "block",
          [theme.breakpoints.up("md")]: {
            display: "flex",
          },
          "& .buttonWrapper": {
            marginLeft: "40px",
            [theme.breakpoints.down("md")]: {
              marginTop: "20px",
              marginLeft: "0px",
            },
          },

          "& .rewardProduct": {
            width: 109,
            height: 109,
            borderRadius: theme?.borderRadius.value1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#EFF0F6",
            "& img": {
              // mixBlendMode: "multiply",
              maxHeight: "90px",
              maxWidth: "90px",
            },
          },
          "& .customchip": {
            height: "25px",
            cursor: "pointer",
            marginBottom: "8px",
            "& span": {
              paddingLeft: 0,
            },
            "& .coinImage": {
              width: "18px",
              height: "18px",
              marginLeft: "6px",
              marginRight: "3px",
            },
          },
        },
        "& .viewDetails": {
          borderTop: `solid 1px ${theme.palette.prelemType1.LINE}`,
          padding: "16px",
        },
        "& .lessGap": {
          marginBottom: "4px",
        },
      },
    },
  };
});
