import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    ConfettiPopup: {
      "&.Confetti-Popup": {
        "& .Platform-x-Dialog-paper": {
          background: theme.palette.prelemType1.BACKGROUND,
          padding: "12px",
          margin: "12px",
          textAlign: "center",
          position: "relative",
          overflow: "hidden",
          maxWidth: "650px !important",
        },
        "& .closeWrapper": {
          textAlign: "center",
          margin: 0,
          [theme.breakpoints.up("md")]: {
            margin: "15px 0 4px",
          },
        },
        "& .modal-close": {
          "& svg": {
            fill: theme.palette.prelemType1.TITLE,
            zIndex: 2,
          },
        },
        "& .dialogTitle": {
          padding: "0px 15px",
        },
        "& .dialogContent": {
          padding: "0 15px",
        },
        "& .boxWrapper": {
          width: "240px",
          height: "240px",
          margin: "0px auto",
          borderRadius: "8px",
          padding: "20px",
          marginTop: "10px",
          background: `linear-gradient(184.62deg, #7ABEF7 1.72%, #4080F5 80.81%, #7747D5 130.08%, #572AC2 170.44%)`,
          "& .boxWrapperInner": {
            width: "100%",
            height: "100%",
            padding: "20px",
            border: "dashed 1px #fff",
            "& .coupanCode": {
              textAlign: "cneter",
              border: "dashed 1px #fff",
              maxWidth: "130px",
              padding: "5px",
              borderRadius: "8px",
              margin: "0px auto",
            },
          },
        },
      },
    },
  };
});
