import TagIcon from "@mui/icons-material/Tag";
import IconButton from "@mui/material/IconButton";

const RpiTagPopupIconButton = ({ onClick }) => {
  return (
    <IconButton onClick={onClick} size='medium' className='rpitagpopup' aria-label='RPI Tag Popup'>
      <TagIcon />
    </IconButton>
  );
};

export default RpiTagPopupIconButton;
