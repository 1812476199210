import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    spinAndWheel: {
      "&.spinAndWheelSection": {
        background: theme.palette.prelemType1.BACKGROUND,
        textAlign: "center",
        "& .wheelContainer": {
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
        },
        "& .buttonWrapper": {
          textAlign: "center",
        },
        "& #spin_the_wheel": {
          margin: "0px auto",
          textAlign: "center",
        },
        "& #spin": {
          fontSize: theme.fontSize?.fontSize_16,
          fontFamily: theme.fontFamily.primary,
        },
        "& #Title1": {
          fontFamily: theme.fontFamily.primary,
        },
      },
    },
  };
});
