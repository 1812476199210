import { Box, CardContent, ImageListItem, Typography } from "@mui/material";
import {
  ImageIcon,
  SelectionTick,
  VODIcon,
  assetDefaultimage,
} from "@platformx/shared/static-assets";
import { PlatFormXDateTimeFormat, relativeImageURL } from "@platformx/utilities";
import { useState } from "react";
import "./ContentTypeCard.css";
import useContentGlleryStyle from "./DamContentGllery.style";

const ContentTypeCard = ({ content, selectedCardIndex, onSelectCard, setImageData }) => {
  const onClick = () => {
    setImageData({
      Thumbnail: content.thumbnail.content_url,
      Title: content?.name,
      Description: content?.description || "",
      Author: content?.thumbnail?.publisher,
      bitStreamId: content?.uuid,
      Url: "",
      bundlesUrl: content?.bitstreams?.url,
    });
  };
  const icon = {
    Image: ImageIcon,
    Video: VODIcon,
  };
  const [isHover, setHovered] = useState(false);
  const classes = useContentGlleryStyle();

  const handleClick = () => {
    onClick();
    onSelectCard(content.uuid); // Assuming content.uuid is a unique identifier for the card
  };

  return (
    <ImageListItem
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={handleClick}
      data-testid='select-content-type-card'
      className={`contenttype_card ${selectedCardIndex === content.uuid ? "selected" : ""} ${
        classes.containbox
      }`}>
      <img
        src={relativeImageURL(content.thumbnail.content_url)}
        alt={content?.name}
        className={classes.container}
        onError={(event) => {
          (event.target as HTMLImageElement).src = assetDefaultimage;
        }}
      />

      {selectedCardIndex === content.uuid && <Box className={classes.cardmedia}></Box>}
      {isHover && <Box className={classes.cardmediaHover}></Box>}

      <CardContent className={classes.cardcontent}>
        {selectedCardIndex === content.uuid && (
          <img src={SelectionTick} className={classes.tickicon} alt='icon' />
        )}
        {(isHover || selectedCardIndex === content.uuid) && (
          <Box className={classes.contentcardwrap}>
            <Box className={classes.contentboxwrap}>
              <img
                style={{ filter: "brightness(100)" }}
                alt={content?.entityType}
                src={icon[content?.entityType || "Image"]}
              />
            </Box>

            <Typography
              className={classes.contentName}
              color='textColor'
              variant='p3medium'
              sx={{ marginTop: "5px !important", marginBottom: "5px !important" }}>
              {content?.name}
            </Typography>
            <Box className={classes.boxlastcontent}>
              <Typography color='textColor' variant='p4regular'>
                {PlatFormXDateTimeFormat(content?.lastModified)}
              </Typography>
            </Box>
          </Box>
        )}
      </CardContent>
    </ImageListItem>
  );
};

export default ContentTypeCard;
