import { makeStyles } from "@mui/styles";

export const useCustomStyle = makeStyles(() => {
  return {
    videoCardWrapper: {
      "&.videoCardWrapperSection": {
        marginTop: "15px",
        overflow: "hidden",
        position: "relative",
        height: "240px",
        maxHeight: "240px",
        "& .playIcon": {
          width: "54px",
          height: "54px",
          position: "absolute",
          top: "40%",
          left: "40%",
        },
      },
    },
  };
});
