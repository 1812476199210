import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { Box, Button, IconButton } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ToastService from "../../../../../components/ToastContainer/ToastService";
import { useCustomStyle } from "./AddCart.style";
import PopupDialog from "./PopupDialog";
import ProductQuantityButton from "./QuantityButton";

type ecommerceAddcartProps = {
  rewardPoints?: string | number;
  productId: string | number;
  secondaryArgs: any;
  parentPage: string;
  productFullDetails?: any;
  addToProductInCart?: any;
  productInStockInfo: string;
  cartData: any;
  otherDetails?: any;
};
const AddCart = (props: ecommerceAddcartProps) => {
  const classes = useCustomStyle();
  const {
    secondaryArgs = {},
    productId = "",
    parentPage,
    productFullDetails = {},
    addToProductInCart = () => {},
    productInStockInfo,
    cartData,
    otherDetails = {},
  } = props;

  const { t } = useTranslation();

  const [cartQuantity, setCartQuantity] = useState(1);
  const tempData = false;

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  /**
   * quantity add
   */
  const addToQuantity = () => {
    if (cartQuantity > 0) {
      setCartQuantity(cartQuantity + 1);
    }
  };

  /**
   * quantity reduce
   */
  const removeQuantity = () => {
    if (cartQuantity > 1) {
      setCartQuantity(cartQuantity - 1);
    }
  };

  /**
   * buyNow handle
   */
  const buyNowHandle = () => {
    if (cartData?.line_item?.length > 0)
      window.location.href = `${secondaryArgs?.prelemBaseEndpoint?.PublishEndPoint}${secondaryArgs?.prelemBaseEndpoint?.language}/ecommerce/cart-list`;
  };

  const view360View = () => {
    window.open(otherDetails?.ecomx_attributes_product_360_url, "_blank")?.focus();
  };

  const buttonData = otherDetails?.ecomx_attributes_product_360_title
    ? `${otherDetails?.ecomx_attributes_product_360_title}`
    : "Product 360&#176; View";

  return (
    <>
      <Box className={`${classes.addToCartWrapper} cartAllButton`}>
        {/* <Box className='productQuantityWrapper'>
          <Box className='gapTopQuantity'>
            <ProductQuantityButton
              removeQuantity={removeQuantity}
              cartQuantity={cartQuantity}
              addToQuantity={addToQuantity}
              parentPage={parentPage}
              disabled={productInStockInfo ? !JSON.parse(productInStockInfo) : false}
            />
          </Box>
        </Box> */}
        {otherDetails?.ecomx_attributes_product_360_title ||
        otherDetails?.ecomx_attributes_product_360_title === "" ? (
          <Button
            variant='ecommerceLinkButton1'
            type='button'
            data-testid='360-view'
            onClick={view360View}
            sx={{ width: "fit-content" }}>
            <p dangerouslySetInnerHTML={{ __html: buttonData }} />
          </Button>
        ) : null}
        <Box className='onlyButtons'>
          <ProductQuantityButton
            removeQuantity={removeQuantity}
            cartQuantity={cartQuantity}
            addToQuantity={addToQuantity}
            parentPage={parentPage}
            disabled={productInStockInfo ? !JSON.parse(productInStockInfo) : false}
          />
          <IconButton
            size='small'
            sx={{
              border: "1px solid",
              borderRadius: "5px",
              padding: "10px",
            }}
            // color='primary'
            className='cartbutton'
            aria-label='add to shopping cart'
            onClick={() => {
              if (JSON.parse(productInStockInfo)) {
                const errMsg = t("errorRequest");
                addToProductInCart({
                  secondaryArgs,
                  productId,
                  cartQuantity,
                  tempData,
                  productFullDetails,
                  errMsg,
                });
              } else {
                ToastService.failToast(t("item_is_currently_out_of_stock"));
              }
            }}
            data-testid='add-to-cart'>
            <AddShoppingCartIcon />
          </IconButton>
          {/* <Button
            type='button'
            disabled={productInStockInfo ? !JSON.parse(productInStockInfo) : false}
            variant='primaryButton2'
            onClick={() => {
              if (JSON.parse(productInStockInfo)) {
                const errMsg = t("errorRequest");
                addToProductInCart({
                  secondaryArgs,
                  productId,
                  cartQuantity,
                  tempData,
                  productFullDetails,
                  errMsg,
                });
              } else {
                ToastService.failToast(t("item_is_currently_out_of_stock"));
              }
            }}
            data-testid='add-to-cart'>
            {t("add_to_cart")}
          </Button> */}
          <Button
            variant='primaryButton2'
            type='button'
            sx={{ marginRight: 0 }}
            disabled={cartData?.line_item?.length > 0 ? false : true}
            onClick={buyNowHandle}
            data-testid='buy-now'>
            {t("buy_now")}
          </Button>
        </Box>
      </Box>
      <PopupDialog open={open} handleClose={handleClose} />
    </>
  );
};
export default AddCart;
