/* eslint-disable @typescript-eslint/no-empty-function */
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ShowToastError } from "../components/ToastNotification/ToastNotification";
import { PlatFormXDateTimeFormat, convertToLowerCase } from "../utils/helperFns";
import { stateIcons } from "./Card/constants";
import { XToolTip } from "./XToolTip/XToolTip";

type PublishInformationProps = {
  dataList: any;
  contentType: string;
  handleCardClick: () => void;
  tagName: string;
};
export const PublishInformation = (props: PublishInformationProps) => {
  const { tagName = "", dataList = {}, contentType = "", handleCardClick = () => {} } = props;

  const { t } = useTranslation();

  const redirect = (pageUrl) => {
    if (pageUrl) {
      window.open(pageUrl, "_blank", "noopener,noreferrer");
    } else {
      ShowToastError(t("api_error_toast"));
    }
  };

  const handleCardClicks = () => {
    if (convertToLowerCase(contentType) === "community") {
      redirect(dataList?.url);
    } else {
      handleCardClick();
    }
  };

  return (
    <>
      <Box className='statusweb' onClick={handleCardClicks}>
        <XToolTip
          Title={t(`${dataList.status}`)}
          position='top-start'
          component={
            convertToLowerCase(contentType) === "community" ? (
              <img
                className='horizThreeDotIcon'
                src={stateIcons.published}
                alt={stateIcons.published}
              />
            ) : (
              <img className='horizThreeDotIcon' alt='status' src={stateIcons[dataList.status]} />
            )
          }
        />
        {dataList.scheduledPublishTriggerDateTime !== undefined &&
          dataList.scheduledPublishTriggerDateTime !== null &&
          tagName === "sitepage" && (
            <XToolTip
              Title={
                `${t("schedulePublish")}` +
                " " +
                `${PlatFormXDateTimeFormat(dataList?.scheduledPublishTriggerDateTime)}`
              }
              position='top-start'
              component={
                <img className='horizThreeDotIcon' src={stateIcons["schedulePublish"]} alt='' />
              }
            />
          )}
        {dataList.scheduledUnPublishTriggerDateTime !== undefined &&
          dataList.scheduledUnPublishTriggerDateTime !== null &&
          tagName === "sitepage" && (
            <XToolTip
              Title={
                `${t("scheduleUnpublish")}` +
                " " +
                `${PlatFormXDateTimeFormat(dataList?.scheduledUnPublishTriggerDateTime)}`
              }
              position='top-start'
              component={
                <img
                  className='horizThreeDotIcon'
                  src={stateIcons["scheduleUnpublish"]}
                  alt='unpublish'
                />
              }
            />
          )}
      </Box>

      <Box className='datetimeweb' onClick={handleCardClick}>
        <Typography variant='p4regular'>
          {dataList?.author?.trim() || dataList?.lastModifiedBy}
        </Typography>
        <Typography variant='p4regular'>
          {PlatFormXDateTimeFormat(dataList?.lastModifiedDate)}
        </Typography>
      </Box>
    </>
  );
};
