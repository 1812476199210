import { Box, TextField, useTheme } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { useState } from "react";
import { Controller } from "react-hook-form";
import ErrorMessageHandle from "../Error/ErrorMessageHandle";

interface DateAndTimePickerProps {
  isDisabled?: boolean;
  disablePast?: boolean;
  state?: any;
  field?: any;
  control?: any;
  clearErrors: any;
  error: boolean;
  updateState: any;
}

DateAndTimePicker.defaultProps = {
  state: {},
  control: {},
  field: {},
  isDisabled: false,
  disablePast: false,
};

export default function DateAndTimePicker({
  field,
  error,
  state,
  updateState,
  clearErrors,
  disablePast,
  control,
}: DateAndTimePickerProps) {
  const theme = useTheme();
  const [date, setDate] = useState<Date | null>(null);

  const updateDate = (selectedDate) => {
    if (error) {
      clearErrors(field.name);
    }
    setDate(selectedDate);
    updateState(field.name, new Date(selectedDate)?.toISOString());
  };

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  const validateDate = () => {
    const currentValue = state[field.name];
    const required = field?.validations?.find((x) => x.type === "required");
    if (required && !currentValue) {
      return required?.message;
    }
    if (disablePast && new Date(currentValue) < new Date()) {
      return "Please select a future date";
    }
    const validations = field.validations?.filter((x) => x.type === "before" || x.type === "after");
    for (const validation of validations) {
      const dependentName = validation?.dependent_name;
      const dependentValue = state[dependentName];
      const errorMessage = validation?.message;
      if (dependentValue) {
        const condition = {
          before: (otherValue) => new Date(currentValue) <= new Date(otherValue),
          after: (otherValue) => new Date(currentValue) >= new Date(otherValue),
        };

        const isValid = condition[validation.type](dependentValue);

        if (!isValid) {
          return errorMessage;
        }
      }
    }
    return true;
  };

  return (
    <Box id={`${field?.name}_id`}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Controller
          name={field.name}
          control={control}
          rules={{ validate: validateDate }}
          render={({ field: controllerField }) => (
            <DateTimePicker
              {...controllerField}
              inputFormat='MM-DD-YYYY hh:mm a'
              minDateTime={disablePast ? dayjs() : null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onKeyDown={onKeyDown}
                  error={!!error}
                  sx={{
                    width: "100%",
                    border: error ? `solid 1px ${theme.palette.customColor.ERROR}` : ``,
                    borderRadius: "5px",
                    backgroundColor: error
                      ? `${theme.palette.customColor.BACKGROUND_ERROR}`
                      : "transparent",
                  }}
                />
              )}
              value={state[field.name] === "" ? date : state[field.name]}
              onChange={(newValue) => updateDate(newValue)}
            />
          )}
        />
        <ErrorMessageHandle error={error} />
      </LocalizationProvider>
    </Box>
  );
}
