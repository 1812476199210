import { RootState } from "@platformx/authoring-state";
import { Loader, getCurrentLang, getSelectedSite } from "@platformx/utilities";
import { Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ProtectedRoute } from "../../router/ProtectedRoute";
import { convertToSnakeCase } from "../../utils/helper";
import Components from "./componentMapping";

interface dynamicRouteProps {
  generatedRoutes: any;
  generatedMenuItems: any;
}
interface routesProps {
  path: string;
  element: any;
}

export const useDynamicRoutes = (): dynamicRouteProps => {
  const { routes: unMappedRoutes = [] } = useSelector((state: RootState) => state?.routes);
  const [generatedRoutes, setGeneratedRoutes] = useState<any>();
  const [generatedMenuItems, setGeneratedMenuItems] = useState<any>([]);
  const iframedurl = `${window?.location?.origin}/${getSelectedSite()}/${getCurrentLang()}/content/common/preview`;

  const menuDisplayOrder = [
    "dashboard",
    "page",
    "content",
    "collection",
    "gamification",
    "reports",
    "Community",
    "UserManagement",
    "Workflow",
    "Menu",
    "Assets",
    "SiteSetting",
    "Site",
  ];

  const dynamicRoutesHandler = (routesList) => {
    const menus = {};
    const routes: routesProps[] = [];
    routesList?.forEach((item) => {
      const category = item?.category || "";
      if (item?.isMenu && !item?.isHidden) {
        if (!menus[category]) {
          menus[category] = {
            id: convertToSnakeCase(category),
            title: category,
            menu: [],
          };
        }
        menus[category]?.menu.push({ ...item, MenuName: item?.title });
      }

      const Component: any = Components?.[item?.component] || <div></div>;
      routes.push({
        path: item.url,
        element: (
          <>
            {item?.id === "access_denied" ? (
              <Component />
            ) : (
              <ProtectedRoute
                category={item?.category}
                subCategory={item?.subCategory}
                isHeader={item?.isHeader}
                isSideBar={item?.isSideBar}>
                <Suspense fallback={<Loader />}>
                  <Component
                    dashboardName={item?.component}
                    heading={item?.id}
                    type={item?.id}
                    iframeUrl={iframedurl}
                  />
                </Suspense>
              </ProtectedRoute>
            )}
          </>
        ),
      });
    });
    const orderedMenu = menuDisplayOrder.map((item) => {
      return Object.values(menus)?.find(
        (menu: any) => menu.title.toLowerCase() === item.toLocaleLowerCase(),
      );
    });
    return { dynamicMenu: orderedMenu, dynamicRoutes: routes };
  };

  useEffect(() => {
    if (unMappedRoutes) {
      const { dynamicMenu, dynamicRoutes } = dynamicRoutesHandler(unMappedRoutes);
      setGeneratedMenuItems(dynamicMenu);
      setGeneratedRoutes(dynamicRoutes);
    }
  }, [unMappedRoutes]);

  return { generatedRoutes, generatedMenuItems };
};
