/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Container, Grid, Slide, Typography } from "@mui/material";
import { formCroppedUrlString } from "@platformx/utilities";
import { useRef } from "react";
import { useInView } from "react-intersection-observer";
import "../../Style.css";
import ContentReplace from "../../components/ContentReplace/ContentReplace";
import { usePrelemImpression } from "../../components/ImpressionHooks/PrelemImpressionHook";
import { SkeletonLoader } from "../../components/SkeletonLoader/SkeletonLoader";
import prelemTypes from "../../globalStyle";
import useContentData from "../../utils/DynamicSchema/useContentData";
import { useCustomStyle } from "./Services2.style";

const Services2 = ({ content, analytics, authoringHelper, secondaryArgs }) => {
  let timer = 1000;
  const firstRender = useRef(true);
  const { bucketName, gcpUrl } = secondaryArgs;
  const { ref, inView } = useInView({
    threshold: 0.1,
  });

  usePrelemImpression(analytics, inView, secondaryArgs);
  const { contentValue, loader } = useContentData(content, secondaryArgs);
  const classes = useCustomStyle();
  const globalClasses = prelemTypes();
  return (
    <div
      ref={authoringHelper?.innerRef}
      className={`${classes.service2PrelemWrapper} ${globalClasses.prelemType1} prelem prelemType1 service2PrelemBg Services2`}>
      <Container
        className={
          authoringHelper?.isEditPage ? `grid_full_width prelem-py` : `grid_container prelem-py`
        }
        ref={ref}>
        <Grid container>
          <Grid xs={12} sm={12} md={12} em={5}>
            <Slide
              direction='right'
              in={true /*secondaryArgs?.editState ? true : inView*/}
              timeout={2000}>
              <Box className='LeftServices2'>
                <Typography variant='labelbold' id='title'>
                  {content?.title}
                </Typography>
                <Typography
                  variant='h2semibold'
                  id='description'
                  sx={{
                    display: authoringHelper?.isEditing || content?.description ? "block" : "none",
                  }}>
                  {content?.description}
                </Typography>
              </Box>
            </Slide>
          </Grid>
          <Grid xs={12} sm={12} md={12} em={7}>
            {loader ? (
              <Box
                className='skeletonbox'
                sx={{ width: "100%", height: "100%", minHeight: "500px" }}>
                <SkeletonLoader />
              </Box>
            ) : (
              <Grid
                container
                item
                ref={ref}
                sx={{
                  position: "relative",
                  "&:hover": {
                    ".add-content-overlay": {
                      display: authoringHelper?.authoringHoverShow ? "flex !important" : "none",
                    },
                  },
                }}>
                {contentValue &&
                  Object.keys(contentValue?.slice(0, Math.ceil(Number(contentValue?.length) / 2)))
                    .map((keys) => {
                      return Number(keys);
                    })
                    .map((key2, value1) => (
                      <Grid key={key2} xs={12} sm={12} md={12}>
                        <Slide
                          direction='left'
                          in={true /*secondaryArgs?.editState ? true : inView*/}
                          timeout={
                            key2 === 0 ? 1000 : key2 - (key2 - 1) === 1 ? (timer = timer + 1000) : 0
                          }>
                          <Grid container item ref={ref}>
                            {contentValue &&
                              Object.entries(contentValue?.slice(value1 * 2, (value1 + 1) * 2)).map(
                                ([key, value]: [string, any]) => (
                                  <Grid
                                    item
                                    key={key}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                    justifyContent='center'
                                    alignItems='center'
                                    className='gridBoxServices2'>
                                    <Box className='Service2Box'>
                                      <Box className={`IconWrapper imghover`}>
                                        <img
                                          src={
                                            formCroppedUrlString(
                                              gcpUrl,
                                              bucketName,
                                              value?.serviceicon?.original_image
                                                ?.original_image_relative_path,
                                              value?.serviceicon?.original_image?.ext,
                                            ).src
                                          }
                                          alt='serviceimage'
                                        />
                                      </Box>
                                      <Box className={`Services2LineBottom line`}></Box>
                                      <Box className='ContentBottonServices2'>
                                        <Typography gutterBottom variant='p3regular'>
                                          {value?.description}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Grid>
                                ),
                              )}
                          </Grid>
                        </Slide>
                      </Grid>
                    ))}
                <ContentReplace
                  onReplaceClick={() => secondaryArgs?.multiSlot?.onToggleContentGallery()}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Services2;
