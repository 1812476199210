import { Box, Skeleton } from "@mui/material";

const CenteredSkeleton = () => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      height='440px'
      textAlign='center'>
      <Skeleton variant='text' width={200} height={20} sx={{ marginTop: 4 }} />
      <Skeleton variant='text' width={350} height={30} sx={{ marginBottom: 3 }} />
      <Skeleton variant='circular' width={300} height={300} />
    </Box>
  );
};

export default CenteredSkeleton;
