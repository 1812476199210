import { Box, Checkbox, FormControlLabel, Typography, useTheme } from "@mui/material";
import { Coin } from "@platformx/shared/static-assets";

const AvailablePoints = ({ olData, currencyCode, checked, onCheckBoxChange, finalPoints }) => {
  const totalPoints = olData?.userPointsInfo?.activePoints || 0;
  const convertedPoints = olData?.userPointsInfo?.convertedPoints || 0;
  const theme = useTheme();
  const additionalText = `${1 / convertedPoints}Pts = 1${currencyCode}`;

  const availablePointsRadio = {
    name: (
      <span>
        Use your
        <img
          src={Coin}
          width='20'
          height='20'
          alt='coin'
          style={{ verticalAlign: "middle", margin: "0 5px" }}
        />
        <b>{`${finalPoints || 0} Pts`}</b> for this purchase.
      </span>
    ),
    value: "POINTS",
    description: `You have <b>${totalPoints}</b> Pts in your wallet. ${totalPoints === 0 ? "" : additionalText}`,
  };

  return (
    <>
      <Typography variant='h4bold'>Your Available Points</Typography>
      <Box className={`payment-detail-section paymentDetail`}>
        <Box className='radio-wrapper'>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={onCheckBoxChange}
                sx={{
                  color: theme.palette.prelemType1.CHECKBOX.BOX_COLOR,
                  "&.Mui-checked": {
                    color: theme.palette.prelemType1.CHECKBOX.BOX_COLOR_ACTIVE,
                  },
                  "&.Mui-disabled": {
                    color: theme.palette.prelemType1.CHECKBOX.BOX_COLOR,
                    opacity: 0.4,
                  },
                }}
              />
            }
            label={
              <Typography variant='p3regular' component='span'>
                {availablePointsRadio.name}
              </Typography>
            }
          />
          <Typography
            color='primaryAnchorLink'
            sx={{ margin: "0 31px" }}
            variant='h6regular'
            dangerouslySetInnerHTML={{
              __html: availablePointsRadio.description,
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default AvailablePoints;
