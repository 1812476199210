import { Button, ButtonGroup } from "@mui/material";
import { useCustomStyle } from "./QuantityButton.style";

const QuantityButton = (_props: any) => {
  const classes = useCustomStyle();
  const {
    cartQuantity = 0,
    addToQuantity = () => {},
    removeQuantity = () => {},
    loading = false,
    disabled = false,
  } = _props;

  return (
    <ButtonGroup size='small' className={`${classes.buttonWrapper} quantityButtonWrapper`}>
      {/* <Typography variant='h3regular' className='quantityText'>
        {t("quantity")}
      </Typography> */}
      <Button
        variant='transparent'
        type='button'
        className='transparentBtnMinus'
        onClick={removeQuantity}
        data-testid='removeQuantity'
        disabled={loading || disabled}>
        -
      </Button>
      <Button variant='transparent' disabled={loading || disabled} className='transparentBtnValue'>
        {cartQuantity}
      </Button>
      <Button
        variant='transparent'
        type='button'
        onClick={addToQuantity}
        data-testid='addToQuantity'
        disabled={loading || disabled}
        className='transparentBtnPlus'>
        +
      </Button>
    </ButtonGroup>
  );
};

export default QuantityButton;
